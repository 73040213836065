<template>
    <div v-if="processData" class="process-details">

        <div class="header">

            <div class="inline-box">
                <img src="../../public/newProcessSignatureLogo.svg" alt="Process Logo" class="process-logo">

                <div class="block-box">
                    <h2> {{ processData.document_title }} </h2>
                </div>

            </div>

            <div class="inline-info">
                <div class="block-info">
                    <div class="inline">
                        <h2>{{ $t("rightSideBar.processID") }}</h2>
                    </div>
                    <h3 class="process-id">{{ processData.process_id }}</h3>
                </div>
                <div class="block-info">
                    <h2>{{ $t("rightSideBar.author") }}</h2>
                    <h3 class="author-info"> {{ processData.owner }}</h3>
                </div>
                <div class="block-info">
                    <h2>{{ $t("rightSideBar.dueDate") }}</h2>
                    <h3> {{ processData.document_due_date }} </h3>
                </div>
                <div class="block-info">
                    <h2>{{ $t("rightSideBar.created") }}</h2>
                    <h3> {{ processData.document_create_date }} </h3>
                </div>
            </div>
            <div class="tabs-box">
                <div class="tab" :class="{ active: activeTab === 'recipients' }" @click="activeTab = 'recipients'">
                    <span>{{ $t('externalSignDetail.recipients') }}</span>
                </div>
                <div class="tab" :class="{ active: activeTab === 'documents' }" @click="activeTab = 'documents'">
                    <span>{{ $t('externalSignDetail.documents') }} ({{ processData?.document_name?.length || 0}})</span>
                </div>
            </div>

        </div>



        <div class="content">

            <div v-if="activeTab === 'recipients'">
                <div class="recipients">
                    <div class="internal-users">
                        <h2>{{ $t('externalSignDetail.internal') }}</h2>
                        <span v-if="processData.internal_users.length == 0" >{{ $t('externalSignDetail.anyInternal') }}</span>
                        <div class="bar-box">
                            <div class="user-list">
                                <div v-for="user in processData.internal_users" :key="user.user_email" class="user-item">
                                    <div class="user-name">
                                        <img src="../../public/profile.svg">
                                        <div class="user-name-flex">
                                            <span class="user-email">{{ user.user_email }}</span>
                                        </div>
                                    </div>
                                    <InfoBadge :status="user.signed" />
                                    <div class="action-btn">
                                        <img v-if="user.signed == 'SignedRestriction'"
                                            src="../../public/noteObjection.png" style="width: 18px;"
                                            @click="showNote(user.note)" />
                                        <img v-if="user.note && user.signed != 'SignedRestriction'"
                                            src="../../public/note.png" @click="showNote(user.note)" />
                                        <img v-if="user.note == null || user.note == ''"
                                            src="../../public/noteDisabled.png" />

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="external-users">
                        <h2>{{ $t('externalSignDetail.external') }}</h2>
                        <span v-if="processData.external_users.length == 0" >{{ $t('externalSignDetail.anyExternal') }}</span>
                        <div class="bar-box">
                            
                            <div class="user-list">
                                <div v-for="user in processData.external_users" :key="user.user_email" class="user-item">
                                    <div class="user-name">
                                        <img src="../../public/profile.svg">
                                        <div class="user-name-flex">
                                            <span class="user-email">{{ user.email }}</span>
                                        </div>
                                    </div>
                                    <InfoBadge :status="user.signed" />
                                    <div class="action-btn">
                                        <img v-if="user.signed == 'SignedRestriction'"
                                            src="../../public/noteObjection.png" style="width: 18px;"
                                            @click="showNote(user.note)" />
                                        <img v-if="user.note && user.signed != 'SignedRestriction'"
                                            src="../../public/note.png" @click="showNote(user.note)" />
                                        <img v-if="user.note == null || user.note == ''"
                                            src="../../public/noteDisabled.png" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="activeTab === 'documents'">
                <div class="documents">

                    <div class="document-container">
                        <div class="document-box" v-for="(document, index) in processData.document_name" :key="index">
                            <img src="../../public/sidebar_document.svg" alt="">
                            <h4>{{ document }}</h4>
                            <img @click="preview(index, document)" class="download-document" src="../../public/download.svg" alt="">
                        </div>
                    </div>
                    <div class="document-container"></div>
                </div>
            </div>
        </div>

        <div class="bar-box footer">
            <div class="button-footer" v-if="processData.document_status == 'NotSigned'">
                <button @click="declineDocument" class="btn decline red">
                    {{ $t("rightSideBar.decline") }}
                </button>
                <button @click="signDocument" class="btn sign">
                    {{ $t("rightSideBar.sign") }}
                </button>
            </div>
            <div v-else>
                <div class="process-status" >
                <img v-if="(processData.document_status == 'Signed')" src="../../public/signedText.png">
                <img v-if="(processData.document_status == 'Declined' || processData.document_status == 'Canceled')" src="../../public/failedText.png">
                <span class="ResultText" v-if='processData.document_status == "Signed"'>{{$t("externalSignDetail.signed") }}</span>
                <span class="ResultText" v-if='processData.document_status  == "Canceled"'>{{$t("externalSignDetail.canceled") }}</span>
                <span class="ResultText" v-if='processData.document_status  == "Declined"'>{{$t("externalSignDetail.declined") }}</span>
                <button class="btn download"
                    v-if="((processData.signed_count == processData.all_count) && processData.process_status == 'Signed')"
                    @click="downloadSigned">
                    <img src="../../public/download_white.svg" alt="">
                    {{ $t("rightSideBar.download") }}
                </button>
            </div>
            </div>
        </div>


        <ModalWindow :show="showAddUserModal" :mode="mode" :note="note" :doc_id="processData.process_id"
        :process_type="'QDS'" :send_to="undefined" @close="showAddUserModal = false"
        @remove="removeFlowEmmit" :joined_process_id="undefined" />

    </div>

    

</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import InfoBadge from '@/components/InfoBadge.vue';
import ModalWindow from "../components/ModalWindow.vue";
import ProgressBar from '@/components/ProgressBar.vue';
import { fetchExternalProcessDetail, downloadOneOriginal, externalSign, externalDecline, downloadDocument } from "../services.js";


const route = useRoute();
const processId = ref(route.params.process_id);
const processData = ref(null);
const note = ref("");
const mode = ref(0);
const showAddUserModal = ref(null)

const activeTab = ref('recipients');


const showNote = (noteValue) => {
  note.value = noteValue;
  mode.value = 2;
  showAddUserModal.value = true;
};

const downloadSigned = async () => {

var process_type = 'QDS';

var data_ = {
  process_id: processData.value.process_id,
  process_type: process_type,
  signed: 1
}
downloadDocument(data_);
};

const preview = async (id, documentName) => {
    console.log("PREVIEW");
    console.log("ID", id, "DOCUMENT", documentName)
    
    var data_ = {
      process_id: processId.value,
      process_type: 'Sign',
      file_name: documentName,
      signed: 0
    }
    console.log("DATA_", data_);
    await downloadOneOriginal(data_);
  };


const declineDocument = async () => {
    var declined_at = new Date();
    declined_at.setHours(declined_at.getHours() + 1); // Přidání jedné hodiny pro UTC+1
    declined_at = declined_at.toISOString().slice(0, 19).replace('T', ' ');

    var data = {
    process_id: processId.value,
    signed_date: declined_at
    };

    let response = await externalDecline(data);

    if (response.status != 200) {
    return;
    }

    fetchData();

}

const signDocument = async () => {
    var signed_at = new Date();
    signed_at.setHours(signed_at.getHours() + 1); // Přidání jedné hodiny pro UTC+1
    signed_at = signed_at.toISOString().slice(0, 19).replace('T', ' ');

    var data = {
    process_id: processId.value,
    signed_date: signed_at
    };

    let response = await externalSign(data);

    if (response.status != 200) {
    return;
    }

    fetchData();

}

const fetchData = async () => {
    try {
        const data = await fetchExternalProcessDetail(processId.value);
        processData.value = data.data;
    } catch (error) {
    console.error('Failed to fetch process data:', error);
    }
};

watch(() => route.params.process_id, (newProcessId) => {
    processId.value = newProcessId;
    fetchData();
});

onMounted(() => {
    fetchData();
    console.log(processData);
})

</script>



<style scoped>

.download {
display: flex;
align-items: center;
padding: 14px 40px;
font-size: 16px;
gap: 0.5rem;
background: #2156A4;
color: white;
border: none;
min-width: 250px;
border-radius: 5px;
cursor: pointer;
}

.download img {
width: 15px;

}

.download:hover {
box-shadow: #1862CEb3 0px 2px 10px;
}

.progress-bar-box {
  width: 100%;
}
.process-status {
    margin-right: 25px;
    display: inline-flex;
    gap: 1.3rem;
    align-items: center;
  }
  .process-status img {
    width: 18px;
    height: 22px;
  }
.process-details {
    background: #fff;
    height: 93vh;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    border-bottom: 1px solid #E5E5E5;
    padding: 20px 20px 0 20px;
    text-align: left;
    border-bottom: 1px solid #e1e5f0;
    box-shadow: 8px 7px 29px 0 rgba(100, 100, 111, .123);
}

.inline-box {
    display: flex;
    align-items: center;
}


.header h2 {
    margin: 0;
    color: #101828;
    font-size: 22px;
    font-weight: 600;
}

.header p {
    margin: 0;
    color: #667085;
    font-size: 14px;
}

.process-logo {
    width: 70px;
    height: 70px;
    margin-right: 20px;
}

.process-info h1 {
    margin: 0;
    font-size: 24px;
}

.process-meta {
    display: flex;
    gap: 10px;
}

.tabs {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.tabs button {
    padding: 10px 20px;
    border: none;
    background: #f0f0f0;
    cursor: pointer;
}

.tabs button.active {
    background: #4285f4;
    color: #fff;
}

.content {
    margin-bottom: 20px;
}

.recipients,
.documents {
    display: flex;
    gap: 20px;
}

.internal-users,
.external-users,
.document-container {
    flex: 1;
}

.inline-info span, .external-users span{
    color: #ACB7C7;
}

.user {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin-bottom: 10px;
}

.user img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.user-info {
    flex: 1;
}

.status {
    margin-right: 10px;
    background: #e0e0e0;
    padding: 2px 8px;
    border-radius: 4px;
}

.actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.actions button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.actions .approve {
    background: #4285f4;
    color: #fff;
}

.actions .reject {
    background: #f0f0f0;
    color: #000;
}

.inline-info {
    display: inline-flex;
    padding-top: 0rem;
    margin-inline: 5.5rem;
    gap: 2rem;
    margin-block: 2rem;
}

.block-info {
    display: flex;
    flex-direction: column;
}

.block-info h2 {
    font-size: 14px;
    margin: 0;
    font-weight: 500;
    color: #141E27;
}

.block-info h3 {
    font-size: 12px;
    margin: 0;
    font-weight: 500;
    color: #ACB7C7;
}

.tabs-box {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    cursor: pointer;
}

.tab {
    font-size: 14px;
    padding-bottom: 10px;
    font-weight: 400;
}

.tab span {
    display: inline-block;
    position: relative;
}

.tab.active span::after {
    content: '';
    display: block;
    width: 100%;
    border-bottom: 3px solid #2156A4;
    position: absolute;
    bottom: -13px;
    left: 0;
}

.tab.active {
    color: #2156A4;
    font-weight: 500;
}

.tab:hover {
    color: #2156A4;
}

.bar-box {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 0 20px;
    text-align: start;
}

.bar-box h3 {
    color: #141E27;
    margin: 15px 0 10px 0;
    font-size: 17px;
}

.user-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.user-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f7f9fb;
    padding: 5px 10px;
    height: 60px;
    border-radius: 5px;
}

.user-name {
    display: flex;
    align-items: center;
    min-width: 300px;
}

.user-name-flex {
    display: flex;
    flex-direction: column;
    text-align: start;
}

.user-email,
.user-status {
    font-weight: 500;
}

.user-email {
    font-size: 15px;
    margin-left: 1rem;
}

.user-date {
    margin-left: 1rem;
    font-size: 13px;
    min-width: 268px;
    color: rgb(90, 90, 90);
}

.action-btn {
    display: flex;
    width: 60px;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.bar-box {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 0 20px;
    text-align: start;
}

.bar-box h3 {
    color: #141E27;
    margin: 15px 0 10px 0;
    font-size: 17px;
}

.footer {
    margin-top: auto;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    min-height: 80px;
    gap: 2rem;
    box-shadow: rgba(100, 100, 111, 0.123) 15px 1px 24px 5px;

}

.btn {
    padding: 12px 44px;
    font-size: 15px;
    background: linear-gradient(90deg, #2156A4, #1862CE);
    border: 1px solid #2156A4 !important;
    box-shadow: rgba(130, 141, 151, 0.568) 0px 2px 10px;
    color: white;
    border: none;
    min-width: 160px;
    border-radius: 10px;
    cursor: pointer;
}

.button-footer {
    display: inline-flex;
    justify-content: right;
    gap: 3rem;
}

button:hover {
    box-shadow: #1862CEb3 0px 2px 10px;
    background: linear-gradient(90deg, #214f96, #1658b9);
}

.red {
    background: #fff;
    border: 1px solid #E9ECEF !important;
    color: black;
    box-shadow: rgb(224 224 224 / 57%) 0px 2px 10px;
    transition: ease 0.2s;
}

.red:hover {
    box-shadow: #aa4f4f42 0px 1px 11px;
    background: white;

}

.document-box {
    display: flex;
    padding: 15px 15px;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    border-radius: 7px;
    width: 95%;
    margin-top: 1px;
    margin-bottom: 0.7rem;
    background: #F7F9FB;
}

.document-box:hover {
    outline: 1px solid #77adffb9;
}

.document-box h4 {
    font-size: 14px;
    margin: 0;
    font-weight: 500;
    color: #141E27;
}

.document-box .download-document {
    margin-left: auto;
}

.document-container {
    margin-top: 2rem;
    margin-left: 2rem;
}
</style>