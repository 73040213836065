<template>
    <div v-if="show" class="modal-backdrop">
      <div class="modal">
        <button class="close-btn" @click="closeModal">X</button>
        <span>{{$t('lastPageModal.header')}}</span>
        <div>
          <div :class="['option', { 'selected-option': selectedOption === 'yes' }]" @click="chooseOption('yes')">
            {{$t('lastPageModal.yes')}}
          </div>
          <div :class="['option', { 'selected-option': selectedOption === 'no' }]" @click="chooseOption('no')">
            {{$t('lastPageModal.no')}}
          </div>
          <div class="buttons">
            <button class="next" :disabled="!selectedOption" @click="sendOption()">{{$t('lastPageModal.confirm')}}</button>
          </div>
        </div>
      </div>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';
const props = defineProps({
    show: Boolean
});
  
const emit = defineEmits(['close']);
  
// === Stores ====================================================================
//
// ===============================================================================

const selectedOption = ref(null);
  
const chooseOption = async (option) => {
    selectedOption.value = option;
    console.log('Selected options:', selectedOption.value);
};

const sendOption = () => {
    emit('send-option', selectedOption.value);
    closeModal(); 
};

const closeModal = () => {
    emit('close');
};
  
</script>
  
<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    max-width: 400px; 
    min-width: 400px;
    width: 100%; 
}

.modal span{
    font-size: medium;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 16px;
}

.option {
    font-size: smaller;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    height: 45px;
    align-items: center;
}

.option img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.selected-option {
    border: 1px solid #538BDD; 
    color: #538BDD;
}

.buttons {
    display: flex;
    justify-content: flex-end;
}

.buttons button{
    padding-block: 7px;
    width: 100px;
    border: 1px solid #E2E2E2;
    background-color: white;
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
    margin-top: 1rem;
}
.buttons .next {
    background-color: #2156A4;
    color: white;
}

.buttons button:disabled {
    background-color: #4177c8;
    cursor: not-allowed;
}

span {
    font-size: 12px;
    display: flex; 
    justify-content: flex-start;
    margin-bottom: 0.7rem;
    margin-top: 1.5rem;
}

</style>
  