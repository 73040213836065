import { createApp } from 'vue'
import App from './App.vue'
import store from './store/store';
import router from './routers/router';
import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';
import vClickOutside from "click-outside-vue3"
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import eventBus from './eventBus';
import { front_url } from './api';
import { provideStores } from '@/store/storeHooks';
// localization
import { createI18n } from "vue-i18n";

// all localization JSONs
import messages from "./lang";

// css used for reseting
// import "reset-css";

const i18n = createI18n({
  legacy: false,
	locale: "sk",
	fallbackLocale: "en",
	messages,
	globalInjection: true,
});

library.add(faTimes)

const app = createApp(App);

provideStores(app);
app.use(store); 
app.use(router); 
app.use(i18n);
app.use(SimpleTypeahead); // Globally register SimpleTypeahead
app.use(vClickOutside); 
app.component('FontAwesomeIcon', FontAwesomeIcon)
store.dispatch('initStore');



const receiveMessage = (event) => {
  if (event.origin !== front_url) return;
  if (event.data.action === 'someAction') {
    eventBus.emit('actionFromIframe', event.data.data);
  }
};

window.addEventListener('message', receiveMessage);

app.mount('#app');