<template>
 
    <div class="modal-overlay"  v-if="show || openModal">
      
      <div class="modal-content" @click.stop>
        <button class="close-btn" @click="closeModal">✖</button> 
        <div class="header-box">
          <StepCircles 
            @change-step="specificModalStep"
          />
        </div>

        <!-- step components -->
        <component :is="currentComponent" ref="component" />
        
        <!-- step buttons -->
        <div class="step-btn-box">
            <button class="step-btn prev" v-if="openModalMode != 'editProcess' && currentStep.id > 1" @click="prevModalStep">{{ $t("addProcess.prev") }}</button>
            <button class="step-btn next" v-if="currentStep.id < allSteps.length" @click="nextModalStep">{{ $t("addProcess.next") }}</button>
            <button class="step-btn confirm" v-if="openModalMode != 'editProcess' && (currentStep.id === allSteps.length)" @click="confirmStep">{{ $t("addProcess.confirm") }}</button>
            <button class="step-btn update" v-if="openModalMode == 'editProcess' && (currentStep.id === allSteps.length)" @click="updateStep">{{ $t("addProcess.update") }}</button>
            
        </div>
      </div>
    </div>

    <!-- loading screen -->
    <LoadingModal :visible="loading" :message="$t('loading.message')" />  

    <!-- error/warning popUp -->
    <transition>
    <InfoPopUp v-if="(store.state.msg.length > 0)" />
  </transition>
</template>
  

<script setup>
// === Test functions ============================================================
//
// ===============================================================================


// === Imports ===================================================================
//
// ===============================================================================
import { createApprovalProcess, createSigningProcess, editDocument, fetchDocumentDetails, isApprovalFinished, loadUsers } from '@/services';
import {  computed, onMounted, ref, defineEmits, defineProps } from 'vue';
import { useStoreAddProcess, useStoreGlobal } from "@/store/storeHooks";
import AddDocumentModal from './modals/AddDocumentModal.vue';
import NewProcessModal from './modals/NewProcessModal.vue';
import AddExternalUsersModal from './modals/AddExternalUsersModal.vue';
import SummaryModal from './modals/SummaryModal.vue';
import StepCircles from '@/components/StepCircles.vue';
import LoadingModal from '@/components/LoadingModal.vue';
import AddUsersModal from './modals/AddUsersModal.vue';
import InfoPopUp from '@/components/InfoPopUp.vue';
import { useI18n } from 'vue-i18n';
import dbg from '@/debug';
import eventBus from '@/eventBus';


// === Defines ===================================================================
//
// ===============================================================================
const emit = defineEmits(['reload', 'close']);

defineProps({
  show: Boolean,
});


// === Stores ====================================================================
//
// ===============================================================================
const store = useStoreGlobal();
const storeAddProcess = useStoreAddProcess();


// === Refs and variables ========================================================
//
// ===============================================================================
//refs
const component = ref(null);
const loading = ref(false);

//variables
const allSteps = computed(() => store.state.modalSteps);

const externComponent = 'AddExternalUsersModal';
const components = {
    NewProcessModal,
    AddDocumentModal,
    AddUsersModal,
    AddExternalUsersModal,
    SummaryModal,
    
};


// === Computed properties =======================================================
//
// ===============================================================================


const currentComponent = computed(() => {
  const step = store.state.currentModalStep;
  if (!step) return null;

  if (store.state.toggleAdditionalStep && step.id === 4) {
    return components[externComponent];
  }
  return components[step.name];
});



const openModal = computed(() => storeAddProcess.state.openModal);
const openModalMode = computed(() => storeAddProcess.state.openModalMode);
const currentStep = computed(() => store.getters['getCurrentModalStep']);
const activeTab = computed(() => store.getters['getActiveTab']);


const process_data = computed(() => store.getters["getDocumentDetail"]);
const users = computed(() => store.state.users);
const external_users = computed(() => store.state.externalUsers);



// === Hooks =====================================================================
//
// ===============================================================================
const { t } = useI18n();

onMounted( async () => {
  try {
    let response = await loadUsers();
    dbg("$loadUsers", response);
    store.commit('storeUsers', response);
  } 
  catch (error) {
    console.log(error);
  }
});


// === Functions =================================================================
//
// ===============================================================================

// when I press next or prev to store subcomponents data
function storeData() {

  switch (currentStep.value.name) {
    case "NewProcessModal":
      dbg("case 1", component.value.newProcessPayload());
      storeAddProcess.commit("storeAddProcess", component.value.newProcessPayload()) 
      break;
    case "AddDocumentModal":
    dbg("case 2", component.value.addDocumentPayload());
      storeAddProcess.commit("storeAddDocument", component.value.addDocumentPayload()) 
      break;
    case "AddUsersModal":
      dbg("case 3");
      break
    default:
      dbg("default");
      break;
  }
}

// when I press next button
async function nextModalStep() {  

  storeData();
  dbg("currentStep", currentStep.value.id);
  if(await checkStepData(currentStep.value.id))
  {
    store.commit('nextModalStep');
    store.commit('addActiveStep', currentStep.value.id);
  }
}

async function specificModalStep(step) {
  console.log("SPECIFIC MODAL STEP", step);
  store.commit('specificModalStep', step);
}

// when I press prev buttone
function prevModalStep() {
  storeData();
  store.commit('prevModalStep');
}

// when I press confirm button
async function confirmStep() {
  dbg('confirmStep');
  if(await checkStepData(currentStep.value.id))
  {
    storeData();
    await createProcess();
    storeAddProcess.commit('closeModal');
    resetData();
  }
}

// when I press update button
async function updateStep() {
  dbg('updateStep', process_data.value);
  dbg('updateStep', users.value);


  let formattedDate;
  let signedAt;

  let sortedUsers = [...users.value].sort((a, b) => a.order - b.order);
  let sortedExternalUsers = [...external_users.value].sort((a, b) => a.order - b.order);

  if (sortedUsers.length == 0){
    sortedUsers = store.getters['sortedUsers'];
  }

  let currentOrder = 1;
  let lastOrder = null;

  if (sortedUsers.length != 0){
    lastOrder = sortedUsers[0].order;
    sortedUsers[0].normalizedOrder = currentOrder;

    for (let i = 1; i < sortedUsers.length; i++) {
      if (sortedUsers[i].signature_order !== lastOrder) {
        currentOrder++;
      }
      sortedUsers[i].normalizedOrder = currentOrder;
      lastOrder = sortedUsers[i].signature_order;
    }
  }
  

  if (sortedExternalUsers.length == 0){
    sortedExternalUsers = store.getters['sortedExternalUsers'];
  }
  
  currentOrder = 1;

  if (sortedExternalUsers.length != 0){
    lastOrder = sortedExternalUsers[0].order;
    sortedExternalUsers[0].normalizedOrder = currentOrder;

    for (let i = 1; i < sortedExternalUsers.length; i++) {
      if (sortedExternalUsers[i].signature_order !== lastOrder) {
        currentOrder++;
      }
      sortedExternalUsers[i].normalizedOrder = currentOrder;
      lastOrder = sortedExternalUsers[i].signature_order;
    }
  }
  

  const extractedData = {
    process_id: process_data.value.process_id,
    process_type: store.getters['getActiveTab'].abbr,
    internal_users: sortedUsers.map((user) => {

      if (user.signed_at) {
        signedAt = new Date(user.signed_at);
        formattedDate = signedAt.toISOString().slice(0, 19).replace('T', ' ');
      } else {
        formattedDate = null;
      }

      return {
        note: user.note,
        signed: user.signed,
        user_id: user.userId,
        signed_date: formattedDate,
        signature_order: user.normalizedOrder
      };
    }),

    external_users: sortedExternalUsers.map((user) => {

    if (user.signed_at) {
      signedAt = new Date(user.signed_at);
      formattedDate = signedAt.toISOString().slice(0, 19).replace('T', ' ');
    } else {
      formattedDate = null;
    }

    return {
      note: user.note,
      signed: user.signed,
      user_id: user.userId,
      signed_date: formattedDate,
      signature_order: user.normalizedOrder
    };
  }),
  };

  dbg("EXTRACTED DATA", extractedData);
  var response = await editDocument(extractedData);
  if (response) {
    dbg("EXTRACTED RESPONSE", response);
    store.commit('storeDocumentDetail', await fetchDocumentDetails(process_data.value.process_id));
    eventBus.emit('loadMyDocuments');
    closeModal();
  }
}


function resetData() {
  store.commit('resetUsers')
  storeAddProcess.commit('resetModalData');
  store.commit('resetModalSteps');
  store.commit('resetToggleAdditionalStep');
  dbg('resetujem');
}

function closeModal() {
  // console.log("currentStep", currentStep);
  // console.log("allSteps", allSteps.value);
  // console.log("currentComponent", currentComponent);
  resetData();
  storeAddProcess.commit('closeModal');
  emit('close');
}

// when we create sign process from approval process, we need to check if approval process is finished
/* eslint-disable no-unused-vars */
async function checkApprovalProcess(approvalId) {
  try {
    const response = await isApprovalFinished(approvalId);
    dbg("is-approval-finished", response);

    return false;
  } catch (error) {
    dbg("error", error);
    return true;
  }
}

const popUp = (type, text) => {
  store.commit('pushMsg', { type: type, text: text });
};

// before move to next subcomponent check if 
// all data like title, date, files, users are filled
const checkStepData = async (stepValue) => {

const files = storeAddProcess.getters['getAddDocument'];
const users = store.getters['getInternalUserIds'];
const title = storeAddProcess.getters['getNewProcess'].title;
const date = storeAddProcess.getters['getNewProcess'].date;
var approvalCheck;
const approvalId = storeAddProcess.getters['getNewProcess'].approvalId;

if(approvalId) {
  dbg("APPROVALID")
  approvalCheck = await checkApprovalProcess(approvalId)
  dbg("approvalCheck", approvalCheck);
}

switch (stepValue) {
  case 1:
    if (!title || !date) {
      popUp(2, t("popUp.required"));
      return false;
    }
    if(approvalCheck == true)
    {
        popUp(2, t("popUp.approval"));
        return false;
    }
    break;
  case 2:
    if(files.files.length === 0)
    {
      popUp(2, t("popUp.file"));
      return false;
    }
    break;
  case 3:
    if(users.length === 0) 
    {
      popUp(2, t("popUp.users"));
      return false;
    }
    break;
  default:
    break;
}

return true;    
};


// func calls in confirmStep() when I press confirm button
const createProcess = async () => {

loading.value = true;
var activeTab = store.getters['getActiveTab'];
dbg("activeTab", activeTab);

// store.commit(mutationMethods.ADD_FILE, files.value);
// minex or qsc certificate
var certificate;
if(activeTab.abbr == 'DS') {
  // minex certificate
  certificate = true
}
else if(activeTab.abbr == 'QDS') {
  certificate = false
}
dbg("certificate", certificate);
var response
dbg("create process");
var internal_users = store.getters['getInternalUserIds'];
var external_users = store.getters['getExternalUserIds'];
var formData = new FormData();
var addProcessData = storeAddProcess.getters['getNewProcess'];
var filesData = storeAddProcess.getters['getAddDocument'];
dbg("USERS: ", users);
dbg("addProcessData: ", addProcessData);
dbg("filesData: ", filesData);
for (let i = 0; i < filesData.files.length; i++) {
  formData.append("files", filesData.files[i]);
}

for (let i = 0; i < internal_users.length; i++) {
    formData.append("internal_users_id", internal_users[i].userId);
    formData.append("internal_users_order", internal_users[i].order);
  }

for (let i = 0; i < external_users.length; i++) {
  formData.append("external_users_id", external_users[i].userId);
  formData.append("external_users_order", external_users[i].order);
}

// when we create approval process
if(activeTab.abbr == 'AP') {

  dbg("APPROVAL PROCESS");
  formData.append("internal_signatures", internal_users);
  formData.append("external_signatures", external_users);
  formData.append("description", addProcessData.note);
  formData.append("title", addProcessData.title);
  formData.append("due_date", addProcessData.date);

  dbg("body", JSON.stringify(formData, null, 2));

  response = await createApprovalProcess(formData);
  dbg("create-approval-process", response);
}

// when we create signing process
else {
  dbg("SIGNING PROCESS");
  dbg("internal_users", JSON.stringify(internal_users, null, 2));
  dbg("external_users", JSON.stringify(external_users, null, 2));
  // formData.append("signatures", JSON.stringify(users));
  formData.append("description", addProcessData.note);
  formData.append("order", 0);
  formData.append("title", addProcessData.title);
  formData.append("due_date", addProcessData.date);
  formData.append("certificate", certificate);
  if (addProcessData.approvalId){
    formData.append("approval_id", addProcessData.approvalId);
  }
  dbg("body", JSON.stringify(formData, null, 2));

  response = await createSigningProcess(formData);
  dbg("create-process", response);
}


emit('reload');
emit('close');
loading.value = false;
};

  </script>
  
  <style scoped>
  .modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .modal-content {
    position: relative;
    width: 44vw;
    height: 88vh;
    border-radius: 16px;
    min-width: 700px;
    padding: 25px;
    background: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 1.5rem;
    cursor: pointer;
  }

.step-btn-box {
    margin-top: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    
}

.step-btn {
    width: 140px;
    height: 45px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    box-shadow: rgb(203 210 215 / 57%) 0px 2px 10px;
}

.step-btn.next {
    background-color: #CFE2FF;
    border: 1px solid #DEE2E6;
    color: #4285F4;
}

.step-btn.prev {
    background-color: #fff;
    border: 1px solid #D6D6D6;
    color: black;
}

.step-btn.confirm, .step-btn.update {
    background-color: #4285F4;
    border: 1px solid #4285F4;
    color: white;
}

/* ========== header box ========== */ 

.header-box {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid #E5E5E5;
    gap: 1rem;
    text-align: left;
}

.header-box h2 {
    margin: 0;
    color: #101828;
    font-size: 22px;
    font-weight: 600;
}

.header-box p {
    margin: 0;
    color: #667085;
    font-size: 14px;

}

.block-box {
    display: flex;
    flex-direction: column;
}

  </style>
  