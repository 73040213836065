// Mutation method names
export const mutationMethods = {
    STORE_NEW_PROCESS: 'storeAddProcess',
    STORE_ADD_DOCUMENT: 'storeAddDocument',
    OPEN_MODAL: 'openModal',
    CLOSE_MODAL: 'closeModal',
    STORE_ADD_USERS: 'storeAddUsers',
    RESET_MODAL_DATA: 'resetModalData',
};
  
  // Getter method names
  export const getterMethods = {
    GET_NEW_PROCESS: 'getNewProcess',
    GET_ADD_DOCUMENT: 'getAddDocument',
    GET_ADD_USERS: 'getAddUsers',
};
  