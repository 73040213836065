<template>
  <div class="modal-overlay">
    <div class="process-page">
      <div class="header">
        <div class="header-box">
          <div class="header-heading">
            <img src="../../public/newProcessSignatureLogo.svg" >
              <div class="block-box">
                  <h2>{{ $t("externalSign.header") }}</h2>
                  
                  <p>{{ $t("externalSign.info") }} </p>
              </div>
          </div>
          <div v-if="processData" class="inline-info">
            <div class="block-info">
              <div class="inline">
                <h2>{{ $t("rightSideBar.processID") }}</h2>
              </div>
              <h3 class="process-id">{{ processData.process_id }}</h3>
            </div>
            <div class="block-info">
              <h2>{{ $t("rightSideBar.author") }}</h2>
              <h3 class="author-info">{{ processData.owner }}</h3>
            </div>
            <div class="block-info">
              <h2>{{ $t("rightSideBar.dueDate") }}</h2>
              <h3>{{ processData.document_due_date }}</h3>
            </div>
            <div class="block-info">
              <h2>{{ $t("rightSideBar.created") }}</h2>
              <h3>{{ processData.document_create_date }}</h3>
            </div>
          </div>
          <span class="tab">{{ $t('externalSign.documents') }} ({{ processData?.document_name?.length || 0}})</span>
        </div>
      </div>
      <div class="bar-box-documents">
          <div v-if="processData" class="document-box" v-for="(document, index) in processData.document_name" :key="index" >
            <img src="../../public/sidebar_document.svg" alt="">
            <h4>{{ document}}</h4>
            <img class="download-document" src="../../public/download.svg" alt=""
              @click.stop="preview(index, document)">
          </div>
        
      </div>
      <div v-if="processData" class="footer">
        <div v-if="processData.signature == 'NotSigned'">
          <button @click="signDocument" class="btn sign"> {{ $t("externalSign.sign") }} </button>
          <button @click="declineDocument" class="btn decline"> {{ $t("externalSign.decline") }} </button>
        </div>
        <div v-else>
          <div class="process-status">

            <img v-if="(processData.signature == 'Signed')" src="../../public/signedText.png">
            <img v-if="(processData.signature == 'Declined' || processData.signature == 'Canceled')" src="../../public/failedText.png">
            <span class="ResultText" v-if='processData.signature == "Signed"'>{{$t("externalSign.signed") }}</span>
            <span class="ResultText" v-if='processData.signature  == "Canceled"'>{{$t("externalSign.canceled") }}</span>
            <span class="ResultText" v-if='processData.signature  == "Declined"'>{{$t("externalSign.declined") }}</span>

          </div>
          
        </div>
        
      </div>
    </div>
  </div>
    
  </template>
  
  <script setup>
  import { onMounted, ref, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import { fetchExternalSignData, downloadOneOriginal, externalSign, externalDecline } from "../services.js";

  const route = useRoute();
  const processId = ref(route.params.process_id);
  const processData = ref(null);
  
  const preview = async (id, documentName) => {
    console.log("PREVIEW");
    console.log("ID", id, "DOCUMENT", documentName)
    
    var data_ = {
      process_id: processId.value,
      process_type: 'Sign',
      file_name: documentName,
      signed: 0
    }
    console.log("DATA_", data_);
    await downloadOneOriginal(data_);
  };

  const signDocument = async () => {
    var signed_at = new Date();
    signed_at.setHours(signed_at.getHours() + 1); // Přidání jedné hodiny pro UTC+1
    signed_at = signed_at.toISOString().slice(0, 19).replace('T', ' ');

    var data = {
      process_id: processId.value,
      signed_date: signed_at
    };

    let response = await externalSign(data);

    if (response.status != 200) {
      return;
    }

    fetchData();

  }

  const declineDocument = async () => {
    var declined_at = new Date();
    declined_at.setHours(signed_at.getHours() + 1); // Přidání jedné hodiny pro UTC+1
    declined_at = signed_at.toISOString().slice(0, 19).replace('T', ' ');

    var data = {
      process_id: processId.value,
      signed_date: declined_at
    };

    let response = await externalDecline(data);

    if (response.status != 200) {
      return;
    }

    fetchData();

  }

  const fetchData = async () => {
    try {
      const data = await fetchExternalSignData(processId.value);
      processData.value = data.data;
    } catch (error) {
    console.error('Failed to fetch process data:', error);
  }
  };

  watch(() => route.params.process_id, (newProcessId) => {
    processId.value = newProcessId;
    fetchData();
  });

  onMounted(() => {
      fetchData();
      console.log(processData);
  })

  </script>
  
  <style scoped>
  .process-status {
    margin-right: 25px;
    display: inline-flex;
    gap: 1.3rem;
  }
  .process-status img {
    width: 18px;
    height: 22px;
  }
  .ResultText{
     margin-right: -17px;
  }
  .block-info {
    display: flex;
    flex-direction: column;
  }

  .block-info h2 {
    font-size: 14px;
    margin: 0;
    font-weight: 500;
    color: #141E27;
  }

  .block-info h3 {
    font-size: 12px;
    margin: 0;
    font-weight: 500;
    color: #ACB7C7;
  }
  .inline-info {
    display: inline-flex;
    padding-top: 0rem;
    justify-content: center;
    gap: 2rem;
    margin-block: 2rem;
  }

  .modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 88vh;
    
}
  .process-page {
    position: relative;
    width: 44vw;
    height: 95%;
    border-radius: 16px;
    min-width: 700px;
    background: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }
  
  .header {
    margin-bottom: 1.5rem; 
    padding-bottom: 27px;
    overflow-y: hidden;
    
    border-bottom: 1px solid #E1E5F0;
    box-shadow: rgba(100, 100, 111, 0.123) 8px 7px 29px 0px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 0 20px;
    text-align: start;
  }

  .header-box{
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    width: 100%;
    height: 100%;
    text-align: initial;
  }
  



  .tab{
    font-size: 14px;
    padding-bottom: 10px;
    color: #2156A4;
    font-weight: 500;
    border-bottom: 2px solid #2156A4;
    width: fit-content;
  }

  .bar-box-documents{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 0 20px;
    text-align: start;
  }

  .document-box{
    display: flex;
    padding: 15px 15px;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    border-radius: 7px;
    width: 95%;
    margin-top: 1px;
    margin-bottom: 0.7rem;
    background: #F7F9FB;
  }

  .document-box h4{
    font-size: 14px;
    margin: 0;
    font-weight: 500;
    color: #141E27;
  }

  .footer{
    margin-top: auto;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    min-height: 80px;
    gap: 2rem;
    box-shadow: rgba(100, 100, 111, 0.123) 15px 1px 24px 5px;
    overflow-y: auto;
    text-align: start;
  }

  .btn{
    padding: 12px 44px;
    font-size: 15px;
    background: linear-gradient(90deg, #2156A4, #1862CE);
    border: 1px solid #2156A4 !important;
    box-shadow: rgba(130, 141, 151, 0.568) 0px 2px 10px;
    color: white;
    border: none;
    min-width: 160px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }

  .decline{
    background: #fff;
    border: 1px solid #E9ECEF !important;
    color: black;
    box-shadow: rgb(224 224 224 / 57%) 0px 2px 10px;
    transition: ease 0.2s;
  }
  .decline:hover{
    background-color: white;
    box-shadow: rgb(224 224 224 / 70%) 0px 5px 15px;
  }

  img {
    overflow-clip-margin: content-box;
    overflow: clip;
   
  }

  .document-box .download-document {
    margin-left: auto;
    cursor: pointer;
  }

  .header-box img{
    height: 76px;
    width: 76px;
  }
  .header-heading{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    margin-bottom: 20px;
  }

  .header-heading h2{
    margin: 0;
    color: #101828;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0;
  }
  .header-heading p{
    margin: 0;
    color: #6C7A89;
    font-size: 14px;
    font-weight: 400;
  }


  </style>
  