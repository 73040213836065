<template>
    <div class="custom-dropdown">
      <input 
        type="text" 
        v-model="inputValue" 
        :placeholder="placeholder" 
        @focus="isDropdownOpen = true" 
        @blur="closeDropdown"
        @keydown.enter="handleEnter"
      />
      <ul v-if="isDropdownOpen" class="dropdown-list">
        <li 
          v-for="item in filteredItems" 
          :key="item" 
          @mousedown.prevent="selectItem(item)" 
          class="dropdown-item"
        >
          {{ item }}
        </li>
      </ul>
    </div>
  </template>
  
  <script setup>
  import { ref, computed, defineProps, defineEmits, watch } from 'vue';
  
  const props = defineProps({
    items: Array,
    placeholder: String,
    modelValue: String
  });
  
  const emit = defineEmits(['update:modelValue', 'selectItem', 'enterKey']);
  
  const inputValue = ref(props.modelValue || '');
  const isDropdownOpen = ref(false);
  
  const filteredItems = computed(() => {
    //const searchTerm = inputValue.value.toLowerCase();
    // return props.items.filter(item => item.toLowerCase().includes(searchTerm));
    return props.items
  });
  
  const selectItem = (item) => {
    inputValue.value = item;
    emit('selectItem', item);
    emit('update:modelValue', item);
    inputValue.value = '';

    // isDropdownOpen.value = false;
  };
  
  const closeDropdown = () => {
    setTimeout(() => {
      isDropdownOpen.value = false;
    }, 200);
  };
  
  const handleEnter = (event) => {
    const matchingItem = filteredItems.value.find(item => item.toLowerCase() === inputValue.value.toLowerCase());
    if (matchingItem) {
      selectItem(matchingItem);
    }
    emit('enterKey', event);
  };
  
  watch(() => props.modelValue, newValue => {
    inputValue.value = newValue;
  });
  </script>
  
  <style scoped>
  .custom-dropdown {
    position: relative;
    width: 96%;
    height: 45px;
  }
  
  .custom-dropdown input {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    outline: 1px solid #DEE2E6;
    border: none;
    color: black;
    padding-left: 20px; /* Set padding-left */
    box-sizing: border-box; /* Include padding in width */
    font-size: 16px;
    transition: 0.2s;
  }
  
  .custom-dropdown input:focus {
    outline: 1px solid #c7c7c7;
  }
  
  .dropdown-list {
    position: absolute;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    background: white;
    border: 1px solid #DEE2E6;
    border-radius: 6px;
    margin-top: 4px;
    padding: 0;
    z-index: 1000;
  }
  
  .dropdown-item {
    padding: 8px 12px;
    cursor: pointer;
    list-style-type: none;
  }
  
  .dropdown-item:hover {
    background-color: #f0f0f0;
  }
  </style>
  