<!-- 
STATUS:
0 = process still active
1 = process successfully finished
2 = process failed

STATE:
0 = user not signed
1 = user signed
2 = user declined
3 = user signed with restrictions
-->

<template>
  <div class="info-badge">
    <div v-if="props.status == 'Signed'" class="badge-box signed">
        <img src="../../public/signedCircle.svg" alt="">
        <p v-if="activeTab === 'AP'">Schvalene</p>
        <p v-else>{{$t('infoBadge.signed')}}</p>
    </div>
    
    <div v-if="props.status == 'Canceled'" class="badge-box cancelled">
        <img src="../../public/declinedCircle.svg" alt="">
        <p>{{$t('infoBadge.canceled')}}</p>
    </div>
    
    
    <div v-if="props.status == 'NotSigned'" class="badge-box notSigned">
        <img src="../../public/notSignedCircle.svg" alt="">
        
        <p v-if="activeTab === 'AP'">Neschvalene</p>
        <p v-else>{{$t('infoBadge.notSigned')}}</p>

    </div>
    
    <div v-if="props.status == 'Declined'" class="badge-box declined">
        <img src="../../public/declinedCircle.svg" alt="">
        <p>{{$t('infoBadge.declined')}}</p>
    </div>

    <div v-if="props.status == 'SignedRestriction'" class="badge-box cancelled">
        <img src="../../public/signedCircleRestriction.svg" alt="">
        <p>{{$t('infoBadge.signedCondition')}}</p>
    </div>
    </div>
</template>

<script setup>

import { computed, defineProps } from "vue";
import { useStore } from "vuex";
const store = useStore();

const activeTab = computed(() => store.state.activeTab.abbr);

const props = defineProps({
  status: Text,
});


</script>

<style scoped>

.badge-box {
    background: #E3FCEC;
    color: #40C377;
    display: flex;
    gap: 5px;
    flex-direction: row;
    height: 10px;
    padding: 4px 8px;
    justify-content: space-around;
    align-items: center;
    border-radius: 25px;
    font-weight: 600;
}

.declined {
    background: #ef868640;
    color: #C34040;
}

.notSigned {
    background: #c1d0e5;
    color: #2156A4;
}


.cancelled {
    background: #f5ecde;
    color: #c36f40;
}
.badge-box p {
    margin: 0;
    font-size: 10px;
}
 
 .info-badge {
    width: auto;
    position: static;
    display: flex;
    flex-direction: row-reverse;
 }

</style>
