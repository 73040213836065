
// =============================================================
// ELEMENTS AND COMPONENTS
// =============================================================


<template>
  <div class="add-document">
    <div class="header">
      <h1>{{ $t("verify.verification") }}</h1>
      <div class="header-buttons">
        <button class="btn sign" @click="verify">{{ $t("verify.button") }}</button>
        <button class="btn sign" v-if="fileName" @click="deleteFile">{{ $t("verify.cancel") }}</button>
      </div>
    </div>

    <div class="content" @click="selectFile">
        <div class="file-info" v-if="fileName">
        <h2>{{ $t("verify.file") }}</h2>
        <div class="inline-fileinfo">
          <span>{{ fileName }}</span>
        </div>

        <div v-if="verificationDetails" class="verification-details">
        <h2>{{ $t("verify.detailsTitle") }}</h2>
        <p>{{ $t("verify.certificate") }}: {{ verificationDetails.certificate.common_name }}</p>
        <p>{{ $t("verify.organisation") }}: {{ verificationDetails.certificate.organization }}</p>
        <p>{{ $t("verify.signDate") }}: {{ verificationDetails.signing_time }}</p>
        <p>{{ $t("verify.status") }}: {{ verificationDetails.validity}}</p>
      </div>

      </div>
      <div class="btn-box" v-if="!file">
        
        <div 
          class="drag-drop"
          :class="{ 'highlight': isDragOver }"
          ref="dropArea"
          @dragleave="handleDragLeave" @dragover.prevent="handleDrag" @drop.prevent="handleDrop"
          
        >
        
        <input
          type="file"
          ref="fileInput"
          @change="handleFileUpload"
          accept="application/pdf"
          style="display: none"
        />
        <img v-if="isDragOver" src="../../public/importFile.png" @click="selectFile" alt="">
        <img v-else src="../../public/importFileClosed.png" @click="selectFile" alt="">
        <!-- <button class="btn add-doc" @click="selectFile">Pridať dokument</button> -->
        <div class="drag-drop-box">
          {{$t("addDocument.dragAndDrop")}}
        </div>
        </div>
        </div>
    </div>


</div>

  <transition>
    <InfoPopUp v-if="(store.state.msg.length > 0)" />
  </transition>


  <ModalWindow
    :show="showAddUserModal"
    :mode="0"
    @close="closeAddUserModal"
  />
</template>


// =============================================================
// METHODS AND INTERACTIONS
// =============================================================


<script setup>

import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import {
  verifyDocument
} from "../services.js";
import { mutationMethods} from "../store/Istore.js";
import dbg from "../debug.js";
import ModalWindow from "./ModalWindow.vue";
import InfoPopUp from './InfoPopUp.vue';
import { useI18n } from 'vue-i18n';


// =============================================================
// REFS
// =============================================================

const store = useStore();
const fileInput = ref(null);
const file = ref(null);
const fileName = ref('');
const isDragOver = ref(false);
const dropArea = ref(null);
const { t } = useI18n();
const verificationDetails = ref(null);
// const router = useRouter();


// =============================================================
// COMPUTED and WATCH
// =============================================================

const uploadedFile = computed(() => store.getters.getUploadedFile);
watch(uploadedFile, (newVal) => {
  dbg(newVal);
});


const uploadedFileName = computed(() => {
  dbg("uploadedFileName", uploadedFileName);
  return store.state.uploadedFile ? store.state.uploadedFile.name : "";
});


// =============================================================
// METHODS
// =============================================================

const selectFile = (event) => {
  event.stopPropagation();
  dbg("select file", event);
  fileInput.value.click();
};

const popUp = (type, text) => {
  store.commit(mutationMethods.PUSH_MSG, { type: type, text: text });
};


// Pridanie dokumentu do store a do databazy
const handleFileUpload = async (event) => {
  dbg("Button Add Document clicked");

  file.value = event.target.files[0];
  fileName.value = file.value.name;
  dbg("file: ", fileName.value);
};


const handleDrag = () => {
  isDragOver.value = true;
};

    const handleDragLeave = (event) => {
      // Overiť, či relatedTarget nie je vnútri dropArea
      dbg("handleDragLeave", event);
      if (dropArea.value && !dropArea.value.contains(event.relatedTarget)) {
        isDragOver.value = false;
      }
    };

const handleDrop = async (event) => {

  
  file.value = event.dataTransfer.files[0];
  if (!file.value.name.endsWith('.pdf')) {
    file.value = null;
    return;    
  }
  fileName.value = file.value.name;
  dbg("file: ", fileName.value);
  
};

// Vymazanie dokumentu 
const deleteFile = (event) => {
  event.stopPropagation(); 
  dbg("Button Remove clicked");
  isDragOver.value = false;
  file.value = null;
  fileName.value = null;
  verificationDetails.value = null;
};

const verify = async () => {
    if (!file.value) {
        popUp(2, t("popUp.fileVerify")); 
        return;
    }
    try {
        let response = await verifyDocument(file.value);
        console.log(response.data);
        verificationDetails.value = response.data;
        if (response.status === 200) {
            popUp(0, t("popUp.sucessfulVerify")); 
        }
    } catch (error) {
        popUp(2, t("popUp.failed"));
    }

};

</script>


// =============================================================
// STYLES
// =============================================================



<style scoped>
* {
  font-family: "Poppins", sans-serif;
}
h1 {
  font-size: 26px;
  font-weight: 500;
  color: #141e27;
}

h2 {
  color: #16212bc9;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0.2em;
}

.add-document {
  display: flex;

  flex-direction: column;
  width: 900px;
  margin: 0 auto;
  padding: 2em;
  border-radius: 15px;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}
.header-buttons {
  display: flex;
  gap: 1rem;
}

.header h1 {
  white-space: nowrap;
}

.btn {
  padding: 0.75em 1em;
  border: none;
  border-radius: 8px;
  background: #f1f1f1;
  cursor: pointer;
  transition: background 0.3s;
}

.btn.add-doc {
  background: #2156a4;
  color: white;
  font-size: 15px;
}

.btn.sign {
  background: #2156a4;
  color: white;
  font-size: 15px;
  max-width: 250px;
  white-space: nowrap;
}

td img {
  padding-top: 5px;
}

.btn.add-user {
  background: white;
  border: 1px solid #e3e3e3;
  color: #1a2873;
  font-size: 15px;
}

.btn-box {
  width: 100%;
  min-height: 266px;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}

.drag-drop-box {
  margin-top: 1rem;
}

.file-info {
  width: 100%;
  min-height: 266px;
  display: flex;
  flex-direction: column;
  text-align: justify;
  background: white;
  border-radius: 8px;
  padding-inline: 1rem;
}

.inline-fileinfo {
  display: flex;
  justify-content: left; /* Center the contents horizontally */
  align-items: center; /* Center the contents vertically */
}

.inline-fileinfo span {
  color: #757575;
  text-decoration: underline;
  font-weight: 500;
  font-size: 14px;
  margin-right: 5px; /* Add margin to separate file name and close button */
}

.inline-fileinfo img {
  cursor: pointer;
}


.inline-fileinfo p {
  color: #252525;
  font-weight: 500;
  font-size: 14px;
}

.inline-fileinfo p:first-child {
  color: #ababab;
  padding-right: 0.5rem;
}

.note-box {
  width: 56%;
  min-height: 200px;
  background: white;
  border-radius: 8px;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
}

.note {
  flex: 1;
}

.note-header {
  padding-top: 1rem;
  padding-left: 1rem;
  text-align: start;
  display: flex;
  height: 250px;
  flex-direction: column;
}

.note-label, .date-label
{
  margin-top: 1rem;
}

.note label {
  font-size: 12px;
  margin-bottom: 3px;
  color: #a1a1a1;
  font-weight: 400;
}

.note input {
  width: 50%;
  border: none;
  background: #f4f4f4;
  padding: 8px 8px;
  border-radius: 4px;
  /* margin-bottom: 1rem; */
}

.note textarea {
  
  width: 90%;
  padding: 10px 10px;
  height: 50px;
  border: none;
  border-radius: 4px;
  resize: none;
  background: #f4f4f4;
}

.note textarea:focus-visible,
input:focus-visible {
  outline: 1px solid #efefef !important;
}



.drag-drop
{
  height: 150px;
  width: 86%;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #BCBCBC;

}

.drag-drop.highlight 
{
  border: 1px dashed #2156a499;
  background: rgba(33, 86, 164, 0.02);
}
.required {
  color: red;
}



</style>
