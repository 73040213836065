<template>

  <transition name="slide" @before-enter="beforeEnter" @enter="enter" @leave="leave">
    <div class="right-side-bar" v-if="show" v-click-outside="closeOutside">
      <!-- <img src="../../public/closeSideBar.png" class="img-close" @click="close"> -->
      <div class="bar-box header">
        <div class="header-box">
          <div class="inline-box close-btn" @click="close">
            <img src="../../public/rightArrow.svg" alt="">
            <span v v-if="stackLayer == 'sign'">{{ $t("rightSideBar.close") }}</span>
            <span v v-if="stackLayer == 'approve'"> {{ $t("rightSideBar.backToFlow") }}</span>
            <img class="delete-img" v-if="data.value.mine_process === true" src="../../public/deleteRed.png"
              @click="removeFlow" />

          </div>
          <div class="header-title">
            <div class="inline-title">
              <img v-if="data.value.document_certificate == 1" src="../../public/minexLogo.svg" alt="">
              <img v-if="data.value.document_certificate == 0" src="../../public/qsignLogo.svg" alt="">
              <div class="inline-title-box">
                <h2>{{ data.value.document_title }}</h2>
                <!-- ============= -->
                <div class="process-status">
                  <span class="ResultText"
                    v-if='activeMainTab == "AP" && ((data.value.document_status == "Signed" || data.value.document_status == "SignedRestriction") && data.value.document_status != "Canceled")'>Schvalené
                  </span>
                  <span class="ResultText"
                    v-if='(activeMainTab == "DS" || activeMainTab == "QDS") && ((data.value.document_status == "Signed" || data.value.document_status == "SignedRestriction") && data.value.document_status != "Canceled")'>{{
    $t("infoBadge.signed") }}
                  </span>

                  <img
                    v-if="((data.value.document_status == 'Signed' || data.value.document_status == 'SignedRestriction') && data.value.document_status != 'Canceled')"
                    src="../../public/signedText.png">

                  <span class="ResultText" v-if='activeMainTab == "AP" && data.value.document_status == "Canceled"'>{{
    $t("rightSideBar.approvalFailed") }}</span>
                  <span class="ResultText"
                    v-if='(activeMainTab == "DS" || activeMainTab == "QDS") && data.value.document_status == "Canceled"'>{{
    $t("rightSideBar.signatureFailed") }}</span>
                  <img v-if="data.value.document_status == 'Canceled'" src="../../public/failedText.png">

                  <span
                    v-if="data.value.previous == 'NotSigned' && data.value.document_is_order && !data.value.document_failed"
                    class="ResultText">{{ $t("rightSideBar.waiting") }}</span>
                  <img
                    v-if="data.value.previous == 'NotSigned' && data.value.document_is_order && !data.value.document_failed"
                    src="../../public/waitText.png">

                  <span v-if="data.value.document_status == 'Declined'" class="ResultText">{{ $t("infoBadge.declined")
                    }}</span>
                  <img v-if="data.value.document_status == 'Declined'" src="../../public/declinedText.png">
                </div>

                <!-- ============= -->

              </div>
            </div>

            <div class="header-date">
            </div>
          </div>


          <div class="inline-info">
            <div class="block-info">
              <div class="inline">
                <h2>{{ $t("rightSideBar.processID") }}</h2>
                <ToolTip :text="'Copy to clipboard'">
                  <img :src="currentImage" @click="handleImageClick" alt="Copy to clipboard" style="cursor: pointer;">
                </ToolTip>
              </div>
              <h3 class="process-id">{{ data.value.process_id }}</h3>
            </div>
            <div class="block-info">
              <h2>{{ $t("rightSideBar.author") }}</h2>
              <h3 class="author-info">{{ data.value.owner }}</h3>
            </div>
            <div class="block-info">
              <h2>{{ $t("rightSideBar.dueDate") }}</h2>
              <h3>{{ data.value.document_due_date }}</h3>
            </div>
            <div class="block-info">
              <h2>{{ $t("rightSideBar.created") }}</h2>
              <h3>{{ data.value.document_create_date }}</h3>
            </div>
          </div>


          <div class="header-bottom">
            <div class="slider-box">
              <img src="../../public/sign.svg">
              <div class="progress-bar-box">
                <ProgressBar :mode="0" :status="data.value.document_status" :possibleCount="data.value.signed_count"
                  :allCount="data.value.all_count" :title='$t("addDocument.youSignDoc")'
                  :subtitle='$t("addDocument.awaitingSignature")' />
              </div>
            </div>

            <div class="tabs-box">
              <div class="tab" :class="{ active: activeTab === 'recipients' }"
                @click="() => setActiveTab('recipients')">
                <span>{{ $t("rightSideBar.recipients") }} ({{ data.value.all_count }})</span>
              </div>
              <div class="tab" :class="{ active: activeTab === 'comments' }" @click="() => setActiveTab('comments')">
                <span>{{ $t("rightSideBar.comments") }} ({{ data.value.comments.length > 9 ? '9+' :
    data.value.comments.length }})</span>
              </div>
              <div class="tab" :class="{ active: activeTab === 'documents' }" @click="() => setActiveTab('documents')">
                <span>{{ $t('rightSideBar.documents') }} ({{ data.value.document_name.length }})</span>
              </div>
              <div class="tab" :class="{ active: activeTab === 'other' }" @click="() => setActiveTab('other')">
                <span>{{ $t('rightSideBar.other') }} ({{ visibleOtherButtonsCount }})</span>
              </div>
            </div>
          </div>
          <!-- <p>{{ data.value.document_note }}</p> -->
        </div>
      </div>
      <div class="box-body">
        <!-- <div class="bar-box note">
      <h3>{{$t("rightSideBar.note")}}:</h3>
      <p>{{ data.value.document_note }}</p>
    </div> -->

        <!-- Comments section -->
        <div class="bar-box comments" v-if="activeTab === 'comments'">
          <CommentSection :data="data.value.comments" @reloadComments="updateCommentSection" />

          <div class="input-container">
            <input @keydown.enter="sendMsg" v-model="commentMsg" type="text"
              placeholder="Tento dokument schvalujem..." />
            <button class="submit-button">
              <!-- Sem vlož ikonu modrého šípu, prípadne použi fontovú ikonu alebo pod. -->
              <img @click="sendMsg" src="../../public/submitIcon.svg" alt="Submit" />
            </button>
          </div>

        </div>

        <!--TAB Documents section  -->
        <div class="bar-box bar-box-documents" v-if="activeTab === 'documents'">
          <div class="document-box" v-for="(document, index) in data.value.document_name" :key="index"
            @click="openInNewWindow(data.value.process_id[index], document)">
            <img src="../../public/sidebar_document.svg" alt="">
            <h4>{{ document }}</h4>
            <img class="download-document" src="../../public/download.svg" alt=""
              @click.stop="preview(data.value.process_id[index], document)">
          </div>
        </div>

        <!-- TAB Other section  -->
        <div class="bar-box other" v-if="activeTab === 'other'">

          <!-- CREATE AUTOMATIC SIGNATURE PROCESS BUTTON -->
          <div class="section-box"
            v-if="activeMainTab === 'AP' && data.value.joined_process_id == null && (data.value.signed_count == data.value.all_count) && (!showConfirm) && (data.value.document_status == 'Signed' || data.value.document_status == 'SignedRestriction')">
            <div class="section-text-box">
              <p class="first-p">{{ $t("rightSideBar.automaticFirst") }}</p>
              <p class="second-p">{{ $t("rightSideBar.automaticSecond-1") }}
              <span>{{$t("rightSideBar.automaticSecond-2")}}</span>
              {{ $t("rightSideBar.automaticSecond-3") }}
              <span>{{ $t("rightSideBar.automaticSecond-4")}}</span></p>
            </div>
            <button @click="signatureFromApproval">{{ $t("rightSideBar.automaticButton") }}</button>
          </div>

          <!-- SHOW EXISTING JOINED SIGNATURE PROCESS BUTTON -->
          <div class="section-box" v-if="activeMainTab === 'AP' && data.value.joined_process_id != null">
            <div class="section-text-box">
              <p class="first-p">{{ $t("rightSideBar.showJoinedFirst") }}</p>
              <p class="second-p">{{ $t("rightSideBar.showJoinedSecond-1") }}<span>{{
    $t("rightSideBar.showJoinedSecond-2") }}</span>{{ $t("rightSideBar.showJoinedSecond-3") }}<span>{{
    $t("rightSideBar.showJoinedSecond-4") }}</span></p>
            </div>
            <button @click="showJoinedSignature">{{ $t("rightSideBar.showJoinedButton") }}</button>
          </div>


          <!-- EDIT BUTTON -->
          
          <div class="section-box"
            v-if="(data.value.internal_users || data.value.external_users) && data.value.mine_process == true && (data.value.document_status != 'Signed' && data.value.document_status != 'Canceled')">
            <div class="section-text-box">
              <p class="first-p">{{ $t("rightSideBar.editFirst") }}</p>
              <p class="second-p">{{ $t("rightSideBar.editSecond-1") }}<span>{{ $t("rightSideBar.editSecond-2")
                  }}</span>{{ $t("rightSideBar.editSecond-3") }}<span>{{ $t("rightSideBar.editSecond-4") }}</span>{{
    $t("rightSideBar.editSecond-5") }}</p>
            </div>
            <button @click="editUsers('internal')">{{ $t('rightSideBar.internal') }}</button>
            <button @click="editUsers('external')">{{ $t('rightSideBar.external') }}</button>
          </div>

          <!-- APPROVAL JOIN STATUS BUTTON -->
          <div class="section-box" v-if="data.value.approval_process && activeMainTab != 'AP' && stackLayer == 'sign'">
            <div class="section-text-box">
              <p class="first-p">{{ $t("rightSideBar.approvalJoinFirst") }}</p>
              <p class="second-p">{{ $t("rightSideBar.approvalJoinSecond-1") }}<span>{{
              $t("rightSideBar.approvalJoinSecond-2") }}</span>{{ $t("rightSideBar.approvalJoinSecond-3") }}<span>{{
              $t("rightSideBar.approvalJoinSecond-4") }}</span></p>
            </div>
            <button @click="approvalLinkDetail">{{ $t("rightSideBar.approvalJoinButton") }}</button>
          </div>

          <!-- SEND BUTTON -->
          <div class="section-box"
            v-if="((data.value.signed_count == data.value.all_count) && activeMainTab === 'QDS' && (!showConfirm) && (data.value.document_status == 'Signed' || data.value.document_status == 'SignedRestriction'))">
            <div class="section-text-box">
              <p class="first-p">ODOSLAT ODOSLAT</p>
              <p class="second-p">ODOSLAT ODOSLAT ODOSLAT</p>
            </div>
            <button @click="showSendMailModal(data.value.process_id)">{{ $t("rightSideBar.sendButton") }}</button>

          </div>

        </div>


        <!-- TAB Recipients section  -->

        <div class="recipients-tab" v-if="activeTab == 'recipients'">
          <div :class="['tab', { active: recipientsTab === 'internal' }]" @click="recipientsTab = 'internal'">
            {{ $t('rightSideBar.internal') }} ({{ data.value.internal_users.length }})
          </div>
          <div :class="['tab', { active: recipientsTab === 'external' }]" @click="recipientsTab = 'external'">
            {{ $t('rightSideBar.external') }} ({{ data.value.external_users.length }})
          </div>
        </div>


        <div class="bar-box" v-if="activeTab === 'recipients'">
          <div class="user-list">
            <div v-for="user in recipientsTab === 'internal' ? data.value.internal_users : data.value.external_users"
              :key="user.user_id" class="user-item">
              <div class="user-name">
                <div class="order-controls"
                  v-if="showConfirm && data.value.document_is_order && user.editable == 'false'">
                  <input type="text" :value="user.signature_order.toString()" disabled
                    style="width: 30px; text-align: center" class="order-input" />
                </div>

                <span v-if="!showConfirm && data.value.document_is_order" style="margin-right: 1rem;">{{
                user.signature_order }}</span>
                <div class="order-controls"
                  v-if="showConfirm && data.value.document_is_order && user.editable == 'true'">
                  <span v-if="data.value.editable_order == user.signature_order - 1">‎</span>
                  <span v-if="data.value.editable_order != user.signature_order - 1" class="arrow up"
                    @click="moveUserUp(user.user_id)">▲</span>
                  <input type="text" :value="user.signature_order.toString()"
                    @keyup.enter="updateUser($event, user.user_id)" @blur="updateUser($event, user.user_id)"
                    style="width: 30px; text-align: center" class="order-input" required />
                  <span class="arrow down" @click="moveUserDown(user.user_id)">▼</span>
                </div>
                <img src="../../public/profile.svg">
                <div class="user-name-flex">
                  <span class="user-email">{{ user.user_email }}</span>

                  <span v-if="activeMainTab === 'AP' && (user.signed == 'Signed' || user.signed == 'SignedRestriction')"
                    class="user-date">{{ $t("rightSideBar.approved") }}: {{ user.signed_at ? user.signed_at : '------'
                    }}</span>
                  <span
                    v-if="(activeMainTab === 'DS' || activeMainTab === 'QDS') && (user.signed == 'Signed' || user.signed == 'SignedRestriction')"
                    class="user-date">{{ $t("rightSideBar.signed") }}: {{ user.signed_at ? user.signed_at : '------'
                    }}</span>

                  <span v-if="user.signed == 'Declined'" class="user-date">{{ $t("rightSideBar.declinedAt") }}: {{
                  user.signed_at }}</span>
                </div>
              </div>
              <InfoBadge :status=user.signed />
              <div class="action-btn">
                <img v-if="user.signed == 'SignedRestriction'" src="../../public/noteObjection.png" style="width: 18px;"
                  @click="showNote(user.note)" />
                <img v-if="user.note && user.signed != 'SignedRestriction'" src="../../public/note.png"
                  @click="showNote(user.note)" />
                <img v-if="user.note == null || user.note == ''" src="../../public/noteDisabled.png" />

              </div>
            </div>
            <div class="user-item"
              v-if="props.mode == 'myDocs' && (data.value.document_status != 'Canceled') && data.value.signed_count != data.value.all_count && showConfirm">
              <div class="user-name">
                <img src="../../public/addSigner.png" @click="addUser" />
                <p class="user-email">{{ $t("rightSideBar.addUser") }}</p>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="bar-box footer">
        <button class="btn cancel red" @click="cancel(data.value.process_id)"
          v-if="data.value.users && showConfirm && props.mode != 'sharedDocs'">
          {{ $t("rightSideBar.cancel") }}
        </button>
        <button class="btn confirm" @click="confirm"
          v-if="data.value.users && showConfirm && props.mode != 'sharedDocs'">
          {{ $t("rightSideBar.confirm") }}
        </button>

        <!-- EDIT BUTTON -->
        <!-- <button class="btn confirm" @click="showConfirm = true"
          v-if="data.value.users && !showConfirm && props.mode != 'sharedDocs' && (data.value.document_status != 'Signed' && data.value.document_status != 'Canceled')">
          Edit
        </button> -->


        <div class="button-footer" v-if='activeMainTab === "AP"'>
          <button class="btn approve" v-if="(props.mode == 'sharedDocs' && data.value.document_status == 'NotSigned')"
            @click="approve">
            {{ $t("rightSideBar.approve") }}
          </button>

          <button class="btn decline red"
            v-if="(props.mode == 'sharedDocs' && (data.value.document_status == 'NotSigned'))" @click="decline">
            {{ $t("rightSideBar.decline") }}
          </button>

          <!-- <button class="btn download"
            v-if="(props.mode == 'myDocs' && (data.value.signed_count == data.value.all_count) && (!showConfirm) && (data.value.document_status == 'Signed' || data.value.document_status == 'SignedRestriction'))"
            @click="signatureFromApproval">

            Signature

          </button> -->

        </div>
        <div class="button-footer" v-if='activeMainTab === "DS" || activeMainTab === "QDS"'>
          <button class="btn decline red"
            v-if="(props.mode == 'sharedDocs' && (data.value.previous == 'Signed' || data.value.document_is_order == 0) && (data.value.document_status == 'NotSigned'))"
            @click="decline">
            {{ $t("rightSideBar.decline") }}
          </button>
          <button class="btn sign"
            v-if="(props.mode == 'sharedDocs' && (data.value.previous == 'Signed' || data.value.document_is_order == false)) && (data.value.document_status == 'NotSigned')"
            @click="sign">
            {{ $t("rightSideBar.sign") }}
          </button>

        </div>

        <button class="btn download"
          v-if="((data.value.signed_count == data.value.all_count) && (!showConfirm) && (data.value.document_status == 'Signed' || data.value.document_status == 'SignedRestriction'))"
          @click="downloadSigned">

          <img src="../../public/download_white.svg" alt="">

          {{ $t("rightSideBar.download") }}


        </button>

        <!-- <button class="btn download"
          v-if="((data.value.signed_count == data.value.all_count) && activeMainTab === 'QDS' && (!showConfirm) && (data.value.document_status == 'Signed' || data.value.document_status == 'SignedRestriction'))"
          @click="showSendMailModal(data.value.process_id)">

          {{ $t("rightSideBar.sendButton") }}

        </button> -->

      </div>
    </div>
  </transition>

  <SignModal :show="showAddNoteModal" @close="showAddNoteModal = false" :mode="addNoteModalMode"
    :docId="data.value.process_id" :filename="data.value.document_name" />
  <ModalWindow :show="showAddUserModal" :mode="mode" :note="note" :doc_id="data.value.process_id"
    :process_type="store.getters['getActiveTab'].abbr" :send_to="send_to" @close="showAddUserModal = false"
    @remove="removeFlowEmmit" :joined_process_id="data.value.joined_process_id" />

  <AutoAddModal :processId="data.value.process_id" :show="showAutoAddModal" @close="showAutoAddModal = false"
    @closeRightSidebar="emit('close')" />
</template>

<script setup>
import { defineProps, watch, defineEmits, ref, computed, onMounted } from "vue";
import SignModal from "./SignModal.vue";
import { useStore } from "vuex";
import { getterMethods, mutationMethods } from "../store/Istore.js";
import { editDocument, fetchDocumentDetails, loadSendTo, loadUsers, downloadDocument, sendMessage, fetchSharedDocuments, loadMessages, fetchApprovalDocument, downloadOneOriginal } from "../services.js";
import ModalWindow from "./ModalWindow.vue";
import dbg from "@/debug";
import ProgressBar from './ProgressBar.vue';
import AutoAddModal from './AutoAddModal.vue';
import InfoBadge from "./InfoBadge.vue";
import CommentSection from "./CommentSection.vue";
import { defineExpose } from 'vue'
import ToolTip from "./PopUpToolTip.vue";
import copyImage from '../../public/copy_clipboard.svg';
import okImage from '../../public/ok_clipboard.svg';
import { useStoreAddProcess } from "@/store/storeHooks";
import api from "@/api";
// import eventBus from "@/eventBus";
//this function is called from parent component SharedDocuments
//when user clicks on document in shared documents to refresh some refs
const refreshYourself = () => {
  activeTab.value = 'recipients';
  stackLayer.value = 'sign';
  console.log(`Function called from parent component`);
  showConfirm.value = false;
};

const storeAddProcess = useStoreAddProcess();

function editUsers(userCategory) {

activeTab.value = 'recipients';
const editUsers = store.getters['getDocumentDetail'];

const external_users = editUsers.external_users.map(user => ({
  userId: user.user_id,
  name: user.user_email, // Assuming user_email is used as name here
  email: user.user_email,
  signed: user.signed,
  note: user.note,
  order: user.signature_order
}));


const internal_users = editUsers.internal_users.map(user => ({
  userId: user.user_id,
  name: user.user_email, // Assuming user_email is used as name here
  email: user.user_email,
  signed: user.signed,
  note: user.note,
  order: user.signature_order
}));


store.commit('addExternalUsers', external_users); //pre external
console.log('External_users', external_users);
store.commit('addUsers', internal_users); 
console.log('Internal users', internal_users);


if (userCategory == 'internal'){
  store.commit('setModalStep', 3);
  storeAddProcess.commit('openModal', "editProcess");
  store.commit('setToggleAdditionalStep', null);
}
else {
  store.commit('setToggleAdditionalStep', true); //pre external
  store.commit('setModalStep', 4); //pre external 
  storeAddProcess.commit('openModal', "editProcess");
}

dbg("opened");
}

const activeTab = ref('recipients'); // defaultne nastavenie


const recipientsTab = ref('internal') //internal or external 

const setActiveTab = (tab) => {
  activeTab.value = tab;
};

const currentImage = ref(copyImage);


const updateCommentSection = async () => {

  var process_type;
  if (stackLayer.value == 'approve') {
    process_type = 'AP';
  }
  else {
    process_type = store.getters['getActiveTab'].abbr;
  }

  const requestData = {
    process_id: data.value.value.process_id,
    process_type: process_type,
  }

  let response = await loadMessages(requestData);
  store.commit('updateComments', response);
  console.log("Comment section updated");

};


defineExpose({
  refreshYourself
});

const props = defineProps({
  show: Boolean,
  signed: Number,
  mode: Number,
  showButtons: Boolean,
});



const copyToClipboard = () => {
  var copyText = data.value.value.process_id;
  navigator.clipboard.writeText(copyText);
};

const handleImageClick = () => {
  copyToClipboard();
  currentImage.value = okImage;
  setTimeout(() => {
    currentImage.value = copyImage;
  }, 2000);
};


const beforeEnter = (el) => {
  el.style.transform = 'translateX(100%)';
};

const enter = (el, done) => {
  // Vyvolanie reflow je potrebné, aby sa zaistilo, že prechod bude správne fungovať
  el.offsetHeight; // trigger reflow
  el.style.transition = 'transform 0.15s ease-in-out';
  el.style.transform = 'translateX(0)';
  done();
};

const leave = (el, done) => {
  el.style.transform = 'translateX(100%)';
  setTimeout(() => done(), 300); // počkaj na ukončenie prechodu
};


const showAddNoteModal = ref(false);
const addNoteModalMode = ref();
const emit = defineEmits(["close", "reload", "updateValue", "showJoinedSignature"]);

var signProcessId = ref();


const approvalLinkDetail = async () => {
  dbg("approvalLinkDetail", data.value.value.approval_process);
  dbg("test before", store.getters['getDocumentDetail'])


  signProcessId.value = data.value.value.process_id;
  dbg("signProcessId", signProcessId.value);

  var responseData = await fetchApprovalDocument(data.value.value.approval_process)
  dbg("responseData", responseData);
  store.commit(mutationMethods.STORE_DOCUMENT_DETAIL, responseData);
  stackLayer.value = 'approve';
  activeTab.value = 'recipients';

  // https://stackoverflow.com/questions/42632711/how-to-call-function-on-child-component-on-parent-events
  dbg("test after", store.getters['getDocumentDetail'])

};


const commentMsg = ref("");
const data = ref({ value: { users: [] } }); // Ensure users are initialized as an empty array
const store = useStore();
var mode = ref(0);
var send_to = ref();
var note = ref("");

const showAutoAddModal = ref(false);

const activeMainTab = computed(() => store.getters['getActiveTab'].abbr);
const changedMainTab = computed(() => store.getters['getActiveTab']);


watch(changedMainTab, () => {
  emit('close')
})


const showConfirm = ref(props.showButtons);
const showAddUserModal = ref(false);

//for stack navigation in rightsidebar, can be  | sign | or | approve |
const stackLayer = ref('sign');

// Using a computed property to automatically update the template when users change
const usersComputed = computed(() => {
  return store.getters[getterMethods.GET_DOCUMENT_DETAIL]?.users || [];
});

watch(usersComputed, (newUsers) => {
  data.value.users = newUsers; // Update users whenever the store's users change
}, { deep: true });

const updateValue = () => {

  emit("updateValue", showConfirm.value);
}

// Load initial data when the component is mounted
onMounted(() => {
  if (!store.state.documentDetail.users) {
    // Assume fetchData() is a method to fetch user data
    fetchDocumentDetails();
  }
});
// watch for changedMainTab
watch(
  () => changedMainTab,
  (newValue) => {
    emit("reload");
    console.log("Changed main tab:", newValue);
  }
);



const sendMsg = async () => {
  dbg("processId sendMsg", data.value.value.process_id);

  var process_type;
  if (stackLayer.value == 'approve') {
    process_type = 'AP';
  }
  else {
    process_type = store.getters['getActiveTab'].abbr;
  }

  const requestData = {
    process_id: data.value.value.process_id,
    process_type: process_type,
    comment: commentMsg.value,
  }

  try {
    const response = await sendMessage(requestData);
    commentMsg.value = "";
    store.commit('updateComments', response);
  } catch (error) {
    console.error("Failed to send message:", error);
  }
};

const showNote = (noteValue) => {
  dbg(noteValue);
  dbg(store.getters[getterMethods.GET_DOCUMENT_DETAIL]);
  note.value = noteValue;
  mode.value = 2;
  showAddUserModal.value = true;
};

const showSendMailModal = async (process_id) => {
  mode.value = 4;
  showAddUserModal.value = true;
  send_to.value = await loadSendTo(process_id);
}

// const deleteUser = (userId, signed) => {
//   dbg(userId);
//   if (signed != 1) {
//     if (!showConfirm.value) {
//       updateValue();
//     }
//     showConfirm.value = true;
//     store.commit(mutationMethods.EDIT_REMOVE_USER, userId);
//   }
// };

const signatureFromApproval = () => {
  showAutoAddModal.value = true;
}

const showJoinedSignature = () => {


  const foundProcess = store.getters['getJoinedFlow'](data.value.value.joined_process_id)[0];

  console.log('foundProcess', foundProcess.certificate);
  if (foundProcess.certificate == true) {
    store.commit('storeActiveTab', { abbr: 'DS', fullName: 'digitalSignature' });
  }
  else {
    store.commit('storeActiveTab', { abbr: 'QDS', fullName: 'qualifiedDigitalSignature' });
  }
  console.log("foundProcess.process_id", foundProcess.process_id)
  emit("showJoinedSignature", foundProcess.process_id);

}


const downloadSigned = async () => {

  var process_type;
  if (stackLayer.value == 'approve') {
    process_type = 'AP';
  }
  else {
    process_type = store.getters['getActiveTab'].abbr;
  }

  var data_ = {
    process_id: data.value.value.process_id,
    process_type: process_type,
    signed: 1
  }
  downloadDocument(data_);
};

const cancel = async (documentId) => {
  showConfirm.value = false;
  store.commit(
    mutationMethods.STORE_DOCUMENT_DETAIL,
    await fetchDocumentDetails(documentId)
  );
  emit("reload")
};

const openInNewWindow = async (id, document) => {
  console.log("OPEN IN NEW WINDOW");
  console.log("ID", id, "DOCUMENT", document);
  var process_type;
  if (stackLayer.value == 'approve') {
    process_type = 'AP';
  } else {
    process_type = store.getters['getActiveTab'].abbr;
  }

  var data_ = {
    process_id: data.value.value.process_id,
    process_type: process_type,
    file_name: document,
    signed: 0
  };
  console.log("DATA_", data_);

  try {
    const response = await api.post(`/get-one-file`, data_, {
      responseType: 'blob'
    });

    const fileBlob = new Blob([response.data], { type: 'application/pdf' }); // Assuming the file is a PDF. Adjust the type as needed.
    const url = window.URL.createObjectURL(fileBlob);

    const newWindow = window.open(url, '_blank');
    if (newWindow) {
      newWindow.focus();
    } else {
      window.location.href = url; // fallback for older browsers
    }

    // Clean up the URL object after usage
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Exception occurred when opening document in new window:", error);
    throw error;
  }
};

const preview = async (id, document) => {
  console.log("PREVIEW");
  console.log("ID", id, "DOCUMENT", document)
  var process_type;
  if (stackLayer.value == 'approve') {
    process_type = 'AP';
  }
  else {
    process_type = store.getters['getActiveTab'].abbr;
  }

  var data_ = {
    process_id: data.value.value.process_id,
    process_type: process_type,
    file_name: document,
    signed: 0
  }
  console.log("DATA_", data_);
  await downloadOneOriginal(data_);
};

const moveUserUp = (userId) => {
  store.commit(mutationMethods.EDIT_MOVE_USER_UP, userId);
};

const moveUserDown = (userId) => {
  store.commit(mutationMethods.EDIT_MOVE_USER_DOWN, userId);
};

const updateUser = (event, userId) => {

  const value = event.target.value.replace(/\D/g, '');
  event.target.value = value;

  if (value === '') return;

  const orderNumber = parseInt(value, 10);
  const limitedOrder = Math.max(1, Math.min(orderNumber, data.value.users.length));

  console.log("user length", data.value.users.length)

  event.target.value = limitedOrder;

  store.commit(mutationMethods.EDIT_UPDATE_USER_ORDER, { userId, order: Number(limitedOrder) });
};


const confirm = async () => {
  let formattedDate;
  let signedAt;

  const internal_users = data.value.internal_users;
  const external_users = data.value.external_users;

  const sortedInternalUsers = [...internal_users].sort((a, b) => a.signature_order - b.signature_order);
  let currentOrder = 1;
  let lastOrder = sortedInternalUsers[0].signature_order;
  sortedInternalUsers[0].normalizedOrder = currentOrder;

  for (let i = 1; i < sortedInternalUsers.length; i++) {
    if (sortedInternalUsers[i].signature_order !== lastOrder) {
      currentOrder++;
    }
    sortedInternalUsers[i].normalizedOrder = currentOrder;
    lastOrder = sortedInternalUsers[i].signature_order;
  }

  const sortedExternalUsers = [...external_users].sort((a, b) => a.signature_order - b.signature_order);
  currentOrder = 1;
  lastOrder = sortedExternalUsers[0].signature_order;
  sortedExternalUsers[0].normalizedOrder = currentOrder;

  for (let i = 1; i < sortedExternalUsers.length; i++) {
    if (sortedExternalUsers[i].signature_order !== lastOrder) {
      currentOrder++;
    }
    sortedExternalUsers[i].normalizedOrder = currentOrder;
    lastOrder = sortedExternalUsers[i].signature_order;
  }

  const extractedData = {
    process_id: data.value.value.process_id,
    process_type: store.getters['getActiveTab'].abbr,
    internal_users: sortedInternalUsers.map((user) => {

      if (user.signed_at) {
        signedAt = new Date(user.signed_at);
        formattedDate = signedAt.toISOString().slice(0, 19).replace('T', ' ');
      } else {
        formattedDate = null;
      }

      return {
        note: user.note,
        signed: user.signed,
        user_id: user.user_id,
        signed_date: formattedDate,
        signature_order: user.normalizedOrder
      };
    
    }),
    external_users: sortedExternalUsers.map((user) => {
    if (user.signed_at) {
      signedAt = new Date(user.signed_at);
      formattedDate = signedAt.toISOString().slice(0, 19).replace('T', ' ');
    } else {
      formattedDate = null;
    }

    return {
      note: user.note,
      signed: user.signed,
      user_id: user.user_id,
      signed_date: formattedDate,
      signature_order: user.normalizedOrder
    };

    }),
  };

  dbg("EXTRACTED DATA", extractedData);
  var response = await editDocument(extractedData);
  if (response) {
    dbg("EXTRACTED RESPONSE", response);
    store.commit(mutationMethods.STORE_DOCUMENT_DETAIL, await fetchDocumentDetails(data.value.value.process_id));
    showConfirm.value = false;
    emit("reload");
  }
};


const addUser = async () => {

  let response = await loadUsers();

  store.commit(mutationMethods.STORE_USERS, response);
  mode.value = 1;

  dbg("showConfirm addUser", showConfirm.value);

  if (!showConfirm.value) {
    updateValue();
    dbg("updateValue addUser");
  }
  showAddUserModal.value = true;
  showConfirm.value = true;
};

const closeOutside = (event) => {

  if (!showConfirm.value && event.target.className === 'flex-item') {
    emit("close");
  }
};

const close = async () => {
  if (stackLayer.value == 'approve') {
    dbg("signProcessId", signProcessId.value)
    var responseData = await fetchSharedDocuments(signProcessId.value)
    dbg("responseData", responseData);
    store.commit(mutationMethods.STORE_DOCUMENT_DETAIL, responseData);
    stackLayer.value = 'sign';
  }
  else {
    showConfirm.value = false
    emit("close");
  }
};

const removeFlow = async () => {

  mode.value = 3;
  showAddUserModal.value = true;

};

const removeFlowEmmit = () => {
  showAddUserModal.value = false;
  emit("reload");
  emit("close");
};

watch(
  () => props.showButtons, (newValue) => {
    console.log("showConfirm value changed", newValue);
    showConfirm.value = newValue;
  });

watch(
  () => props.show,
  (newValue) => {
    console.log("Right Side Bar visibility changed:", newValue);
  }
);

watch(showConfirm, (newVal) => {
  if (newVal) {
    // Initialize temporary order for editing
    data.value.users.forEach(user => {
      user.tempOrder = user.signature_order;
    });
  }
});

// want to add console.log into computed
data.value = computed(() => {
  console.log("Data changed:", store.getters[getterMethods.GET_DOCUMENT_DETAIL]);
  return store.getters[getterMethods.GET_DOCUMENT_DETAIL];
});
// data.value = computed(( ) => store.getters[getterMethods.GET_DOCUMENT_DETAIL]);

const approve = async () => {
  showAddNoteModal.value = true;
  addNoteModalMode.value = "approve";
};

const sign = async () => {
  showAddNoteModal.value = true;
  addNoteModalMode.value = "sign";
};


const decline = async () => {
  showAddNoteModal.value = true;
  addNoteModalMode.value = "decline";
};

watch(
  () => props.data,
  (newData) => {
    console.log("Data changed:", newData);
  },
  { deep: true }
);

watch(() => store.state.users, (newUsers) => {
  data.value.users = newUsers;
}, { deep: true });


const visibleOtherButtonsCount = computed(() => {
  let count = 0;

  if (activeMainTab.value === 'AP' && data.value.value.joined_process_id == null &&
    (data.value.value.signed_count == data.value.value.all_count) && (!showConfirm.value) &&
    (data.value.value.document_status == 'Signed' || data.value.value.document_status == 'SignedRestriction')) {
    count++;
  }

  if (activeMainTab.value === 'AP' && data.value.value.joined_process_id != null) {
    count++;
  }
   
  if ((data.value.value.internal_users || data.value.value.external_users) && data.value.value.mine_process == true &&
      (data.value.value.document_status != 'Signed' && data.value.value.document_status != 'Canceled')) {
    count++;
  }

  if (data.value.value.approval_process && activeMainTab.value != 'AP' && stackLayer.value == 'sign') {
    count++;
  }

  if ((data.value.value.signed_count == data.value.value.all_count) && activeMainTab.value === 'QDS' &&
    (!showConfirm.value) && (data.value.value.document_status == 'Signed' || data.value.value.document_status == 'SignedRestriction')) {
    count++;
  }

  return count;
});

</script>

<style scoped>
.ResultText {
  margin-right: -17px;
}

.right-side-bar {
  position: fixed;
  right: 0;
  top: 0;
  width: 725px;
  height: 100vh;
  z-index: 100;
  display: flex;
  box-shadow: -10px 0px 150px rgb(0 0 0 / 15%);
  border-top: 3px solid #c0d6ec;
  flex-direction: column;
  background-color: #ffffff;
  box-sizing: border-box;
  transition: transform 0.3s ease-in-out;
}

.user-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.user-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f9fb;
  padding: 10px;
  height: 60px;
  border-radius: 5px;
}

.user-email,
.user-status {
  font-weight: 500;
}

.user-email {
  font-size: 15px;
  margin-left: 1rem;
}

.user-date {
  margin-left: 1rem;
  font-size: 13px;
  min-width: 268px;
  color: rgb(90, 90, 90);
}

.bar-box {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 20px;
  text-align: start;
}

.other {
  gap: 2rem;
  margin-top: 1rem;
}

.comments {
  height: 100%;
}

h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  color: #bcbcbc;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.inline-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.inline-box span {
  padding: 0px 7px;
  background: #DAE9F8;
  color: #2156A4;
  font-weight: 500;
  font-size: 12px;
  border-radius: 6px;
}

.bar-box h3 {
  color: #141E27;
  margin: 15px 0 10px 0;
  font-size: 17px;
}

.header {
  /* margin-bottom: 1.5rem; */
  padding-bottom: 27px;
  overflow-y: hidden;
  min-height: 270px;
  border-bottom: 1px solid #E1E5F0;
  box-shadow: rgba(100, 100, 111, 0.123) 8px 7px 29px 0px;
}

.header-box {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
  height: 100%;
  text-align: initial;
}

.close-btn .delete-img {
  margin-left: auto;
}

.header-title {

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;

}

.header-box h2 {
  font-size: 20px;
  margin: 0;
  font-weight: 600;
  color: #141E27;
}

.header-box a {

  font-weight: 500;
  margin: 8px 0 0 0;
  text-decoration: underline;
  cursor: pointer;
  font-size: 15px;
  color: #757575;
}

.box-body {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.note {
  text-align: start;
  overflow-y: hidden;
}

.note h3 {
  color: #141E27;
  margin: 0 0 10px 0;
  font-size: 17px;
}

.note p {
  margin-left: 1rem;
}


.header-owner {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-top: auto;
}

.header-owner p {
  color: #ABABAB;
  font-size: 14px;
}

.header-owner span {
  font-size: 14px;
  color: #252525;
  font-weight: 500;

}


.footer {
  margin-top: auto;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  min-height: 80px;
  gap: 2rem;
  box-shadow: rgba(100, 100, 111, 0.123) 15px 1px 24px 5px;

}

.btn {
  padding: 12px 44px;
  font-size: 15px;
  background: linear-gradient(90deg, #2156A4, #1862CE);
  border: 1px solid #2156A4 !important;
  box-shadow: rgba(130, 141, 151, 0.568) 0px 2px 10px;
  color: white;
  border: none;
  min-width: 160px;
  border-radius: 10px;
  cursor: pointer;
}

.img-close {
  width: 32px;
  height: 32px;
  top: 22px;
  left: -18px;
  position: absolute;
  transition: ease 0.2s;
}

.img-close:hover {
  transform: scale(1.4);
}

.action-btn {
  display: flex;
  width: 60px;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.user-name {
  display: flex;
  align-items: center;
  min-width: 300px;
}

.user-name-flex {
  display: flex;
  flex-direction: column;
  text-align: start;
}

button:hover {
  box-shadow: #1862CEb3 0px 2px 10px;
  background: linear-gradient(90deg, #214f96, #1658b9);
}

.red {
  background: #fff;
  border: 1px solid #E9ECEF !important;
  color: black;
  box-shadow: rgb(224 224 224 / 57%) 0px 2px 10px;
  transition: ease 0.2s;
}

.red:hover {
  box-shadow: #aa4f4f42 0px 1px 11px;
  background: white;

}

.gray {
  background-color: gray;
}


.header-bottom {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: auto;
}

.slider-box {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 1rem;
}

.progress-bar-box {
  width: 100%;
}

.inline-box {
  display: flex;
  flex-direction: row;
  align-content: center;
  gap: 0.1rem;
}

.inline-box p {
  color: #B5B5B5;
  font-size: 15px;
}

.header-date {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.user-order {
  margin-right: 0.5rem;
}


.download {

  padding: 14px 40px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: #2156A4;
  color: white;
  border: none;
  min-width: 250px;
  border-radius: 5px;
  cursor: pointer;
}

.download img {
  width: 15px;

}

.download:hover {
  box-shadow: #1862CEb3 0px 2px 10px;
}

.inline-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}


.date-box p {
  margin: 0;
}

.date-box {
  text-align: end;
}

/* Input comment section */
.input-container {
  display: flex;
  margin-top: auto;
  align-items: center;
  padding: 10px 0px;
}

.input-container input {
  border: none;
  outline: none;
  flex-grow: 1;
  border-radius: 10px;

  background: #F6F6F6;
  padding: 10px;
}

.input-container .submit-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0 10px;
}

/* Pridaj vlastné štýly pre ikonu, ak je to potrebné */
.submit-button img {
  width: 24px;
  /* Nastav veľkosť podľa potreby */
  height: 24px;
  /* Nastav veľkosť podľa potreby */
}

/* Odoberie štýly pre IE10+/Edge */
.input-container input::-ms-clear {
  display: none;
}

.order-controls {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  margin-right: 1rem;
}

/* Odoberie štýly pre všetky prehliadače */
input[type="text"]::-ms-reveal,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

.button-footer {
  display: inline-flex;
  justify-content: right;
  gap: 3rem;
}

.close-btn {
  gap: 1rem;
  color: #2156A4;
  font-size: 16px;
  cursor: pointer;
}

.process-id {
  font-size: 10px;
  color: #f70000;
  margin: 2px 0 0 0;
}

.inline {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.inline img {
  width: 20px;
}

.clipboard {
  cursor: pointer;
}


.inline-info {
  display: inline-flex;
  padding-top: 0rem;
  justify-content: center;
  gap: 2rem;
  margin-block: 2rem;
}

.block-info {
  display: flex;
  flex-direction: column;
}

.block-info h2 {
  font-size: 14px;
  margin: 0;
  font-weight: 500;
  color: #141E27;
}

.block-info h3 {
  font-size: 12px;
  margin: 0;
  font-weight: 500;
  color: #ACB7C7;
}

.tabs-box {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  cursor: pointer;
}

.tab {
  font-size: 14px;
  padding-bottom: 10px;
  font-weight: 400;
}

.tab span {
  display: inline-block;
  position: relative;
}

.tab.active span::after {
  content: '';
  display: block;
  width: 100%;
  border-bottom: 3px solid #2156A4;
  position: absolute;
  bottom: -13px;
  left: 0;
}

.tab.active {
  color: #2156A4;
  font-weight: 500;
}

.tab:hover {
  color: #2156A4;
}

.document-box {
  display: flex;
  padding: 15px 15px;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  border-radius: 7px;
  width: 95%;
  margin-top: 1px;
  margin-bottom: 0.7rem;
  background: #F7F9FB;
}

.document-box:hover {
  outline: 1px solid #77adffb9;
}

.document-box h4 {
  font-size: 14px;
  margin: 0;
  font-weight: 500;
  color: #141E27;
}

.document-box .download-document {
  margin-left: auto;
}

.section-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem 1rem 2rem;
  border-radius: 4px;
  border: 1px solid #E9EBEE;

}

.section-text-box {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  text-align: center;

}

.section-text-box .first-p {
  font-size: 12px;
  margin: 0;
  font-weight: 400;
  color: #81868E;
}

.section-text-box .second-p {
  font-size: 12px;
  margin: 0;
  font-weight: 400;
  color: #BDC0C7;
}

.second-p span {
  color: #214f96 !important;
  font-weight: 500;
}

.section-box button {
  padding: 15px 40px;
  font-size: 15px;
  background: #2156A4;
  color: white;
  border: none;
  min-width: 160px;
  border-radius: 3px;
  cursor: pointer;
}

.process-id {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100px;
}

.author-info {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 180px;
}


.inline-title-box {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: normal;
  gap: 1rem;
}

.process-status {
  display: inline-flex;
  gap: 1.3rem;
}

.process-status img {
  width: 18px;
  height: 22px;
}

.recipients-tab {
  display: flex;
  border-bottom: 1px solid #EEEEEE;
  margin-bottom: 1rem;

}

/* .recipients-tab .tab {
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 400;
  color: #A1A1A1;
}

.recipients-tab .tab.active {
  color: #333333;
  font-weight: 500;
  border-bottom: 2px solid #4486E8;
} */

.recipients-tab {
  display: flex;
  border-bottom: 1px solid #EEEEEE;
}

.recipients-tab .tab {
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 400;
  color: #777;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recipients-tab .tab::after {
  content: '';
  position: absolute;
  bottom: -1px;
  width: 33%;
  height: 3px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: transparent;
  transition: background-color 0.3s;
}

.recipients-tab .tab.active {
  color: #000;
  font-weight: 500;
}

.recipients-tab .tab.active::after {
  /* Ensure the width is 50% when active */
  background-color: #4285f4;
  ;
  /* Adjust the color to match the blue in your image */
}


.bar-box-documents {
  margin-top: 1rem;
}
</style>