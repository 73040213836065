// Mutation method names
export const mutationMethods = {
    ADD_FILE: 'addFile',
    ADD_IMAGE_SIGNATURE_FILE: 'addImageSignatureFile',
    REMOVE_FILE: 'removeFile',
    ADD_USER: 'addUser',
    ADD_EXTERNAL_USER: 'addExternalUser',
    ADD_EXTERNAL_USERS: 'addExternalUsers',
    ADD_USERS: 'addUsers',
    UPDATE_USER_ORDER: 'updateUserOrder',
    MOVE_USER_DOWN: 'moveUserDown',
    MOVE_USER_UP: 'moveUserUp',
    STORE_USERS: 'storeUsers',
    SET_CURRENT_USER: 'setCurrentUser',
    RESET_USERS: 'resetUsers',
    ADD_DOCUMENT_ID: 'addDocumentId',
    STORE_MY_DOC: 'storeMyDoc',
    STORE_SHARED_DOC: 'storeSharedDoc',
    STORE_DOCUMENT_DETAIL: 'storeDocumentDetail',
    EDIT_REMOVE_USER: 'editRemoveUser',
    EDIT_ADD_USER: 'editAddUser',
    EDIT_MOVE_USER_UP: 'editMoveUserUp',
    EDIT_MOVE_USER_DOWN: 'editMoveUserDown',
    EDIT_UPDATE_USER_ORDER: 'editUpdateUserOrder',
    DELETE_USER: 'deleteUser',
    DELETE_EXTERNAL_USER: 'deleteExternalUser',
    ADD_CERTIFICATE: 'addCertificate',
    STORE_DOCUMENT_DETAIL_NOTE: 'storeDocumentdetailNote',
    PUSH_MSG: 'pushMsg',
    POP_MSG: 'popMsg',
    STORE_ACTIVE_TAB: "storeActiveTab",
    UPDATE_COMMENTS: "updateComments",
    NEXT_MODAL_STEP: "nextModalStep",
    PREV_MODAL_STEP: "prevModalStep",
    ADD_ACTIVE_STEP: "addActiveStep",
    SPECIFIC_MODAL_STEP: "specificModalStep",
    SET_TOGGLE_ADDITIONAL_STEP: "setToggleAdditionalStep",
    RESET_TOGGLE_ADDITIONAL_STEP: "resetToggleAdditionalStep",
    SET_MODAL_STEP: "setModalStep",
    RESET_MODAL_STEPS: "resetModalSteps",
  };
  
  // Getter method names
  export const getterMethods = {
    GET_UPLOADED_FILE: 'getUploadedFile',
    GET_UPLOADED_FILE_IMAGE_SIGNATURE: 'getUploadedFileImageSignature',
    GET_USERS: 'getUsers',
    GET_STORE_USERS: 'getStoreUsers',
    GET_CURRENT_USER: 'getCurrentUser',
    GET_DOCUMENT_ID: 'getDocumentId',
    GET_DOCUMENT_DETAIL: 'getDocumentDetail',
    GET_INTERNAL_USER_IDS: 'getInternalUserIds',
    GET_EXTERNAL_USER_IDS: 'getExternalUserIds',
    GET_CERTIFICATE: 'getCertificate',
    SORTED_USERS: 'sortedUsers',
    SORTED_EXTERNAL_USERS: 'sortedExternalUsers',
    FILTER_DOCS: 'filterDocs',
    GET_ACTIVE_TAB: 'getActiveTab',
    GET_CURRENT_MODAL_STEP: "getCurrentModalStep",
    GET_JOINED_FLOW: "getJoinedFlow",
    };
  
