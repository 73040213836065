<template>
  <div class="summary-modal">
      <h2 v-if="processInfo.title">{{ $t('summaryModal.info') }}:</h2>
      <div class="info-row" v-if="processInfo.title">
          <label>{{ $t('summaryModal.title') }}: </label>
          <span>{{processInfo.title}}</span>
      </div>
      <div class="info-row" v-if="processInfo.date">
          <label>{{ $t('summaryModal.dueDate') }}: </label>
          <span>{{processInfo.date}}</span>
      </div>
      <div class="info-row" v-if="processInfo.approvalId" >
          <label>{{ $t('summaryModal.approval') }}: </label>
          <span>{{processInfo.approvalId}}</span>
      </div>
      <div class="info-row" v-if="processInfo.note">
          <label>{{ $t('summaryModal.note') }}: </label>
          <span>{{processInfo.note}}</span>
      </div>
      <h2  v-if="sortedUsers.length > 0" >{{ $t('summaryModal.internalUsers') }}</h2>
      <div class="user-row" v-for="user in sortedUsers" :key="user.id">
          {{user.name}}
      </div>
      <h2  v-if="externalUsers.length > 0" >{{ $t('summaryModal.externalUsers') }}</h2>
      <div class="user-row" v-for="user in externalUsers" :key="user.id">
          {{user.name}}
      </div>
      <h2 v-if="fileData.name.length > 0" >{{ $t('summaryModal.files') }}</h2>
      <div class="file-row" v-for="(file, index) in fileData.name" :key="index">
          {{ file }}
      </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from "vue";
import { useStoreGlobal } from "@/store/storeHooks";
import { useStoreAddProcess } from "@/store/storeHooks";
const storeGlobal = useStoreGlobal();
const store = useStoreAddProcess();

const newProcessData = computed(() => store.state.addDocument);
const sortedUsers = computed(() => storeGlobal.getters['sortedUsers']);
const externalUsers = computed(() => storeGlobal.getters['sortedExternalUsers']);
const processInfo = computed(() => store.state.newProcess);

const fileData = ref({ name: [], size: [] });
const files = ref([]);


onMounted(() => {

  if (newProcessData.value.files && newProcessData.value.fileData) {
    files.value = newProcessData.value.files;
    fileData.value.name = newProcessData.value.fileData.name;
    fileData.value.size = newProcessData.value.fileData.size;
  }
});


</script>

<style scoped>
.summary-modal {
    padding: 10px;
    height: 90vh; /* Adjust the height as necessary */
    overflow-y: auto; /* Enables vertical scrolling */
   

}

h2 {
    color: #666666;
    border-bottom: 2px solid #eee;
    font-weight: 500;
    padding-bottom: 4px;
    margin-top: 15px;
    text-align: left;
    font-size: 20px;
}

.info-row, .user-row, .file-row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

label {
    color: #7a7a7a;
    font-weight: bold;
    margin-right: 8px;
    flex-shrink: 0; /* Prevents the label from shrinking */
    text-align: left;
    padding-left: 30px;
}

span {
    padding: 4px 8px;
    border-radius: 4px;
}

.user-row, .file-row {
    padding-left: 140px; /* Aligns users and files under values */
}
</style>
