import { createStore } from 'vuex';
import { mutationMethods, getterMethods } from './IAddProcessStore.js';
import dbg from '@/debug.js';
//import dbg from '../debug.js';


export default createStore({


  // =============================================================
  // JSON BUILDER DATA
  // =============================================================
  state: {


    openModal: false,
    openModalMode: "addProcess", //or editProcess

    // Add Process modal layers
    
    newProcess: {
        title: "",
        date: "",
        isApprovalIdChecked: false,
        approvalId: "",
        note: "",
    },

    addDocument: {},

    addUsers: {},
  },


  // =============================================================
  // SETTER METHODS
  // =============================================================


  mutations: {

    [mutationMethods.OPEN_MODAL](state, mode) {
    dbg('OPEN_MODAL');
    if(mode) {
      state.openModalMode = mode;
    }
    state.openModal = true;

    },
    [mutationMethods.CLOSE_MODAL](state) {
    dbg('CLOSE_MODAL');
    state.openModal = false;
    },

    [mutationMethods.STORE_NEW_PROCESS](state, payload) {
      console.log('STORE_NEW_PROCESS', payload);

      // Parse and format the date to yyyy-MM-dd
      if (payload.date) {
        const date = new Date(payload.date);
        const formattedDate = date.toISOString().split('T')[0]; // yyyy-MM-dd
        payload.date = formattedDate;
      }

      state.newProcess = payload;
    },
     
    [mutationMethods.STORE_ADD_DOCUMENT](state, payload) {
        state.addDocument = payload;
    },
    [mutationMethods.STORE_ADD_USERS](state, payload) {
        state.addUsers = payload;
    },
    [mutationMethods.RESET_MODAL_DATA](state) {
        dbg('RESET_MODAL_DATA');
        state.newProcess = {};
        state.addDocument = {};
        state.addUsers = {};
        dbg('RESET_MODAL_DATA_END', state.newProcess, state.addDocument, state.addUsers);

    },
  },


  // =============================================================
  // GETTER METHODS
  // =============================================================
  getters: {
    [getterMethods.GET_NEW_PROCESS]: (state) => state.newProcess,
    [getterMethods.GET_ADD_DOCUMENT]: (state) => state.addDocument,
    [getterMethods.GET_ADD_USERS]: (state) => state.addUsers,
  },
});
