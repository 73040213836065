<template>
    <div class="signature-pad-container">

      <canvas ref="signatureCanvas"></canvas>
      <div class="buttons">
        <button class="btn" @click="clearSignature">{{$t('imageSignature.clear')}}</button>
        <button class="btn" @click="saveSignature">{{$t('imageSignature.save')}}</button>
      </div>
    </div>
  </template>
  
  <script>
  import SignaturePad from 'signature_pad';
  import { useI18n } from 'vue-i18n';
  
  export default {
    name: 'SignaturePad',
    setup(){
      const { t } = useI18n();
    },
    data() {
      return {
        signaturePad: null,
      };
    },
    mounted() {
      const canvas = this.$refs.signatureCanvas;
      this.signaturePad = new SignaturePad(canvas, {
        minWidth: 1,
        maxWidth: 3,
        penColor: 'rgb(0, 0, 0)',
      });
  
      this.resizeCanvas();
      window.addEventListener('resize', this.resizeCanvas);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.resizeCanvas);
      if (this.signaturePad) {
        this.signaturePad.off();
      }
    },
    methods: {
      resizeCanvas() {
        const canvas = this.$refs.signatureCanvas;
        const ratio = Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext('2d').scale(ratio, ratio);
        this.signaturePad.clear();
      },
      clearSignature() {
        this.signaturePad.clear();
      },
      saveSignature() {
        if (!this.signaturePad.isEmpty()) {
          const signatureImage = this.signaturePad.toDataURL('image/png');
          this.$emit('save-signature', signatureImage);
        } else {
          alert('Please provide a signature first.');
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .signature-pad-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }
  canvas {
    border-radius: 5px;
    border: 1px solid #000;
    max-width: 800px;
    max-height: 400px;
  }
  .buttons {
    margin-top: 10px;
  }
  button {
    margin-right: 10px;
    padding: 5px 10px;
    font-size: 14px;
  }
  .btn {
	padding: 10px 15px;
	color: #fff;
	background-color: #2156a4;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-weight: bold;
	transition: background-color 0.3s ease;
}

.btn:hover {
	background-color: #0056b3;
}
  </style>
  