<template>
    <div class="box">
        <!-- upload files box -->
        <div class="upload-box" @click="selectFile">
            <input
                type="file"
                ref="fileInput"
                @change="handleFileUpload"
                accept="application/pdf"
                style="display: none"
                multiple
            />
            <img src="../../../public/icon_upload_modal.svg" alt="">
            <div class="inline-box">
                <h2 class="blue">{{ t("addProcess.clickToUpload") }}</h2>
                <h2> {{ t("addProcess.orDrag") }}</h2>
            </div>
            <h3>{{ t("addProcess.fileType") }}</h3>
        </div>

        <div class="line">
           <span>{{ t("addProcess.files") }}</span> 
        </div>

        <!-- files list  -->
        <div class="list-box">
            <div class="file-box" v-for="(file, index) in fileData.name" :key="index">
              <img src="../../../public/file_modal.svg" alt="">
              <div class="block-box">
                <h2>{{file}}</h2>
                <h3>{{fileData.size[index] }} bytes</h3>
              </div>              
              <img src="../../../public/delete.png" alt="" class="delete-img" @click="deleteFile(index)">
            </div>
          
            <div class="text-empty" v-if="fileData.name.length === 0">
              {{ t("addProcess.noFiles") }}   
            </div>
        </div>
    </div>
</template>

<script setup>
import dbg from "@/debug";
import { ref, watch, defineExpose, onMounted, computed } from "vue";
import { useStoreAddProcess } from "@/store/storeHooks";
import { useI18n } from 'vue-i18n';

// === Ref variables =============================================================
//
// ===============================================================================
const store = useStoreAddProcess();

const files = ref([]);
const fileData = ref({ name: [], size: [] });

const fileInput = ref(null);
const disableSelectFile = ref(false);

const newProcessData = computed(() => store.state.addDocument);
const { t } = useI18n();

// === Functions =================================================================
//
// ===============================================================================

// load values from store if exist
onMounted(() => {
  dbg("AddDocumentModal mounted", newProcessData.value);
  if (newProcessData.value.files && newProcessData.value.fileData) {
    files.value = newProcessData.value.files;
    fileData.value.name = newProcessData.value.fileData.name;
    fileData.value.size = newProcessData.value.fileData.size;
  }
});

// when I press next or prev in AddProcess.vue
function addDocumentPayload() {
  const payload = {
    files: files.value,
    fileData: fileData.value
  };
  return payload;
}


function selectFile (event) {
  event.stopPropagation();
  dbg("select file", event);
  if (fileInput.value) {
    fileInput.value.click();
  }
  dbg(fileInput.value);
}

function handleFileUpload(event) {
    const newFiles = event.target.files;

    for (let i = 0; i < newFiles.length; i++) {
        files.value.push(newFiles[i]);
        fileData.value.name.push(newFiles[i].name);
        fileData.value.size.push(newFiles[i].name);
    }
    dbg("file: ", fileData.value);
    disableSelectFile.value = false;

    dbg("handleFileUpload event", event);
    event.target.value = '';
}


function deleteFile (index) {
    dbg(index)

    //delete fileData
    fileData.value.name.splice(index, 1);
    fileData.value.size.splice(index, 1);

    // Delete file from files ref
    files.value.splice(index, 1);
}


// === Defines ===================================================================
//
// ===============================================================================

defineExpose({
    addDocumentPayload
})

// === Watch Effect ==============================================================
//
// ===============================================================================

watch(fileData, (newValue, oldValue) => {
  dbg('fileData changed:', oldValue, newValue);
  dbg('files value:', files.value);
}, { deep: true });


</script>

<style scoped>
.box {
  width: 100%;
  height: 100vh; 
  display: flex;
  flex-direction: column;
  overflow: hidden; 
}

/* upload section */
.upload-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  width: 99%;
  align-self: center;
  height: 20vh;
  min-height: 160px;
  margin-top: 3rem;
  background: #F9FBFF;
  border: 2px #DEE2E6;
  border-style: dashed;
  border-radius: 8px;
}

.inline-box {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
}

.block-box {
  display: flex;
  flex-direction: column;
}

.upload-box img {
  margin-bottom: 4px;
}

.upload-box h2 {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  
}

.blue {
  color: #0D6EFD;
}

.upload-box h3 {
  font-size: 14px;
  font-weight: 400;
  color: #555a63;
  margin: 0;
}

/* line with text */
.line {
  width: 100%;
  height: 1px;
  background: #DEE2E6;
  margin-block: 2rem;
}

.line span {
  background: white;
  padding: 0 1rem;
  color: #667085;
  font-size: 14px;
  position: relative;
  bottom: 0.8rem;
}

/* files list section */
.list-box {
    width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Add gap between rows */
  margin-bottom: 1rem;
}

.file-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
  border: 1px solid #DEE2E6;
  border-radius: 9px;
}

.file-box h2 {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.file-box h3 {
  font-size: 12px;
  font-weight: 400;
  text-align: start;
  color: #AFB7BF;
  margin: 0;
}

.file-box .delete-img {
  margin-left: auto;
}

.text-empty {
 margin: auto;
}


</style>