<template>
  <div id="app">
    <NavigationBar />
    <div class="app-body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import NavigationBar from '../src/components/NavigationBar.vue';

export default {
  name: "App",
  components: {
    NavigationBar,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body, html {
  height: 100%;
  margin: 0;
  font-family: "Poppins", sans-serif;
  background: #f4f4f4; /* Nastavenie pozadia pre celú obrazovku */
}


#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh; /* Zabezpečí, aby element #app mal minimálnu výšku celého viewportu */
  display: flex;
  flex-direction: column;
}

.app-body{
  padding-top: 3rem;
}

/* =================== */

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}
</style>
