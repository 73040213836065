<template>
  <nav class="navbar" v-if="!isSignedRoute">
    <img class="logo" src="../../public/minexLogo.png" alt="" v-on:click="prelinkToMinex()">
    <select v-model="selectedUser" @change="simulateLogin" v-if="local == 'true'">
      <option value="" disabled>Select a User</option>
      <option v-for="user in testUserProfiles" :key="user.user" :value="user.user">
        {{ user.user }}
      </option>
    </select>
    <div class="tabs" v-if="isSharedDocumentsOnly">
      <div class="tab" :class="{ 'active': activeTab.abbr === 'AP' }" @click="selectTab({ abbr: 'AP', fullName: 'approvalProcess' })">{{ $t("navigationBar.approvalProcess") }}</div>
      <div class="tab" :class="{ 'active': activeTab.abbr === 'DS' }" @click="selectTab({ abbr: 'DS', fullName: 'digitalSignature' })">{{ $t("navigationBar.electronicSignature") }}</div>
      <div class="tab" :class="{ 'active': activeTab.abbr === 'QDS' }" @click="selectTab({ abbr: 'QDS', fullName: 'qualifiedDigitalSignature' })">{{ $t("navigationBar.qualifiedSignature") }}</div>
    </div>
    <div class="nav-items">
      <span v-if="isSharedDocumentsRoute" class="nav-item-shared-documents" @click="toVerifyDocument">{{$t("verify.verification")}}</span>
      <span v-if="!isSharedDocumentsRoute" class="nav-item-shared-documents" @click="toSharedDocuments">{{$t("navbar.sharedDocuments")}}</span>
      <img class="user-icon" src="../../public/userIcon.png" alt="">
      <span class="nav-text"> {{ $store.state.currentUser }} </span>
      <div class="nav-button"  v-on:click="logoutUser()">Odhlásiť sa</div>
      <div class="dropdown" @click="toggleDropdown">
        {{ this.$i18n.locale }}
        <div class="dropdown-content" v-show="isDropdownOpen">
          <a href="#" v-for="(lang, i) in langs" :key="`Lang${i}`" @click="updateLanguage(lang)">
            {{ lang }}
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>


<script>
import dbg from '@/debug';
import router from '@/routers/router';
import EnvProvider from 'jvjr-docker-env';
import { mutationMethods } from "../store/Istore.js";
import { useCookies } from "vue3-cookies";
import { auth_api } from '../api.js';

const { cookies } = useCookies();

export default {
  data() {
    // const savedTab = localStorage.getItem('activeTab');
    // const defaultTab = { abbr: 'DS', fullName: 'digitalSignature' };
    const local = EnvProvider.value('LOCAL');
    return {
      langs: ["sk", "en"],
      isDropdownOpen: false,
      local,
      testUserProfiles: [
        { user: "michal.pales" },
        { user: "matus.brandajsky" },
        { user: "david.pitner" },
        { user: "adam.hedera" },
        { user: "testuser" },
        { user: "martin.nemethy" },
        { user: "zakaznik.test" },
        { user: "richard.leckesi" },
      ],
      selectedUser: null,
    }
  },
  computed: {
    activeTab() {
      return this.$store.state.activeTab;
    },
    isSharedDocumentsRoute() {
      return (this.$route.path === '/shared-documents') || (this.$route.path === '/single-sign');
    },
    isSharedDocumentsOnly() {
      return this.$route.path === '/shared-documents';
    },
    isSignedRoute() {
      return this.$route.path === '/signed';
    },
  },
  methods: {
    simulateLogin() {
      const selectedUserName = this.testUserProfiles.find(user => user.user === this.selectedUser).user;
      if (!selectedUserName) {
        console.error('Selected user not found');
        return;
      }
      cookies.set('userName', selectedUserName, { path: '/', domain: EnvProvider.value('HOST_DOMAIN') });
      console.log(`User simulated as logged in: ${selectedUserName}`);
      window.location.reload();
    },
    toSharedDocuments(){
      router.push({name: "SharedDocuments"});
    },
    toVerifyDocument(){
      router.push({name: "VerifyDocument"});
    },
    selectTab(tab) {
      this.$store.commit("storeActiveTab", tab);
    },
    updateLanguage(lang) {
      dbg("lang: ", lang)
      this.$i18n.locale = lang;
      dbg(this.$i18n.locale);
      localStorage.setItem("lang", this.$i18n.locale);
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    prelinkToMinex() {
      window.location.href = EnvProvider.value('API_AUTH')
    },
    logoutUser(){
      console.log('Logging out user');
      auth_api.get('/logout', { withCredentials: true }
                ).then((result) => {
                    console.log(result.data);
                    console.log('Response data: '+ result.data);
                    if (result.status == 200){
                        cookies.remove('userName',null,EnvProvider.value('HOST_DOMAIN'));
                        cookies.remove('csrfToken');
                        this.$store.commit(mutationMethods.SET_CURRENT_USER, '');
                        this.$router.push({ name: 'AddDocument'})
                    } else{
                        console.error('Error logging out')
                    }
                  }).catch((e) => {
                    console.log(e);
                  });
    },
  },
  mounted() {
    if (localStorage.getItem("lang")) {
      this.$i18n.locale = localStorage.getItem("lang");
    } else {
      localStorage.setItem("lang", this.$i18n.locale);
    }
  }
}
</script>


<style scoped>
.navbar {
    display: flex;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 47px;
    padding-block: 0.5rem;
    background-color: #2156a4;
    color: white;
    z-index: 1;
}

.logo {
    width: 170px;
    margin-left: 7%;
    cursor: pointer;
}

@media (max-width: 1100px) {
  .logo {
    margin-left: 1%;
  }
}

.user-icon {
  width: 30px;
}

.nav-items {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: 2%;
}

.nav-text, .nav-button, .dropdown, .nav-item-shared-documents {
  cursor: pointer;
}

.nav-button {
  border: none;
  color: white;
  font-size: 16px;
  background: #4e78b6;
  padding: 0.6rem;
  border-radius: 15px;
  white-space: nowrap;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 62px;
    right: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    color: black;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

/* Tabs styles */
.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: 15px;
  height: 100%;
  flex-grow: 1;
  overflow: hidden; 
  margin-top: 1rem;
}

.tab {
  padding: 12px 20px;
  border-bottom: none;
  background-color: #2156a4;
  cursor: pointer;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: fit-content;
  flex-shrink: 1; 
  flex-grow: 1; 
  flex-basis: 0; 
  transition: background-color 0.2s;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

}

.tab:hover {
  background-color: #4e78b63a;
}

.tab.active {
  background-color: #F4F4F4;
  color: black;
}

</style>
