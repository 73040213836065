<template>
    <div class="modal-backdrop">
      <div class="modal">
        <button class="close-btn" @click="emitClose">x</button>
  
        <!-- sort by title -->
        <div class="block-input">
          <div class="inline-box">
            <label class="label toggle-label">{{ $t('filterModal.title') }}</label> 
          </div>
          <div class="toggle-switch">
            <div class="toggle-option-left" :class="{ 'active': sortingOptions.titleAZ === true }" @click="updateSortingOption('titleAZ', true)">{{ $t('filterModal.asc') }}</div>
            <div class="toggle-option-right" :class="{ 'active': sortingOptions.titleAZ === false }" @click="updateSortingOption('titleAZ', false)">{{ $t('filterModal.desc') }}</div>
          </div>
        </div>
  
        <!-- sort by author -->
        <div class="block-input">
          <div class="inline-box">
            <label class="label toggle-label">{{ $t('filterModal.author') }}</label> 
          </div>
          <div class="toggle-switch">
            <div class="toggle-option-left" :class="{ 'active': sortingOptions.byAuthor === true }" @click="updateSortingOption('byAuthor', true)">{{ $t('filterModal.asc') }}</div>
            <div class="toggle-option-right" :class="{ 'active': sortingOptions.byAuthor === false }" @click="updateSortingOption('byAuthor', false)">{{ $t('filterModal.desc') }}</div>
          </div>
        </div>
  
        <!-- sort by date created -->
        <div class="block-input">
          <div class="inline-box">
            <label class="label toggle-label">{{ $t('filterModal.creationDate') }}</label> 
          </div>
          <div class="toggle-switch">
            <div class="toggle-option-left" :class="{ 'active': sortingOptions.byCreationDate === true }" @click="updateSortingOption('byCreationDate', true)">{{ $t('filterModal.asc') }}</div>
            <div class="toggle-option-right" :class="{ 'active': sortingOptions.byCreationDate === false }" @click="updateSortingOption('byCreationDate', false)">{{ $t('filterModal.desc') }}</div>
          </div>
        </div>
  
        <!-- sort by date deadline -->
        <div class="block-input">
          <div class="inline-box">
            <label class="label toggle-label">{{ $t('filterModal.dueDate') }}</label> 
          </div>
          <div class="toggle-switch">
            <div class="toggle-option-left" :class="{ 'active': sortingOptions.byDeadline === true }" @click="updateSortingOption('byDeadline', true)">{{ $t('filterModal.asc') }}</div>
            <div class="toggle-option-right" :class="{ 'active': sortingOptions.byDeadline === false }" @click="updateSortingOption('byDeadline', false)">{{ $t('filterModal.desc') }}</div>
          </div>
        </div>
  
        <button @click="resetFilters" class="reset-btn">{{ $t('filterModal.filterReset') }}</button>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, watch, defineProps, defineEmits } from 'vue';
  
  const props = defineProps({
    mode: String,
    initialSortingOptions: Object
  });
  
  const emit = defineEmits(['update:sorting', 'close']);
  
  // component local state
  const sortingOptions = ref({ ...props.initialSortingOptions });
  
  // watch for changes in the initialSortingOptions prop
  watch(() => props.initialSortingOptions, (newVal) => {
    sortingOptions.value = { ...newVal };
  }, { deep: true });
  
// emit the updated sorting options to the parent component
const updateSortingOption = (option, value) => {
  // reset all sorting options
  for (let key in sortingOptions.value) {
    if (key !== option) {
      sortingOptions.value[key] = null;
    }
  }
  
  // set the selected sorting option
  sortingOptions.value[option] = value;

  // when the sorting options are updated, it also emits the updated state so the parent can update
  emit('update:sorting', { mode: props.mode, sortingOptions: sortingOptions.value });
};
  
  const emitClose = () => {
    emit('close');
};

const resetFilters = () => {
for (let key in sortingOptions.value) {
sortingOptions.value[key] = null;
}
// emit also when the filters are reset
emit('update:sorting', { mode: props.mode, sortingOptions: sortingOptions.value });
};
</script>

  
  <style scoped>
  .modal-backdrop {
    position: relative;
    top: 8REM;
    left: 7rem;
    right: 21rem;
    z-index: 100;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 300px;
  position: fixed; 
  z-index: 1000; 
  text-align: left;
}

label {
    font-size: 12px;
    margin-bottom: 3px;
    color: #a1a1a1;
    font-weight: 400;
}


  .close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 1.2rem;
  }
  
  .filter-option {
    margin-bottom: 20px;
  }
  
  .filter-option label {
    margin-right: 10px;
  }

  .toggle-switch {
  display: flex;
  margin-right: 1rem;
  gap: 0.5px;
}

.toggle-option-left,
.toggle-option-right
{
  flex: 1;
  text-align: center;
  width: 80px;
  outline: 1px solid #dadada;
  font-size: 12px;
  padding-block: 2px;
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s;
  user-select: none; 
}

.toggle-option-left
{
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: 0;
}

.toggle-option-right
{
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 0;
}


.toggle-option-left.active {
  color: #2156A4;
  outline: 1px solid #2156A4;
  background-color: #2155a41a;
  z-index: 100;
}


.toggle-option-right.active {
  color: #2156A4;
  outline: 1px solid #2156A4;
  background-color: #2155a41a;
  z-index: 10;
}

.reset-btn {
  background-color: white;
  color: #2156A4;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

  </style>
  