<template>
  <div class="container">
    <h1 v-if=" activeTab.abbr === 'AP'">Úspešne schválené</h1>
    <h1 v-if=" activeTab.abbr === 'DS'">Úspešne podpísané</h1>
    <h1 v-if=" activeTab.abbr === 'QDS'">Úspešne podpísané</h1>
    <button @click="goBack">Go Back</button>
  </div>
</template>

<script setup>
// Importujte potrebné funkcie z Vue (ak je to potrebné)
import { useRoute, useRouter} from 'vue-router';
import { ref } from "vue";
import { onMounted, computed } from "vue";
import { useStore } from "vuex";
import { getterMethods } from "../store/Istore.js";

import { qSignGetDoc, QsignDocument } from "../services";
import dbg from '@/debug';
const router = useRouter();
const route = useRoute();
const id = ref("");
const lang = ref("");
const restriction = ref("");
const store = useStore();

const goBack = () => {
  // Sem môžete pridať logiku na návrat na predchádzajúcu stránku alebo na špecifickú cestu
  router.push({ name: "SharedDocuments" });
};


const activeTab = computed(() => store.getters['getActiveTab']);

const loadTestFuncion = async () => {

  //http://localhost:8081/signed?id=5&lang=en
 
  dbg("on load test", route.query);
  const data = store.getters[getterMethods.GET_DOCUMENT_DETAIL];
  dbg(" document id, ", data.process_id)
  dbg(" document id, ", data.note)

  try {
    id.value = route.query.id;
    lang.value = route.query.lang; 
    restriction.value = route.query.restriction;
  } catch (error) {
    dbg(error);
  }

  if (id.value == undefined){
    return;
  }

  //tu zavolam request na doc-signer, [ /get-signed-document?signid=signid ]
  const response_file = await qSignGetDoc(id.value);
  dbg("response file", typeof(response_file))

  var signed_at = new Date();
  signed_at.setHours(signed_at.getHours() + 1); // Přidání jedné hodiny pro UTC+1
  signed_at = signed_at.toISOString().slice(0, 19).replace('T', ' ');
    
    //  QsignDocument(id dokumentu, note, signed_date, file  )
  dbg("qsign body data", data.process_id, data.note, signed_at, response_file);
  
  const response = await QsignDocument(data.process_id, data.note, signed_at, response_file, restriction.value);
  dbg(response)
};

onMounted(loadTestFuncion);
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
}

button:hover {
  background-color: #0056b3;
}
</style>
