// eventBus.js
import { reactive, readonly } from 'vue';

const state = reactive({
  listeners: {}
});

function on(event, callback) {
  if (!state.listeners[event]) {
    state.listeners[event] = new Set();
  }
  state.listeners[event].add(callback);
}

function off(event, callback) {
  state.listeners[event]?.delete(callback);
}

function emit(event, ...args) {
  state.listeners[event]?.forEach(callback => callback(...args));
}

export default readonly({ on, off, emit });
