<template>
    <div class="comments">
      <div v-for="comment in data" :key="comment.id" class="comment">
        <div class="header">
          <span class="name">{{ comment.user }}</span>
          <div class="inline-box">
            <span class="date">{{ comment.date }}</span>
            <img v-if="comment.is_owner" src="../../public/delete.png" class="delete-comment" alt="" @click="deleteComment(comment.id)">
          </div>
        </div>
        <div class="content">
          {{ comment.content }}
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import dbg from '@/debug'
import { deleteMessage } from '@/services';
import { defineProps, defineEmits } from 'vue'
  

  //define emit reloadComments
  const emit = defineEmits(['reloadComments']);

  const props = defineProps({
    data: {
      type: Array,
      required: true
    }
  })

  dbg(props.data);
  const deleteComment = async (commentId) => {
    dbg('Deleting comment');
    await deleteMessage(commentId);
    emit('reloadComments');
  }
  
  </script>
  
  
  <style scoped>
  .comments {
    font-family: 'Arial', sans-serif;
    max-height: 80%;
    overflow-y: scroll;
  }
  
  .comment {
    margin-top: 1rem;
    border-bottom: 1px solid #ECECEC;
    padding-block: 16px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  
  .name {
    font-weight: bold;
    font-size: 14px;
    color: #2156A4;
  }
  
  .date {
    margin: 0;
    color: #ababab;
    font-size: 12px;
    font-weight: 400;
  }
  
  .content {
    font-size: 15px;
  }

  .delete-comment {
    width: 14px;
  }

  .inline-box {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }


  </style>
  