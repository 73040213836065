
// =============================================================
// ELEMENTS AND COMPONENTS
// =============================================================
<template>
  <div class="add-document">
    <div class="header">
      <h1>{{ $t("addDocument.signDocument") }}</h1>
      <div class="header-buttons">
        <button class="btn add-user" @click="openAddUserModal">
          {{$t("addDocument.addUser")}}
        </button>
        <button class="btn sign" @click="createProcess">{{$t("addDocument.sign")}}</button>
      </div>
    </div>
      <div class="content" @click="!disableSelectFile && selectFile">
      <div class="btn-box" v-if="files.length == 0">  
        <div 
          class="drag-drop"
          :class="{ 'highlight': isDragOver }"
          ref="dropArea"
          @dragleave="handleDragLeave" @dragover.prevent="handleDrag" @drop.prevent="handleDrop"
        >
        <input
          type="file"
          ref="fileInput"
          @change="handleFileUpload"
          accept="application/pdf"
          style="display: none"
          multiple
        />
        <img v-if="isDragOver" src="../../public/importFile.png" @click="selectFile" alt="">
        <img v-else src="../../public/importFileClosed.png" @click="selectFile" alt="">
        <div class="drag-drop-box">
          {{$t("addDocument.dragAndDrop")}}
        </div>
      </div>
        </div>
      <div class="file-info" v-else>
        <h2>{{$t("addDocument.document")}}</h2>
        <div v-for="(file, index) in files" :key="index" class="inline-fileinfo">
          <span>{{ file.name }}</span>
          <img src="../../public/close.png" @click="deleteFile(index)" alt="" />
        </div>
        <div class="inline-fileinfo">
          <p>{{ $t("addDocument.from") }}:</p>
          <p>{{ $store.state.currentUser }}</p>
        </div>
        <div class="inline-fileinfo bar">
          <img src="../../public/sign.svg" alt="" />
          <div class="progress-bar-box">
           <ProgressBar :mode="0" :possibleCount="0" :allCount="users.length" :title='$t("addDocument.youSignDoc")'  :subtitle='$t("addDocument.awaitingSignature")'/>
          </div>
        </div>
      </div>
      <div class="note-box" v-if="files.length > 0">
        <div class="note">
          <div class="note-header">
            <label class="label">{{$t("addDocument.title")}} <span class="required">*</span></label>                  
            <div class="inline-input">
              <input type="text" id="inputDate" v-model="title" />
              <div class="block-input">
                <div class="inline-box">
                <label class="label toggle-label">{{ $t("addDocument.certificate") }}</label> 
                <ToolTip text="Certifikat tooltip text">
                <img src="../../public/info.svg" class="info-icon">
                </ToolTip>
              </div>
              <div class="toggle-switch">
                <div class="toggle-option-left" :class="{ 'active': !isToggleCertificate }" @click="toggleViewCertificate(false)">QSC</div>
                <div class="toggle-option-right" :class="{ 'active': isToggleCertificate }" @click="toggleViewCertificate(true)">MSC</div>
              </div>
            </div>
          
            </div>
            <label class="label date-label">{{$t("addDocument.date")}} <span class="required">*</span> </label>
            <div class="inline-input">
            <input type="date" id="inputDate" v-model="inputDate" />
            <div class="block-input">
              <div class="inline-box">
                <label class="label toggle-label">{{$t("addDocument.order") }}</label> 
                <ToolTip text="Order tooltip text">
                <img src="../../public/info.svg" class="info-icon">
                </ToolTip>
              </div>
            <div class="toggle-switch">
                <div class="toggle-option-left" :class="{ 'active': !isToggleOrder }" @click="toggleViewOrder(false)">1 3 2</div>
                <div class="toggle-option-right" :class="{ 'active': isToggleOrder }" @click="toggleViewOrder(true)">1 2 3</div>
              </div>
              </div>         
              </div>
              <input type="text" id="inputApprovalId" v-model="approvalId" />
            <label class="label note-label">{{$t("addDocument.note")}}</label>
            <textarea v-model="note" placeholder="Poznámky..."></textarea>
          </div>
          
        </div>
      </div>
    </div>
  <table class="documents-table" >
    <thead v-if="users.length">
      <tr>
        <th v-if="order"></th>
        <th></th>
        <th>Meno</th>
        <th>Email</th>
        <th></th>
      </tr>
    </thead>
    <!-- priority order -->
    <tbody>
      <tr v-for="(user) in users" :key="user.id">
        <td v-if="order">
          <div class="order-controls">
            <span class="arrow up" @click="moveUserUp(user.userId)">▲</span>
            <input
              type="text"
              :value="user.order.toString()"
              @keyup.enter="handleOrderInputChange($event, user.userId)"
              @blur="handleOrderInputChange($event, user.userId)"
              style="width: 30px; text-align: center"
              class="order-input"
              required
            />
            <span class="arrow down" @click="moveUserDown(user.userId)">▼</span>
        </div>
        </td>
        <td><img src="../../public/profile.svg" alt="" /></td>
        <td>{{ user.name }}</td>
        <td>{{ user.email }}</td>
        <td>
          <img src="../../public/delete.png" alt="" @click="signerDetails(user.userId)" >
        </td>
      </tr>
    </tbody>
  </table>
  </div>
  <transition>
    <InfoPopUp v-if="(store.state.msg.length > 0)" />
  </transition>

  <ModalWindow
    :show="showAddUserModal"
    :mode="0"
    @close="closeAddUserModal"
  />

</template>


// =============================================================
// METHODS AND INTERACTIONS
// =============================================================
<script setup>

import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { loadUsers, createSigningProcess, createApprovalProcess } from "../services.js";
import { mutationMethods, getterMethods } from "../store/Istore.js";
import { useRouter } from "vue-router";
import { useI18n } from 'vue-i18n';
import ToolTip from "../components/PopUpToolTip.vue";
import ProgressBar from '../components/ProgressBar.vue';
import ModalWindow from "../components/ModalWindow.vue";
import dbg from "../debug.js";
import InfoPopUp from '../components/InfoPopUp.vue';


// =============================================================
// REFS
// =============================================================
const store = useStore();
const fileInput = ref(null);
const files = ref([]);
const fileName = ref([]);
const disableSelectFile = ref(false);
const note = ref("");
const inputDate = ref("");
const title = ref("");
const approvalId = ref("");
const showAddUserModal = ref(false);
const isDragOver = ref(false);
const dropArea = ref(null);
const router = useRouter();
const order = ref(false);
const isToggleCertificate = ref(false);
const isToggleOrder = ref(false);
const { t } = useI18n();


// =============================================================
// COMPUTED and WATCH
// =============================================================
 
const handleOrderInputChange = (event, userId) => {

  const value = event.target.value.replace(/\D/g, '');
  event.target.value = value;

  if (value === '') return;

  const orderNumber = parseInt(value, 10);
  const limitedOrder = Math.max(1, Math.min(orderNumber, users.value.length)); 
  event.target.value = limitedOrder;
  store.commit('updateUserOrder', { userId, order: limitedOrder });
};




const users = computed(() => store.getters['sortedUsers']);

const uploadedFile = computed(() => store.getters.getUploadedFile);
watch(uploadedFile, (newVal) => {
  dbg(newVal);
});

const uploadedFileName = computed(() => {
  dbg("uploadedFileName", uploadedFileName);
  return store.state.uploadedFile ? store.state.uploadedFile.name : "";
});


// =============================================================
// METHODS
// =============================================================

const moveUserUp = (userId) => {
  console.log("moveUserUp", userId);
  store.commit(mutationMethods.MOVE_USER_UP, userId);
};

const moveUserDown = (userId) => {
  console.log("moveUserDown", userId);
  store.commit(mutationMethods.MOVE_USER_DOWN, userId);
};


const selectFile = (event) => {
  event.stopPropagation();
  dbg("select file", event);
  if (fileInput.value) {
    fileInput.value.click();
  }
};


const toggleViewOrder = (toggleStatus) => {
  isToggleOrder.value = toggleStatus;
  order.value = toggleStatus;
  dbg("toggle order", toggleStatus, order.value);
};


const toggleViewCertificate = (toggleStatus) => {
  isToggleCertificate.value = toggleStatus;
  store.commit(mutationMethods.ADD_CERTIFICATE, isToggleCertificate.value);
  dbg("toggle certificate", toggleStatus);
};


const handleFileUpload = async (event) => {
  dbg("Button Add Document clicked");

  files.value = event.target.files;
  for (let index = 0; index < files.value.length; index++) {
    fileName.value.push(files.value[index].name);
  }
  dbg("file: ", fileName.value);
  disableSelectFile.value = false;
};


const handleDrag = () => {
  isDragOver.value = true;
};


const handleDragLeave = (event) => {
  dbg("handleDragLeave", event);
  if (dropArea.value && !dropArea.value.contains(event.relatedTarget)) {
    isDragOver.value = false;
  }
};


const handleDrop = async (event) => {  
  files.value = event.dataTransfer.files;
  for (let index = 0; index < files.value.length; index++) {
    if (!files.value[index].name.endsWith('.pdf')) {
      files.value = [];
      return;    
    }
  }
  fileName.value = []
  for (let index = 0; index < files.value.length; index++) {
    fileName.value.push(files.value[index].name);
  }
  dbg("file: ", fileName.value);
};


const deleteFile = (index) => {
  dbg("Button Remove clicked");
  console.log(files.value);
  isDragOver.value = false;

  const filesArray = Array.from(files.value);
  filesArray.splice(index, 1);
  fileName.value.splice(index, 1);

  const newTransfer = new DataTransfer();

  filesArray.forEach(file => {
      newTransfer.items.add(file);
  });

  const newFileList = newTransfer.files;

  files.value = newFileList;

  console.log(files.value);
  console.log("KTS",fileInput);
  disableSelectFile.value = true;
};


const openAddUserModal = async () => {
  dbg("opened add user modal");
  try {
    let response = await loadUsers();

    store.commit(mutationMethods.STORE_USERS, response);
  } catch (error) {
    console.log(error);
  }
  showAddUserModal.value = true;
  dbg("showAddUserModal", showAddUserModal.value);
};

const closeAddUserModal = async () => {
  dbg("closed add user modal");
  showAddUserModal.value = false;
  dbg("showAddUserModal", showAddUserModal.value);
};

const popUp = (type, text) => {
  store.commit(mutationMethods.PUSH_MSG, { type: type, text: text });
};


const checkAll = () => {

  if(files.value == [])
  {
    popUp(2, t("popUp.file"));
    return false;
  }
  else if(users.value.length === 0) 
  {
    popUp(2, t("popUp.users"));
    return false;
  }
  else if (!title.value || !inputDate.value) {
    popUp(2, t("popUp.required"));    
    return false;
  }
  return true;    
};


const createProcess = async () => {

  const check = checkAll();
 
  if(!check) {
    dbg("Nepridal si ziadnych pouzivatelov")
    return
  }

  var activeTab = store.getters['getActiveTab'];
  var response;
  dbg("activeTab", activeTab);

  store.commit(mutationMethods.ADD_FILE, files.value);
  // minex or qsc certificate
  var certificate;
  if(activeTab.abbr == 'DS') {
    // minex certificate
    certificate = true
  }
  else if(activeTab.abbr == 'QDS') {
    certificate = false
  }
  dbg("certificate", certificate);

  dbg("create process");
  var internal_users = store.getters[getterMethods.GET_INTERNAL_USER_IDS];
  var external_users = store.getters[getterMethods.GET_EXTERNAL_USER_IDS];
  var formData = new FormData();
  
  for (let i = 0; i < files.value.length; i++) {
    formData.append("files", files.value[i]);
  }

  for (let i = 0; i < internal_users.length; i++) {
    formData.append("internal_users_id", internal_users[i].userId);
    formData.append("internal_users_order", internal_users[i].order);
  }

  for (let i = 0; i < external_users.length; i++) {
    formData.append("external_users_id", external_users[i].userId);
    formData.append("external_users_order", external_users[i].order);
  }

  // when we create approval process
  if(activeTab.abbr == 'AP') {

  dbg("APPROVAL PROCESS");
  formData.append("internal_signatures", internal_users);
  formData.append("description", note.value);
  formData.append("title", title.value);
  formData.append("due_date", inputDate.value);

  dbg("body", JSON.stringify(formData, null, 2));

  response = await createApprovalProcess(formData);
  dbg("create-approval-process", response);
  }

  // when we create signing process
  else {



  dbg("SIGNING PROCESS");
  dbg("users", JSON.stringify(users, null, 2));
  formData.append("signatures", JSON.stringify(users));
  formData.append("description", note.value);
  formData.append("order", order.value === true ? 1 : 0);
  formData.append("title", title.value);
  formData.append("due_date", inputDate.value);
  formData.append("certificate", certificate);
  formData.append("approval_id", approvalId.value);
  dbg("body", JSON.stringify(formData, null, 2));

  response = await createSigningProcess(formData);
  dbg("create-process", response);
  }


  router.push({ name: "SharedDocuments" });
};

const signerDetails = (userId) => {
  dbg("Akcia pre používateľa s ID", userId);
  store.commit(mutationMethods.DELETE_USER, userId);
};
</script>


// =============================================================
// STYLES
// =============================================================
<style scoped>
* {
  font-family: "Poppins", sans-serif;
}
h1 {
  font-size: 26px;
  font-weight: 500;
  color: #141e27;
}

h2 {
  color: #16212bc9;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0.2em;
}

.add-document {
  display: flex;

  flex-direction: column;
  width: 900px;
  margin: 0 auto;
  padding: 2em;
  border-radius: 15px;
}

thead {
  background: transparent;
}

tbody {
  background: white;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}
.header-buttons {
  display: flex;
  gap: 1rem;
}

.header h1 {
  white-space: nowrap;
}

.btn {
  padding: 0.75em 1em;
  border: none;
  border-radius: 8px;
  background: #f1f1f1;
  cursor: pointer;
  transition: background 0.3s;
}

.btn.add-doc {
  background: #2156a4;
  color: white;
  font-size: 15px;
}

.btn.sign {
  background: #2156a4;
  color: white;
  font-size: 15px;
  max-width: 250px;
  white-space: nowrap;
}

td img {
  padding-top: 5px;
}

.btn.add-user {
  background: white;
  border: 1px solid #e3e3e3;
  color: #1a2873;
  font-size: 15px;
}

.btn-box {
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  min-height: 266px;
  background: white;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}

.drag-drop-box {
  margin-top: 1rem;
}

.file-info {
  width: 44%;
  min-height: 266px;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  text-align: justify;
  background: white;
  border-radius: 8px;
  padding-inline: 1rem;
}

.inline-fileinfo {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.inline-fileinfo span {
  color: #757575;
  text-decoration: underline;
  font-weight: 500;
  font-size: 14px;
  padding-right: 0.5rem;
  overflow: hidden;
}


.inline-fileinfo p {
  color: #252525;
  font-weight: 500;
  font-size: 14px;
}

.inline-fileinfo p:first-child {
  color: #ababab;
  padding-right: 0.5rem;
}

.note-box {
  width: 56%;
  min-height: 200px;
  background: white;
  border-radius: 8px;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
}

.note {
  flex: 1;
}

.note-header {
  padding-top: 1rem;
  padding-left: 1rem;
  text-align: start;
  display: flex;
  height: 250px;
  flex-direction: column;
}

.note-label, .date-label
{
  margin-top: 1rem;
}

.note label {
  font-size: 12px;
  margin-bottom: 3px;
  color: #a1a1a1;
  font-weight: 400;
}

.note input {
  width: 50%;
  border: none;
  background: #f4f4f4;
  padding: 8px 8px;
  border-radius: 4px;
}

.note textarea {
  
  width: 90%;
  padding: 10px 10px;
  height: 50px;
  border: none;
  border-radius: 4px;
  resize: none;
  background: #f4f4f4;
}

.note textarea:focus-visible,
input:focus-visible {
  outline: 1px solid #efefef !important;
}

.documents-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.documents-table th,
.documents-table td {
  padding: 8px;
  text-align: left;
}

.documents-table th {
  background-color: #f4f4f4;
  color: #414141;
  font-weight: 500;
}

.documents-table tr {
  height: 75px;
  font-size: 16px;
  color: #616161;
}

.documents-table td:first-child {
  padding-left: 1rem;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.documents-table td:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.documents-table .signed {
  color: #dc3545;
}

.documents-table .not-signed {
  color: #dc3545;
}

.btn.action {
  background: transparent;
  color: #333;
}

.progress-bar-box 
{
  width: 100%;
}

.bar 
{
  margin-top: 2rem;
  gap: 1rem;
}

.inline-input 
{
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.checkbox-box {
  display: flex;
  flex-direction: row;
  margin-right: 1rem;
  align-items: center;
  gap: 0.5rem;
  height: 0;
}

.checkbox-input {
  margin: 0 !important;
}

.drag-drop
{
  height: 150px;
  width: 86%;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #BCBCBC;

}

.drag-drop.highlight 
{
  border: 1px dashed #2156a499;
  background: rgba(33, 86, 164, 0.02);
}
.required {
  color: red;
}


.checkbox-p {
  width: 100px;
}

.toggle-switch {
  display: flex;
  margin-right: 1rem;
  gap: 0.5px;
}

.toggle-option-left,
.toggle-option-right
{
  flex: 1;
  text-align: center;
  width: 80px;
  outline: 1px solid #dadada;
  font-size: 12px;
  padding-block: 2px;
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s;
  user-select: none; /* Zabránenie označenia textu pri dvojitom kliknutí */
}

.toggle-option-left
{
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: 0;
}

.toggle-option-right
{
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 0;
}


.toggle-option-left.active {
  color: #2156A4;
  outline: 1px solid #2156A4;
  background-color: #2155a41a;
  z-index: 100;
}


.toggle-option-right.active {
  color: #2156A4;
  outline: 1px solid #2156A4;
  background-color: #2155a41a;
  z-index: 10;
}


.block-input
{
  display: flex;
  flex-direction: column;
  margin-top: -24px;
}

.toggle-label {
  margin-bottom: 0 !important;
}

.info-icon {
  width: 12px;
}

.inline-box {
  display: flex;
  margin-bottom: 12px !important;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.order-controls {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.order-input {
  width: 20px;
  text-align: center;
  border-radius: 4px;
  border-width: 1px;
}


</style>
