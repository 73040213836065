<template>
  <div v-if="show" class="modal-container">
    <div class="modal-content">
      <button class="close-btn" @click="closeModal">x</button>
      <h1 v-if="mode == 0 || mode == 1">{{ $t("adduserModal.addUser") }}</h1>
      <h1 v-if="mode == 3">{{ $t("adduserModal.removeFlow") }}</h1>
      <h1 v-if="mode == 3 && joined_process_id != null" class="warning">{{ $t("modalWindow.warning") }}</h1>
      <h1 v-if="mode == 4">{{ $t("adduserModal.insertMail")}}</h1>

      <div class="modal-body" v-if="mode != 2">
        <div class="searchBox">
          <vue3-simple-typeahead
            v-if="mode != 3 && mode != 4"
            id="searchBoxId"
            :key="typeaheadKey"
            :placeholder='$t("adduserModal.search")'
            :items="typeaheadItems"
            :minInputLength="1"
            autocomplete="on"
            @selectItem="selectItem"
            @keydown.enter="selectItem"
            v-model="simpleTypeaheadValue"
          >
          </vue3-simple-typeahead>
        </div>

        <div v-if="mode == 4">
          <span class="error-text" v-if="isValidEmail == false"> {{$t("adduserModal.invalidMail")}} </span>
          <span class="success-text" v-if="success == true"> {{$t("adduserModal.success")}} </span>
          <div class="search-box">
            <input id="emailInput" ref="emailInput" class="search-input" type="email" :placeholder='$t("adduserModal.sendMail")' v-model="searchText">
          </div>
          <h1 v-if="sendToMails.length > 0">{{$t("adduserModal.sendTo")}}</h1>
          <div class="badges-container">
            <span class="badge" v-for="(mail, index) in sendToMails" :key="index">
                {{ mail }}
            </span>
          </div>
        </div>
        


        <div class="badges-container" v-if="mode == 0">
          <span class="badge" v-for="user in users" :key="user.id"
            >{{ user.email }} <b @click="deleteUser(user.userId)">  x</b></span>
        </div>
        <div class="badges-container" v-if="mode == 1">
          <span class="badge" v-for="user in editUsers.users" :key="user.user_id">
            {{user.user_email}} <b v-if="editUsers.users.length > 1" @click="deleteUserEdit(user.user_id)">  x</b></span>
        
        </div>
        <button v-if="mode == 0" class="confirm-btn" @click="addUser">
          {{ $t("adduserModal.confirm") }}
        </button>
        <button v-if="mode == 1" class="confirm-btn" @click="addUserEdit">
          {{ $t("adduserModal.confirm") }}
        </button>
        
        <div class="inline-box">
          <button v-if="mode == 3 || mode == 4" class="cancel-btn" @click="closeModal">
          {{ $t("adduserModal.cancel") }}
        </button>

        <button v-if="mode == 3" class="confirm-btn" @click="removeFlow">
          {{ $t("adduserModal.confirm") }}
        </button>
        <button v-if="mode == 4" class="confirm-btn" @click="sendMail">
          {{ $t("adduserModal.send") }}
        </button>
        </div>
      </div>
      <p v-if="mode == 2">{{ note }}</p>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { mutationMethods, getterMethods } from "../store/Istore.js";
import dbg from "@/debug.js";
import { deleteFlow, sendMailWithFile, loadSendTo } from '@/services.js';

const typeaheadKey = ref(0);

export default {
  props: {
    note: String,
    show: Boolean,
    mode: Number,
    doc_id: String,
    process_type: String,
    send_to: Array,
    joined_process_id: String,
  },
  setup(props, { emit }) {
    const searchText = ref();
    const isValidEmail = ref(true);
    const success = ref(false);
    const emailInput = ref(null);
    const selectedUser = ref(null);
    const currentUser = ref(null);
    const simpleTypeaheadValue = ref(""); // Hodnota pro sledování změn v simple-typeahead
    const store = useStore();

    const users = computed(() => store.state.users);
    const editUsers = computed(() => store.getters[getterMethods.GET_DOCUMENT_DETAIL]);

    const storeUsers = computed(() => store.getters.getStoreUsers);
    const typeaheadItems = computed(() =>
      storeUsers.value.map((user) => user.email)
    );
    const searchPlaceholder = ref("Search");

    const sendToMails = ref(props.send_to);

    watch(() => props.send_to, (newValue) => {
      sendToMails.value = newValue;
    });

    const removeFlow = async () => {

      let response = await deleteFlow(props.process_type, props.doc_id);
      
      if (response) 
      {
        emit("remove");
      }

    };

    const sendMail = async () => {
      success.value = false;
      const user_mail = emailInput.value.value.trim();
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(user_mail)) {
        isValidEmail.value = false;
        return; 
      }

      isValidEmail.value = true;
      var data_ = {
        process_id: props.doc_id,
        user_mail
      }
      var response = await sendMailWithFile(data_);

      if(response != 200){
        console.log("ERROR WHILE SENDING MAIL")
      }
      else{
        success.value = true;
        sendToMails.value = await loadSendTo(props.doc_id)
      }

    }

    const selectItem = (item) => {
      selectedUser.value = item; // Assuming this is your existing logic
      currentUser.value = item;
      
      if(props.mode === 0 ) {
      const user = storeUsers.value.find((u) => u.email === selectedUser.value);
      
       if (user) {
        dbg("ide to aj sem")
        const isUserExists = users.value.some(u => u.email === user.email);
        
        if (isUserExists) {
          return;
        }
        store.commit(mutationMethods.ADD_USER, {
          name: user.email,
          email: user.email,
          status: "not signed",
          userId: user.id,
        }); 
      }
      }
      else {
      const userEdit = storeUsers.value.find((u) => u.email === currentUser.value);
        if (userEdit) {
          // const highestSignatureOrder = Array.isArray(editUsers.value) ? editUsers.value.reduce((max, user) => Math.max(max, user.signature_order || 0), 0) : 0;
          store.commit(mutationMethods.EDIT_ADD_USER, {
            note: null,
            signed: "NotSigned",
            signature_order: 3,
            user_email: userEdit.email,
            user_id: userEdit.id,
          });
        }
      }

      simpleTypeaheadValue.value = ""; 
      typeaheadKey.value++; 
    };

    const deleteUser = (userid) => {
      store.commit(mutationMethods.DELETE_USER, userid);
    };

    const deleteUserEdit = (userid) => {
    
      // when there is only one user in the list, we can't delete it
      if(editUsers.value.users.length > 1)
      {
        store.commit(mutationMethods.EDIT_REMOVE_USER, userid);
      }
    };

    const closeModal = () => {
      isValidEmail.value = true;
      success.value = false;
      searchText.value = '';
      emit("close");
    };

   

    const addUser = () => {
      dbg(simpleTypeaheadValue.value);

      closeModal();
    };
    const addUserEdit = () => {
      dbg("editUser.value", users.value.email);

     
      closeModal();
    };

    watch(simpleTypeaheadValue, (newValue) => {
      const user = storeUsers.value.find((u) => u.email === newValue);
      if (user) {
        selectedUser.value = user.id;
      } else {
        console.error("Uživatel s emailem", newValue, "nebyl nalezen.");
        selectedUser.value = null;
      }
    });

    return {
      selectedUser,
      simpleTypeaheadValue,
      closeModal,
      addUser,
      storeUsers,
      searchPlaceholder,
      typeaheadItems,
      selectItem,
      sendMail,
      addUserEdit,
      typeaheadKey,
      users,
      deleteUser,
      currentUser,
      editUsers,
      deleteUserEdit,
      removeFlow,
      emailInput,
      isValidEmail,
      success,
      searchText,
      sendToMails
    };
  },
};
</script>



<style scoped>
.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  text-align: initial;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-container /deep/ #searchBoxId {
  width: 94%;
  height: 35px;
  font-size: 15px;
  border: none;
  outline: 1px solid #f4f4f4;
  background: #f4f4f4;
  padding: 0px 8px;
  border-radius: 4px;
  transition: 0.2s;
}

/* /deep/ is used to change external component css while in style scoped */
.modal-container /deep/ #searchBoxId:hover { 
  outline: 1px solid #ddddddd2 !important;
}

.modal-container /deep/ #searchBoxId:focus-visible {
  outline: 1px solid #d7d7d7 !important;
}

h1 {
  font-size: 13px;
  font-weight: 400;
  color: #6c6c6c;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  min-width: 300px;
  width: 420px;
  /* height: 150px; */
  position: relative;
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
}

.modal-body {
  display: flex;
  flex-direction: column;
  /* gap: 35px; */
}

.search-box {
  display: flex;
    align-items: center;
    background-color: #f1f3f4;
    border-radius: 24px;
    margin-top: 1rem;
    margin-bottom: 10px;
    padding: 2px 4px;
}

.search-input {
  flex-grow: 1;
  border: none;
  background-color: white;
  padding: 11px 12px;
  font-size: 16px !important;
  border-radius: 14px;
  outline: none;
}

.error-text {
  color: red;
}

.success-text{
  color: green;
}

.inline-box  {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
}

.cancel-btn {
  background-color: #FCE3E3;
  border: 1px solid #C34040 !important;
  color: #C34040 ;
  width: 100px;
  height: 36px;
  align-self: self-end;
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;  
  transition: ease 0.2s;
}

.cancel-btn:hover {
  color: white;
  background: #C34040;
}

.confirm-btn {
  background: #0056b3;
  border: none;
  color: white;
  width: 100px;
  height: 36px;
  align-self: self-end;
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
}

.badges-container {
  display: flex;
  cursor: pointer;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px 0;
}

.badge {
  background-color: #eee;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 11px;
}

.warning {
  color: rgb(221, 0, 0);
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 11px;
}

</style>