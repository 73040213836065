<template>
  <div class="stepper">
    <template v-for="(step, index) in modalSteps" :key="step.id">
      <div 
        class="step" 
        :class="stepClass(step.id)" 
        @click="updateCurrent(step.id)" 
        :style="isClickable(step.id)">
        <div class="circle">{{ step.id }}</div>
        <div class="label">STEP {{ step.id }}<br><p>{{ step.title }}</p></div>
      </div>
      <div 
        v-if="index < modalSteps.length - 1" 
        class="line" 
        :class="lineClass(step.id)">
      </div>
    </template>
  </div>
</template>


  
  <script setup>
  import { computed, ref, defineEmits } from 'vue';
  import { useStoreGlobal} from "@/store/storeHooks";

  // === Defines ===================================================================
//
// ===============================================================================
const emit = defineEmits(['change-step']);

// === Stores ====================================================================
//
// ===============================================================================
const store = useStoreGlobal();


  const current = computed(() => store.getters['getCurrentModalStep'].id);
  const active = computed(() => store.state.activeSteps);
  const modalSteps = computed(() => store.state.modalSteps);

  const updateCurrent = (step) => {
    console.log('updateCurrent', step);
    if (active.value.includes(step)) {
        emit('change-step', step);
    }
  };
  
  const stepClass = (step) => {
    if (step === current.value) {
      return 'current';
    } else if (active.value.includes(step)) {
      return 'active';
    } else {
      return 'inactive';
    }
  };
  
  const lineClass = (step) => {
    if (step < current.value) {
      return 'active-line';
    } else {
      return '';
    }
  };
  
  const isClickable = (step) => {
    return active.value.includes(step) ? { cursor: 'pointer' } : { cursor: 'default' };
  };
  </script>
  
  <style scoped>
  .stepper {
    display: flex;
    align-items: center;
    width: 80%;
    justify-content: space-between;
  }
  
  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #d3d3d3;
    background-color: white;
    color: #333;
    font-weight: 500;
    font-size: 12px;
  }
  
  .label {
    width: 100px;
    margin-top: 4px;
    text-align: center;
    font-size: 10px;
    color: #888;
  }
  
.label p {
    margin: 0;
    font-size: 12px;
    color: black;
    font-weight: 500;
  }

  .line {
    width: 100px;
    height: 3px;
    border-radius: 1rem;
    background-color: #d3d3d3;
  }
  
  .current .circle {
    background-color: #2156A4;
    color: white;
    border: 2px solid #2156A4;
  }
  
  .current .label {
    color: #ADB5BD;
  }
  
  .active .circle {
    border: 2px solid #2156A4;
    color: #2156A4;
  }
  
  .active .label {
    color: #ADB5BD;
  }
  
  .inactive .circle {
    border: 2px solid #d3d3d3;
    color: #d3d3d3;
  }
  
  .inactive .label {
    color: #d3d3d3;
  }
  
  .current-line {
    background-color: #70a9ff;
  }
  
  .active-line {
    background-color: #70a9ff;
  }
  </style>
    