<template>
  <div class="shared-documents">

    <div class="search-box">

      <div class="view-toggle">
        <button @click="setView('list')" :class="{ active: view === 'list' }">
          <span class="icon-list"><img src="../../public/list.svg" alt=""></span>
        </button>
        <button @click="setView('grid')" :class="{ active: view === 'grid' }">
          <span class="icon-grid"><img src="../../public/grid.svg" alt=""></span>
        </button>
      </div>

      <input class="search-input" type="text" :placeholder='$t("adduserModal.search")' v-model="searchText"
        v-on:keyup.enter="filterDocs">
      <button class="search-button">
        <img @click="filterDocs" src="../../public/searchIcon.svg" alt="" />
      </button>

      <button class="new-process-button" @click="createProcess">
        <img class="new-process-icon" src="../../public/plusIconBlue.svg" alt="" />
        {{ $t("sharedDocuments.newPorcess") }}
      </button>

      <AddProcess :show="addNewProcesModal" @close="addNewProcesModal = false" @reload="loadMyDocuments" />
    </div>

    <!-- === GRID VIEW ======================================================================================================== -->
    <!-- ====================================================================================================================== -->

    <div class="flex-container" v-if="view == 'grid'">
      <div class="column">
        <div id="createProcess" class="box-heading">
          <div class="block-box">

            <div class="inline-heading">
              <h1>{{ $t("sharedDocuments.myDocuments") }}</h1>
              <img @click="filterMyDocs" src="../../public/filterIcon.svg " alt="" />
              <FilterModal v-if="myDocsFilterModal" mode="myDocs" :initialSortingOptions="sortingOptionsForMyDocs"
                @update:sorting="handleUpdateSorting" @close="myDocsFilterModal = false" />
            </div>

            <div class="tabs">
              <button :class="{ active: activeTabMD === 'active' }" @click="changeActiveTabMD('active')">
                <div class="inline">
                  <p>{{ $t("sharedDocuments.activeProcess") }} </p>
                  <p>({{ createdDocuments.activeCount }}) </p>
                </div>
              </button>
              <button :class="{ active: activeTabMD === 'finished' }" @click="changeActiveTabMD('finished')">
                <div class="inline">
                  <p>{{ $t("sharedDocuments.finishedProcess") }} </p>
                  <p>({{ createdDocuments.finishedCount }})</p>
                </div>




              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- my documents are loaded  -->
          <span class="no-process" v-if="createdDocuments.docs.length == 0 && activeTabMD === 'active'">{{
          $t("sharedDocuments.noActiveProcesses") }}</span>
          <span class="no-process" v-if="createdDocuments.docs.length == 0 && activeTabMD === 'finished'">{{
          $t("sharedDocuments.noFinishedProcesses") }} </span>
          <div class="mydoc-item" v-for="process in createdDocuments.docs" :key="process.id"
            @click="selectMyDocument(process.process_id)" :class="{
          'selected-document':
            selectedDocumentId === process.process_id && mode === 'myDocs',
        }">
            <div class="shared-doc">
              <div class="shared-title">
                <div class="title-inline">
                  <!-- temporary solution for approval process -->
                  <img v-if="process.certificate == undefined" src="../../public/approvalLogo.svg" alt="" />
                  <img v-if="process.certificate == 1" src="../../public/minexLogo.svg" alt="" />
                  <img v-if="process.certificate == 0" src="../../public/qsignLogo.svg" alt="" />

                  <div class="inline-box">
                    <h2 class="title">{{ process.title }}</h2>
                    <ToolTip :text="process.process_id">
                      <h4> #{{ process.process_id }}</h4>
                    </ToolTip>
                  </div>
                </div>

                <div class="date-box">
                  <p><b>{{ $t("sharedDocuments.created") }}: </b> {{ process.created_date }}</p>
                  <p><b>{{ $t("sharedDocuments.due") }}: </b> {{ process.doc_due_date }}</p>
                </div>


              </div>
              <div class="inline-box">
                <h5>{{ $t("sharedDocuments.mumberOfFiles") }} </h5>
                <span>{{ process.name.length }}</span>
              </div>
            </div>
            <div class="shared-name">
              <div class="name-box">
                <img src="../../public/sign.svg" alt="" />
                <h3>{{ process.email }}</h3>
              </div>
              <div class="signed-box">
                <p>{{ $t("sharedDocuments.signatures") }}</p>
                <div class="progress-bar-box">
                  <ProgressBar :mode="1" :status="process.status" :possibleCount="process.signed_count"
                    :allCount="process.all_count" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div id="sharedDocs" class="box-heading">

          <div class="block-box">

            <div class="inline-heading">
              <h1>{{ $t("sharedDocuments.sharedDocuments") }}</h1>
              <img @click="filterSharedDocs" src="../../public/filterIcon.svg " alt="" />
              <FilterModal v-if="sharedDocsFilterModal" mode="sharedDocs"
                :initialSortingOptions="sortingOptionsForSharedDocs" @update:sorting="handleUpdateSorting"
                @close="sharedDocsFilterModal = false" />
            </div>

            <div class="tabs">
              <button :class="{ active: activeTabSD === 'active' }" @click="changeActiveTabSD('active')">
                <div class="inline">
                  <p>{{ $t("sharedDocuments.activeProcess") }} </p>
                  <p>({{ mySharedDocuments.activeCount }}) </p>
                </div>
              </button>
              <button :class="{ active: activeTabSD === 'finished' }" @click="changeActiveTabSD('finished')">

                <div class="inline">
                  <p>{{ $t("sharedDocuments.finishedProcess") }} </p>
                  <p>({{ mySharedDocuments.finishedCount }}) </p>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- shared documents are loaded  -->
          <span class="no-process" v-if="mySharedDocuments.docs.length == 0 && activeTabSD === 'active'">{{
          $t("sharedDocuments.noActiveProcesses") }} </span>
          <span class="no-process" v-if="mySharedDocuments.docs.length == 0 && activeTabSD === 'finished'">{{
          $t("sharedDocuments.noFinishedProcesses") }}</span>
          <div class="shareddoc-item" v-for="process in mySharedDocuments.docs" :key="process.id"
            @click="selectSharedDocument(process.process_id)" :class="{
          'selected-document':
            selectedDocumentId === process.process_id && mode === 'sharedDocs',
        }">
            <div class="shared-doc">
              <div class="shared-title">
                <div class="title-inline">
                  <img v-if="process.certificate == undefined" src="../../public/approvalLogo.svg" alt="" />
                  <img v-if="process.certificate == 1" src="../../public/minexLogo.svg" alt="" />
                  <img v-if="process.certificate == 0" src="../../public/qsignLogo.svg" alt="" />

                  <div class="inline-box">
                    <h2 class="title">{{ process.title }}</h2>
                    <ToolTip :text="process.process_id">
                      <h4> #{{ process.process_id }}</h4>
                    </ToolTip>
                  </div>
                </div>
                <div class="date-box">
                  <p><b>{{ $t("sharedDocuments.created") }}: </b> {{ process.created_date }}</p>
                  <p><b>{{ $t("sharedDocuments.due") }}: </b> {{ process.doc_due_date }}</p>
                </div>

              </div>
              <div class="inline-box">
                <h5>{{ $t("sharedDocuments.mumberOfFiles") }} </h5>
                <span>{{ process.name.length }}</span>
              </div>
            </div>
            <div class="shared-name">
              <div class="name-box">
                <img v-if="process.status === 'NotSigned'" src="../../public/profileNotSigned.svg" alt="">
                <img v-if="process.status === 'Canceled'" src="../../public/profileCancelled.svg" alt="">
                <img v-if="process.status === 'Signed'" src="../../public/profileSigned.svg" alt="">
                <img v-if="process.status === 'Declined'" src="../../public/profileDeclined.svg" alt="">
                <img v-if="process.status === 'SignedRestriction'" src="../../public/profileSignedRestriction.svg"
                  alt="">
                <h3>{{ process.email }}</h3>
              </div>
              <div class="signed-box">
                <p>{{ $t("sharedDocuments.Status") }}</p>

                <InfoBadge :status="process.status" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <transition name="last-col" @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <div class="column last-col" v-if="showRightSideBar">
          <!-- Obsah pre Right Sidebar -->
        </div>
      </transition>

    </div>


    <!-- === LIST VIEW ======================================================================================================== -->
    <!-- ====================================================================================================================== -->
    <div v-if="view == 'list'" class="badge-box">
    <div v-for="badge in badges" :key="badge.id" class="badge-container">
      <div class="badge"
        :class="{ 'active': badge.isActive || (badge.isDropdown && badge.selectedOption !== 'default') }"
        @click="badge.isDropdown ? toggleDropdown(badge) : toggleActive(badge)">
        <span>{{ badge.icon }}</span>
        <span>{{ badge.isDropdown ? badge.text + ': ' + getOptionText(badge.selectedOption, badge.options) : badge.text }}</span>
      </div>
      <div v-if="badge.isOpen && badge.isDropdown" class="dropdown-menu">
        <div v-for="option in badge.options" :key="option.id" class="dropdown-item"
          @click="selectDropdownOption(badge, option)">
          {{ option.text }} <span v-if="badge.selectedOption === option.id" class="checkmark"><img src="../../public/checkmark.svg" alt=""></span>
        </div>
      </div>
    </div>
  </div>

    <ProcessTable :items="combinedDocs" @detail="myOrShared" v-if="view == 'list'" ref="processTableComponent" @tableOrder="tableOrderFilter"/>

    <RightSideBar :showButtons="showConfirm" :show="showRightSideBar" :mode="mode" :signed="signedUser"
      @close="closeRightSideBar" @updateValue="updateValue" @reload="loadMyDocuments" ref="rightSideBarComponent"
      @showJoinedSignature="selectMyDocument" />

  </div>
</template>

<script setup>
// import ToolTip from "./PopUpToolTip.vue";
import { ref, reactive, computed, onUnmounted, watch, onBeforeMount, watchEffect } from "vue";
import { useStore } from "vuex";
import { getterMethods, mutationMethods } from "../store/Istore.js";
import { myDocuments, sharedDocuments } from "../services.js";
import RightSideBar from "../components/RightSideBar.vue";
import ProgressBar from "../components/ProgressBar.vue";
import ProcessTable from "@/components/ProcessTable.vue";
import dbg from "../debug.js";
import { fetchDocumentDetails, fetchSharedDocuments } from "../services.js";
import AddProcess from "../views/AddProcess.vue";
import eventBus from "@/eventBus.js";
import InfoBadge from "../components/InfoBadge.vue";
import FilterModal from "../components/FilterModal.vue";
import ToolTip from "../components/PopUpToolTip.vue";
import { useI18n } from 'vue-i18n';
// import router from "@/routers/router";
const createdDocuments = computed(() => store.state.myDocs || []);
const mySharedDocuments = computed(() => store.state.sharedDocs || []);

// Spojenie procesov myDocs a sharedDocs
const combinedDocs = ref();
// computed(() => {
//   return [...store.state.myDocs.docs, ...store.state.sharedDocs.docs] || [];
// });
const { t } = useI18n();
const activeTab = computed(() => store.state.activeTab);
const selectedDocumentId = ref(null);
const showRightSideBar = ref(false);
const signedUser = ref();
const data = ref({});
const store = useStore();
const storeAddProcess = useStoreAddProcess();
var mode = ref();
var showConfirm = ref(false);
const activeTabMD = ref("active");
const activeTabSD = ref("active");
const filter = ref({});
const searchText = ref("");
const myDocsFilterModal = ref(false);
const sharedDocsFilterModal = ref(false);
const addNewProcesModal = ref(false);
const rightSideBarComponent = ref(null);
import { useStoreAddProcess } from "../store/storeHooks";
const tableColumnOrderType = ref({
  column: null,
  order: null
});

const tableOrderFilter = (key, order) => {
  tableColumnOrderType.value = {
    column: key,
    order: order
  };

  console.log("tableColumnOrderType", tableColumnOrderType.value);  
  filterDocs();
};


const badges = reactive([
  {
    id: 'showFinished',
    ref: 'showFinished',
    text: t("processTable.show"),
    isActive: false,
    icon: '🟢',
    isDropdown: true,
    options: [
      { id: 'default', text: t("processTable.default") },
      { id: 'active', text: t("processTable.active") },
      { id: 'finished', text: t("processTable.finished") }
    ],
    selectedOption: 'default',
    isOpen: false
  },
  {
    id: 'showCancelledOrFailed',
    ref: 'showCancelledOrFailed',
    text: t("processTable.canceledOrFailed"),
    isActive: false,
    icon: '⛔️',
    isDropdown: false
  },
  {
    id: 'showMine',
    ref: 'showMine',
    text: t("processTable.owned"),
    isActive: false,
    icon: '🔵',
    isDropdown: false
  },
  {
    id: 'showTurn',
    ref: 'showTurn',
    text: t("processTable.myProcesses"),
    isActive: false,
    icon: '🕑',
    isDropdown: true,
    options: [
      { id: 'default', text: t("processTable.default") },
      { id: 'all', text: t("processTable.all") },
      { id: 'myTurn', text: t("processTable.myTurn") },
      { id: 'notMyTurn', text: t("processTable.notMyTurn") }
    ],
    selectedOption: 'default',
    isOpen: false
  }
]);

watchEffect(() => {
  badges[0].text = t("processTable.show");
  badges[0].options[0].text = t("processTable.default");
  badges[0].options[1].text = t("processTable.active");
  badges[0].options[2].text = t("processTable.finished");

  badges[1].text = t("processTable.canceledOrFailed");

  badges[2].text = t("processTable.owned");

  badges[3].text = t("processTable.myProcesses");
  badges[3].options[0].text = t("processTable.default");
  badges[3].options[1].text = t("processTable.all");
  badges[3].options[2].text = t("processTable.myTurn");
  badges[3].options[3].text = t("processTable.notMyTurn");
});


const deactivateAllBadgesExcept = (currentBadge) => {
  badges.forEach(badge => {
    if (badge !== currentBadge) {
      badge.isActive = false;
      badge.isOpen = false;
      if (badge.isDropdown) {
        badge.selectedOption = 'default';
      }
    }
  });
};

const deactivateAllBadges = () => {
  badges.forEach(badge => {
    badge.isActive = false;
    badge.isOpen = false;
    if (badge.isDropdown) {
      badge.selectedOption = 'default';
    }
  });
};

const toggleActive = (badge) => {
  deactivateAllBadgesExcept(badge);
  badge.isActive = !badge.isActive;
  console.log(badge.text, badge.isActive);
  filterDocs();
};

const toggleDropdown = (badge) => {
  deactivateAllBadgesExcept(badge);
  badge.isOpen = !badge.isOpen;
};

const selectDropdownOption = (badge, option) => {
  badge.selectedOption = option.id;
  console.log(`${badge.text} selected: ${option.text}`);
  badge.isOpen = false;
  badge.isActive = option.id !== 'default';
  filterDocs();
};

const getOptionText = (selectedOptionId, options) => {
  const selectedOption = options.find(option => option.id === selectedOptionId);
  return selectedOption ? selectedOption.text : 'default';
};

const processTableComponent = ref();

const myOrShared = async (id, flow_col) => {
  dbg("FLOW_COL", flow_col);

  if (flow_col === "myDocs") {
    await selectMyDocument(id);
  }
  if (flow_col === "sharedDocs") {
    await selectSharedDocument(id);
  }
};

// Ref na uchovanie aktuálneho zobrazenia
const view = ref('list');

// Funkcia na nastavenie zobrazenia
const setView = (newView) => {
  view.value = newView;
  deactivateAllBadges();
  filterDocs();
  closeRightSideBar();
};

const beforeEnter = (el) => {
  el.style.flex = '0';
  el.style.minWidth = '0px';
};
// eslint-disable-next-line no-unused-vars
const enter = (el, done) => {
  el.offsetHeight; // Trigger reflow
  el.style.transition = 'flex 0.15s ease-in-out';
  el.style.flex = '1';
  el.style.minWidth = '0px'; // Nastavte min-width podľa potreby
  done();
};
// eslint-disable-next-line no-unused-vars
const leave = (el, done) => {
  el.style.flex = '0';
  el.style.minWidth = '0px';
  setTimeout(() => done(), 300); // Počkať na ukončenie prechodu
};

// Začiatočné a aktuálne nastavenia zoradenia pre oba režimy
const sortingOptionsForMyDocs = ref({
  titleAZ: null,
  byAuthor: null,
  byCreationDate: null,
  byDeadline: null
});

const sortingOptionsForSharedDocs = ref({
  titleAZ: null,
  byAuthor: null,
  byCreationDate: null,
  byDeadline: null
});

watch(activeTab, () => {
  filterDocs();

});


// handle filter modal, when user click on actions in filter icon
const handleUpdateSorting = ({ mode, sortingOptions }) => {
  console.log(`Aktualizácia zoradenia pre režim: ${mode}`, sortingOptions);

  if (mode === 'myDocs') {
    sortingOptionsForMyDocs.value = sortingOptions;
    console.log('Nové nastavenia pre My Documents:', sortingOptionsForMyDocs.value);
  } else if (mode === 'sharedDocs') {
    sortingOptionsForSharedDocs.value = sortingOptions;
    console.log('Nové nastavenia pre Shared Documents:', sortingOptionsForSharedDocs.value);
  }

  filterDocs();
};

const buildFilter = () => {
  const myDocsSorting = { ...sortingOptionsForMyDocs.value };
  const sharedDocsSorting = { ...sortingOptionsForSharedDocs.value };

  filter.value = {
    ...filter.value,
    processType: activeTab.value.fullName,
    searchText: searchText.value,
    tableFilter: tableColumnOrderType.value,
    badgeFilters: {
      showFinished: badges[0],
      showCancelledOrFailed: badges[1].isActive,
      showMine: badges[2].isActive,
      forSignature: badges[3],
    },
    myDocs: {
      ...filter.value.myDocs,
      status: activeTabMD.value,
      ...myDocsSorting,
    },
    sharedDocs: {
      ...filter.value.sharedDocs,
      status: activeTabSD.value,
      ...sharedDocsSorting,
    }
  };
}

// when I change tab, filter, or serach
const filterDocs = () => {
  buildFilter();
  dbg("filter", filter.value);
  store.getters[getterMethods.FILTER_DOCS](filter.value, view.value);
  combinedDocs.value = [...store.state.myDocs.docs, ...store.state.sharedDocs.docs];
  combinedDocs.value = store.getters[getterMethods.FILTER_COMBINED_DOCS](combinedDocs.value, filter.value.tableFilter);
}

// when I click on filter icon in my documents
const filterMyDocs = () => {
  myDocsFilterModal.value = !myDocsFilterModal.value;
  dbg(myDocsFilterModal.value);
}

// when I click on filter icon in shared documents
const filterSharedDocs = () => {
  sharedDocsFilterModal.value = !sharedDocsFilterModal.value;
  dbg(sharedDocsFilterModal.value);
}

// change myDocs tab handler
const changeActiveTabMD = (status) => {
  activeTabMD.value = status;
  filterDocs();
}
// change sharedDocs tab handler
const changeActiveTabSD = (status) => {
  activeTabSD.value = status;
  filterDocs();
}

const createProcess = () => {
  addNewProcesModal.value = true;
  storeAddProcess.commit('openModal', "addProcess");
  // router.push({ name: "AddDocument" });
};

const handleActionFromIframe = async (data) => {
  dbg(data);
  selectedDocumentId.value = null;
  showRightSideBar.value = false;
  await loadMyDocuments();
};

const updateValue = (value) => {
  showConfirm.value = value;
  showConfirm.value = !showConfirm.value;
};

const loadMyDocuments = async () => {
  dbg("refresh");
  try {
    //load my documents into vuex state myDocs
    store.commit(mutationMethods.STORE_MY_DOC, await myDocuments());
    dbg("MY PROCESS DATA", JSON.stringify(createdDocuments.value, null, 2));

    //load shared documents into vuex state sharedDocs
    store.commit(mutationMethods.STORE_SHARED_DOC, await sharedDocuments());
    dbg("SIGNATURE PROCESS DATA", JSON.stringify(mySharedDocuments.value, null, 2));

    // dbg("COMBINED DOCS DATA", JSON.stringify(combinedDocs.value, null, 2));

    filterDocs();


    dbg("FILTERED MY PROCESS DATA", JSON.stringify(createdDocuments.value, null, 2));

    dbg("FILTERED SIGNATURE PROCESS DATA", JSON.stringify(mySharedDocuments.value, null, 2));
    dbg("COMBINED DOCS DATA", JSON.stringify(combinedDocs.value, null, 2));


  } catch (error) {
    console.error("Error loading documents:", error);
  }
};

// const selectedUserNumber = computed({
//   get: () => CurrentUser.value,
//   set: async (val) => {
//     CurrentUser.value = val;
//     await loadMyDocuments();
//   },
// });

const closeRightSideBar = () => {
  showRightSideBar.value = false;
  selectedDocumentId.value = null;
  if (processTableComponent.value) {
    processTableComponent.value.refreshYourself();
  }
};

// const showTest = (id) => {
//   console.log("showTest", id);
// };

const selectMyDocument = async (id) => {
  dbg("selected my document id", id);
  selectedDocumentId.value = id;
  showConfirm.value = false;
  data.value = await fetchDocumentDetails(id);
  store.commit(mutationMethods.STORE_DOCUMENT_DETAIL, data.value);
  dbg(
    "document detail",
    JSON.stringify(store.getters[getterMethods.GET_DOCUMENT_DETAIL], null, 2)
  );
  mode.value = "myDocs";
  showRightSideBar.value = true;
  signedUser.value = null;
  rightSideBarComponent.value.refreshYourself();
};


const selectSharedDocument = async (documentId) => {
  dbg("selected shared document id", documentId);
  selectedDocumentId.value = documentId;
  data.value = await fetchSharedDocuments(documentId);
  dbg("fetchSharedDocuments response", data.value);
  store.commit(mutationMethods.STORE_DOCUMENT_DETAIL, data.value);
  mode.value = "sharedDocs";
  showConfirm.value = false;
  showRightSideBar.value = false;
  showRightSideBar.value = true;
  // signedUser.value = signed;
  rightSideBarComponent.value.refreshYourself();
};

onBeforeMount(async () => {
  await loadMyDocuments();

  eventBus.on("actionFromIframe", handleActionFromIframe);
  eventBus.on("loadMyDocuments", loadMyDocuments);
});

onUnmounted(() => {
  eventBus.off("actionFromIframe", handleActionFromIframe);
  eventBus.off("loadMyDocuments", loadMyDocuments);
});
</script>



<style scoped>
.shared-documents {
  display: flex;
  flex-direction: column;
}

.row {
  flex-wrap: wrap;
  color: black;
  display: flex;
  overflow-y: scroll;
  overflow-x: hidden;
  min-width: 440px;
  max-height: 68vh;
  padding-inline: 0.5rem;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  width: 100%;
}



.box-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 440px;
  width: 85%;
  padding-inline: 1rem;
  padding-block: 5px;
}

#add-icon {
  justify-content: space-between;
  width: 45px;
  height: 45px;
  cursor: pointer;
  background: white;
  background-color: #f4f4f4;
}

#centered-paragraph {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0;
  height: 10px;
}

.flex-container {
  display: flex;
  height: auto;
  padding-left: 30px;
  transition: all 0.3s ease-in-out;
}

.column {
  flex: 1;
  color: black;
  display: flex;
  overflow-y: scroll;
  overflow-x: hidden;
  min-width: 460px;
  padding-inline: 0.5rem;
  flex-direction: column;
  align-items: center;
}

.last-col {
  flex: 0;
  min-width: 0;
  padding-inline: 0 !important;
}

#add-icon:hover {
  box-shadow: 0 0.1rem 0.6rem rgba(64, 195, 119, 0.3);
  border-radius: 25.5px;
}

.shareddoc-item,
.mydoc-item {
  background: white;
  width: 85%;
  min-width: 440px;
  height: 115px;
  padding: 1rem;
  border-radius: 14px;
  margin-top: 2px;
  margin-bottom: 1rem;
  margin-inline: 1rem;
  box-shadow: rgba(149, 157, 165, 0.007) 0px 8px 24px;
  transition: ease 0.2s;
}

.selected-document {
  outline: 1px solid #72a7ef;
  background: #f2faff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}



.shared-doc {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  overflow-y: visible;
  text-align: start;
}

.title-inline {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.no-process {
  margin-top: 50px;
}

h1 {
  font-size: 22px;
  font-weight: 500;
  color: #141e27;
  padding-inline: 0;
}

h2 {
  flex-direction: column;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

h3 {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  color: #bcbcbc;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 60px;
}

h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  color: #bcbcbc;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.shared-name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.shared-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.shared-title p {
  margin: 0;
  color: #ababab;
  font-size: 12px;
  font-weight: 400;
}

.signed-box p {
  margin: 0;
  font-size: 10px;
  font-weight: 500;
  text-align: end;
  color: #ababab;
}

.signed-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.name-box {
  display: flex;
  margin-top: 0.5rem;
  flex-direction: row;
  column-gap: 0.5rem;
  align-items: center;
}

.progress-bar-box {
  width: 100px;
}



.tooltip {
  position: absolute;
  transform: translateX(-40%) translateY(0px);
  background-color: #ffffff;
  color: #484848;
  text-align: center;
  font-size: 13px;
  border-radius: 6px;
  opacity: 0.8;
  box-shadow: 0 2px 4px rgb(0 0 0 / 4%);
  padding: 0.5rem;
}

.search-box {
  display: flex;
  align-items: center;
  align-self: center;
  background-color: #f1f3f4;
  border-radius: 24px;
  width: 73vw;
  min-width: 58rem;
  max-width: 72.8rem;
  margin-top: 2rem;
  margin-left: 2.7rem;
  padding: 10px 24px;
}

.dropdown {
  border: none;
  background: #ececec;
  padding: 10px 8px;
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
  font-size: 15px;
  cursor: pointer;
  color: #878787;
  z-index: 1000;
}

.dropdown:focus-visible {
  outline: 1px solid rgb(216, 216, 216) !important;
}

.search-input {
  flex-grow: 1;
  border: none;
  background-color: white;
  padding: 11px 12px;
  font-size: 16px !important;
  border-radius: 14px;
  outline: none;
}

.search-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  margin-left: -3rem;
}

.icon {
  font-size: 24px;
}

.new-process-button {
  padding: 0.6rem 0.6rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #e3eafc;
  margin-left: 4rem;
  color: #2156a4;
  border-radius: 5px;
  border: 1px solid #98b1da;
}

.new-process-icon {
  padding-inline: 8px;
  width: 12px;
  padding-bottom: 0.5px;
}

.new-process-button:hover {
  box-shadow: 0 0.1rem 0.6rem #2155a431;
}

.inline-heading {
  display: inline-flex;
  align-items: center;
  gap: 2rem;
}

.tabs button {
  padding: 10px 20px;
  border: none;
  background: none;
  margin-right: 10px;
  font-size: 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  color: #6C6C6C;
  border-bottom: 2px solid #E2E2E2;
  width: 47%;
  transition: ease 0.1s;
}

.tabs p {
  margin: 0;
}

.tabs button:hover {
  background-color: #EBEDF1;

}

.tabs button.active {
  background-color: #EBEDF1;
  border-bottom: 2px solid #2156A4;
  color: #2156A4;
  font-weight: 600;
}

.block-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.date-box {
  text-align: end;
}

@media only screen and (max-width: 1550px) {
  .search-box {
    display: flex;
    align-items: center;
    background-color: #f1f3f4;
    border-radius: 24px;
    width: 60.8rem;
    margin-top: 2rem;
    margin-left: 0.5rem;
  }
}



@media only screen and (max-width: 1300px) {
  .search-box {
    display: flex;
    align-items: center;
    background-color: #f1f3f4;
    border-radius: 24px;
    width: 58.8rem;
    margin-top: 2rem;
    margin-left: 0.5rem;
  }

  .title {
    max-width: 100px;
  }
}

.inline-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.inline-box span {
  padding: 0px 7px;
  background: #DAE9F8;
  color: #2156A4;
  font-weight: 500;
  font-size: 12px;
  border-radius: 6px;
}


.title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 180px;
}

@media only screen and (max-width: 1750px) {
  .title {
    max-width: 140px !important;
  }

}


.inline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  text-align: center
}


.view-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  width: 100px;
  margin-right: 1rem;
}

.view-toggle button {
  background: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-toggle button.active {
  background-color: #2156a421;
}

.badge-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 3rem;
  margin-left: 1rem;

}

.badge-container {
  display: inline-block;
  position: relative;
  margin-right: 10px;
}

.badge {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
  /* background-color: #f0f0f0; */
  background: white;
  display: flex;
  outline: 1px solid #f2f2f2;

  font-size: 13px;
  /* align-items: center; */
  gap: 5px;
  transition: background-color 0.3s;
  ;
}

.badge:hover {
  background-color: #e3e3e3;
}

.badge.active {
  outline: 1px solid #72a7ef;
  background: #f2faff;
  font-weight: 500
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 0.5rem;
  background-color: white;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  font-size: 14px;
  z-index: 1000;
  width: 100%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.checkmark {
  color: blue;
}
</style>
