import axios from 'axios';
import EnvProvider from 'jvjr-docker-env';
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

// no cache in response from axios
axios.defaults.headers = {
	"Cache-Control": "no-cache",
	Pragma: "no-cache",
	Expires: "0",
};

if (EnvProvider.value("ENV") === "DEV") {
	// FOR DEVELOPMENT ONLY
	axios.defaults.withCredentials = true;
}

const commonRequestInterceptor = (config) => {
  console.log('Interceptor:')
  // console.log('CSRF: token in header')
  let csrf = cookies.get('csrfToken')
  let user = cookies.get('userName')
  console.log('CSRF:', csrf)
  console.log('user:', user)
  // console.log(store.state.loggedUser)
  // console.log(EnvProvider.value('ENV'))
  if(EnvProvider.value('LOCAL') === 'true' && EnvProvider.value('ENV') === 'DEV'){
    console.log('DEV CSRF: token in header')
    config.headers['x-csrf-token'] = 'dev_bypass';
    csrf = user ? 'dev_bypass'+user : 'dev_bypass';
  }

  if(user != ''){   
    config.headers['x-csrf-token'] = csrf;
  }
  console.log(config)

  return config;
}

const commonErrorInterceptor = (error) => {
  console.log(error)
  return Promise.reject(error);
}

// // Add CSRF token in header if logged in
// axios.interceptors.request.use( function (config) {
//   console.log('Interceptor:')
//   // console.log('CSRF: token in header')
//   let csrf = cookies.get('csrfToken')
//   let user = cookies.get('userName')
//   console.log('CSRF:', csrf)
//   console.log('user:', user)
//   // console.log(store.state.loggedUser)
//   // console.log(EnvProvider.value('ENV'))
//   if(user === 'testuser' && EnvProvider.value('ENV') === 'DEV'){
//     console.log('DEV CSRF: token in header')
//     config.headers['x-csrf-token'] = 'dev_bypass';
//     csrf = 'dev_bypass'
//   }

//   if(user != ''){   
//     config.headers['x-csrf-token'] = csrf;
//   }
//   console.log(config)

//   return config;
// },
// error => {
//   console.log(error)
//   return Promise.reject(error);
// }
// );

const api = axios.create({
  // baseURL: 'https://signer.sensesolutions.sk/',
  baseURL: EnvProvider.value('API_BASE_URL'),
});


export const testApi = axios.create({
  baseURL: 'http://localhost:5000/',
  // baseURL: EnvProvider.value('API_BASE_URL'),
});

testApi.interceptors.request.use(
  commonRequestInterceptor,
  commonErrorInterceptor
);


export const signer_api = axios.create({
  baseURL: EnvProvider.value('SIGNER_API_BASE_URL'),
});

export const auth_api = axios.create({
  baseURL: EnvProvider.value('API_AUTH'),
});

api.interceptors.request.use(
  commonRequestInterceptor,
  commonErrorInterceptor
);

signer_api.interceptors.request.use(
  commonRequestInterceptor,
  commonErrorInterceptor
);

auth_api.interceptors.request.use(
  commonRequestInterceptor,
  commonErrorInterceptor
);

export const base_url = EnvProvider.value('BASE_URL')
export const front_url = EnvProvider.value('FRONT_URL')
// export const front_url = 'https://signer.sensesolutions.sk'
// export const base_url = 'https://signer.sensesolutions.sk'
export default api;