<template>
  <div class="assignee-circles" @click="printAssignee">
    <div
      v-for="(assignee, index) in displayedAssignees"
      :key="assignee.user_id"
      class="circle"
      :style="{ backgroundColor: assignee.color, zIndex: displayedAssignees.length - index }"
    >
      {{ assignee.initials }}
    </div>
    <div v-if="extraCount > 0" class="circle extra" style="z-index: 0;">+{{ extraCount }}</div>
  </div>
</template>



<script setup>
import { defineProps, computed } from 'vue';

// Props definícia
const props = defineProps({
  assignee: Array
});

// Rozšírený zoznam farieb
const colors = [
  '#0197A7', '#7F77F1', '#04A9F4', '#E65100', '#536CFE',
  '#827718', '#FFA727'
];

// Funkcia na generovanie farieb z user_id
const generateColorFromId = (id) => {
  let hash = 0;
  for (let i = 0; i < id.length; i++) {
    hash = id.charCodeAt(i) + ((hash << 8) - hash);
  }
  const colorIndex = Math.abs(hash) % colors.length;
  return colors[colorIndex];
};

// Prepočet assignee so štýlmi
const assigneesWithStyles = computed(() => {
  return props.assignee.map((user) => {
    const initials = user.user_email.substring(0, 2).toUpperCase();
    const color = generateColorFromId(user.user_id);
    return { ...user, initials, color };
  });
});

// Zoznam zobrazených užívateľov (max 3)
const displayedAssignees = computed(() => {
  return assigneesWithStyles.value.slice(0, 4);
});

// Výpočet extra assignee
const extraCount = computed(() => {
  return props.assignee.length > 4 ? props.assignee.length - 4 : 0;
});

const printAssignee = () => {
  console.log("ASSIGNEE", props.assignee);
};
</script>



<style scoped>
.assignee-circles {
  display: flex;
  align-items: center;
  position: relative;
}

.circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 400;
  font-size: 10px;
  margin-left: -8px;
  border: 2px solid white;
  position: relative;
}

.extra {
  background-color: #e0e0e0;
  color: #424242;
}
</style>



