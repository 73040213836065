import { createStore } from 'vuex';
import { mutationMethods, getterMethods } from './Istore.js';
import dbg from '../debug.js';



function findJoined(docs, id) {
  // console.log("docs", docs, id)
  return docs.filter(doc => doc.process_id === id);
}

function sortBySignatureOrder(users) {
  return users.sort((a, b) => a.signature_order - b.signature_order);
}



function listOrderFilter(docs, tableFilter) {
  console.log("tableFilter listOrderFilter", tableFilter)
  if(tableFilter.column == 'nazov') {
    if(tableFilter.order == 'asc') {
      return sortByTitle(docs, true)
    }
    else if(tableFilter.order == 'desc') {
      return sortByTitle(docs, false)
    }
    else {
      return docs
    }
  }
  if(tableFilter.column == 'due') {
    if(tableFilter.order == 'asc') {
      return sortByDate(docs, 'doc_due_date', true)
    }
    else if(tableFilter.order == 'desc') {
      return sortByDate(docs, 'doc_due_date', false)
    }
    else {
      return docs
    }
  }
  if(tableFilter.column == 'created') {
    if(tableFilter.order == 'asc') {
      return sortByDate(docs, 'created_date', true)
    }
    else if(tableFilter.order == 'desc') {
      return sortByDate(docs, 'created_date', false)
    }
    else {
      return docs
    }
  }
  if(tableFilter.column == 'autor') {
    if(tableFilter.order == 'asc') {
      return sortByAuthor(docs, true)
    }
    else if(tableFilter.order == 'desc') {
      return sortByAuthor(docs, false)
    }
    else {
      return docs
    }
  }
  if(tableFilter.column == 'assignee') {
    if(tableFilter.order == 'asc') {
      return sortByAssignee(docs, true)
    }
    else if(tableFilter.order == 'desc') {
      return sortByAssignee(docs, false)
    }
    else {
      return docs
    }
  }
  if(tableFilter.column == 'slider') {
    if(tableFilter.order == 'asc') {
      return sortBySlider(docs, true)
    }
    else if(tableFilter.order == 'desc') {
      return sortBySlider(docs, false)
    }
    else {
      return docs
    }
  }
  if(tableFilter.column == 'id') {
    if(tableFilter.order == 'asc') {
      return sortById(docs, true)
    }
    else if(tableFilter.order == 'desc') {
      return sortById(docs, false)
    }
    else {
      return docs
    }
  }
  if(tableFilter.column == 'prepojenie') {
    if(tableFilter.order == 'asc') {
      return sortByJoins(docs, true)
    }
    else if(tableFilter.order == 'desc') {
      return sortByJoins(docs, false)
    }
    else {
      return docs
    }
  }
  if(tableFilter.column == 'mojStav') {
    if(tableFilter.order == 'asc') {
      return filterByStatus(docs, true)
    }
    else if(tableFilter.order == 'desc') {
      return filterByStatus(docs, false)
    }
    else {
      return docs
    }
  }
  return docs

}

// Sort documents by status
function filterByStatus(docs, ascending = true) {
  console.log("filterByStatus", ascending);

  const statusOrder = {
    Signed: ascending ? 1 : 4,
    NotSigned: ascending ? 2 : 3,
    Canceled: ascending ? 3 : 2,
    Declined: ascending ? 4 : 1
  };

  return docs.sort((a, b) => {
    const statusA = a.status;
    const statusB = b.status;

    return statusOrder[statusA] - statusOrder[statusB];
  });
}


// Sort documents by number of users (assignees)
function sortByAssignee(docs, ascending = true) {
  console.log("sortByAssignee", ascending);

  return docs.sort((a, b) => {
    const userCountA = a.users.length;
    const userCountB = b.users.length;

    if (userCountA < userCountB) return ascending ? -1 : 1;
    if (userCountA > userCountB) return ascending ? 1 : -1;
    return 0;
  });
}

// Sort documents by joined_process_id
function sortByJoins(docs, ascending = true) {
  console.log("sortByJoins", ascending);

  return docs.sort((a, b) => {
    const joinA = a.joined_process_id;
    const joinB = b.joined_process_id;

    if (joinA === null && joinB !== null) return ascending ? -1 : 1;
    if (joinA !== null && joinB === null) return ascending ? 1 : -1;
    return 0;
  });
}

function sortBySlider(docs, ascending = true) {
  console.log("sortBySlider", ascending);

  return docs.sort((a, b) => {
    const percentageA = a.signed_count / a.all_count;
    const percentageB = b.signed_count / b.all_count;
    
    if (percentageA < percentageB) return ascending ? -1 : 1;
    if (percentageA > percentageB) return ascending ? 1 : -1;
    return 0;
  });
}

// sort documents by title
function sortByTitle(docs, ascending = true) {

  console.log("sortByTitle", ascending)

  return docs.sort((a, b) => {
    const titleA = a.title.toLowerCase();
    const titleB = b.title.toLowerCase();
    if (titleA < titleB) return ascending ? -1 : 1;
    if (titleA > titleB) return ascending ? 1 : -1;
    return 0;
  });
}

// sort documents by author
function sortByAuthor(docs, ascending = true) {
  return docs.sort((a, b) => {
    const authorA = a.email.toLowerCase(); 
    const authorB = b.email.toLowerCase();
    if (authorA < authorB) return ascending ? -1 : 1;
    if (authorA > authorB) return ascending ? 1 : -1;
    return 0;
  });
}

function sortById(docs, ascending = true) {
  console.log("sortById", ascending);

  return docs.sort((a, b) => {
    const idA = a.process_id;
    const idB = b.process_id;
    if (idA < idB) return ascending ? -1 : 1;
    if (idA > idB) return ascending ? 1 : -1;
    return 0;
  });
}

// sort documents by date 
function sortByDate(docs, dateField, ascending = true) {
  return docs.sort((a, b) => {
    const dateA = new Date(a[dateField]);
    const dateB = new Date(b[dateField]);
    return ascending ? dateA - dateB : dateB - dateA;
  });
}

  //active tab is 'myDocs' or 'sharedDocs'
  //filter only NotSigned documents
  function showActive(docs) {
    return docs.filter(doc => doc.status === 'NotSigned');

  }
  //finished tab is 'myDocs' or 'sharedDocs'
  //filter all except NotSigned documents
  function showFinished(docs) {
    return docs.filter(doc => doc.status != 'NotSigned');
  }

  // function showCancelled(docs) {
  //   return docs.filter(doc => doc.status === 'Cancelled');
  // }

  //search for documents by title
  function searchDocs(docs, searchText) {
    return docs.filter(doc => doc.title.toLowerCase().includes(searchText.toLowerCase()));
  
  }

// filter combined documents by status and search text
function filterCombinedDocs(docs, tableFilter) {
  if (!(tableFilter.column == null || tableFilter.order == null)) {
    docs = listOrderFilter(docs, tableFilter);
  }

  return docs;
}

// filter documents by status and search text
function filterDocs(view, docs, processType, status, searchText, sortingOptions, badgeFilters, tableFilter, sharedDocs) {
  console.log("sortingOptions", processType);
  console.log("badgeFilters", badgeFilters);

  // Process/Certificate type filter
  if (processType !== null) {
    if (processType === 'approvalProcess') {  
      // temp solution for approval tab
      docs = docs.filter(doc => doc.certificate === undefined);       
    }
    if (processType === 'digitalSignature') {  
      docs = docs.filter(doc => doc.certificate === true);       
    }
    else if (processType === 'qualifiedDigitalSignature') {
      docs = docs.filter(doc => doc.certificate === false);
    }
  }

  // Search filter                       
  if (searchText !== '') {
    docs = searchDocs(docs, searchText);
  }

  // Remove duplicates where sharedDocs have the same id as myDocs
  if (view === 'list') {
    const sharedDocsIds = new Set(sharedDocs.map(doc => doc.process_id));
    docs = docs.filter(doc => !(doc.flow_col === 'myDocs' && sharedDocsIds.has(doc.process_id)));
  }

  // Badge filters
  if (badgeFilters.showFinished.isActive == true) {
    if (badgeFilters.showFinished.selectedOption === 'active')  {
      docs = showActive(docs);
    }
    else if (badgeFilters.showFinished.selectedOption === 'finished') {
      docs = showFinished(docs);
    }
  }

  if (badgeFilters.showCancelledOrFailed == true) {
    docs = docs.filter(doc => doc.status === 'Canceled' || doc.status === 'Failed');
  }

  if (badgeFilters.showMine) {
    docs = docs.filter(doc => doc.mine_process == true);  // Předpokladám, že 'me' je identifikátor pre vlastníka dokumentu
  }

  if (badgeFilters.forSignature.isActive == true) { 
    if (badgeFilters.forSignature.selectedOption === 'all') {
      docs = docs.filter(doc => doc.flow_col === 'sharedDocs');
    }
    else if (badgeFilters.forSignature.selectedOption === 'myTurn') {
      docs = docs.filter(doc => doc.flow_col === 'sharedDocs');
      docs = docs.filter(doc => doc.previous === 'Signed');
      docs = docs.filter(doc => doc.status === 'NotSigned');

    }
    else if (badgeFilters.forSignature.selectedOption === 'notMyTurn') {
      docs = docs.filter(doc => doc.flow_col === 'sharedDocs');
      docs = docs.filter(doc => doc.previous === 'NotSigned');
    }
    
  }

  // Status active/finished filter
  let activeDocs = showActive(docs);
  let finishedDocs = showFinished(docs);

  let filteredDocs;
  if (view === 'list') {
    // Pre list view zobrazíme všetky dokumenty
    filteredDocs = docs;
  } else {
    // Pre grid view filtrujeme podľa statusu
    filteredDocs = status === 'active' ? activeDocs : 
                   status === 'finished' ? finishedDocs : docs;
  }

  // Sorting options filter LIST (TABLE) VIEW
  if(!(tableFilter.column == null || tableFilter.order == null)) {
    filteredDocs = listOrderFilter(docs, tableFilter);
  }

  // Sorting options filter GRID VIEW
  if (sortingOptions.titleAZ !== null) {
    filteredDocs = sortByTitle(filteredDocs, sortingOptions.titleAZ);
    console.log('sorted by title');
  } else if (sortingOptions.byAuthor !== null) {
    filteredDocs = sortByAuthor(filteredDocs, sortingOptions.byAuthor);
  } else if (sortingOptions.byCreationDate !== null) {
    filteredDocs = sortByDate(filteredDocs, 'created_date', sortingOptions.byCreationDate);
  } else if (sortingOptions.byDeadline !== null) {
    filteredDocs = sortByDate(filteredDocs, 'doc_due_date', sortingOptions.byDeadline);
  }

  filteredDocs = filteredDocs.map(doc => ({ ...doc, flow_col: doc.flow_col }));
  
  return {
    docs: filteredDocs,
    activeCount: activeDocs.length,
    finishedCount: finishedDocs.length
  };
}



export default createStore({


  // =============================================================
  // JSON BUILDER DATA
  // =============================================================


  state: {
    msg: [],
    uploadedFile: null,
    uploadedFileImageSignature: null,
    users: [],
    externalUsers: [],
    loggedUsers: [],
    currentUser: '',
    myDocs: [],

    // main tabs in navbar
    activeTab: { abbr: 'DS', fullName: 'digitalSignature' },
    
    sharedDocs: [],

    /*
    temporary storage for documents, when Docs will be reverted 
    from filter or search to original state
    */
    myDocsTemp: [],
    sharedDocsTemp: [],

    documentId: null,
    documentDetail: [],
    certificate: false,

    // Steps for AddProcess modal
    modalSteps: [

      { id: 1, name: 'NewProcessModal', img: 'newProcessApprovalLogo.svg', title: 'New Process', description: 'Start a new process', approvalJoin: false },
      { id: 2, name: 'AddDocumentModal', img: 'addFilesLogo.svg', title: 'Add Document', description: 'Add documents to your process' },
      { id: 3, name: 'AddUsersModal', img: 'addUsersLogo.svg', title: 'Add Users', description: 'Invite users to participate' },
      { id: 4, name: 'SummaryModal', img: 'addUsersLogo.svg', title: 'Summary', description: 'Summary of process' }
    ],
    activeSteps: [1],
    currentModalStep: { id: 1, name: 'NewProcessModal', img: 'newProcessApprovalLogo.svg', title: 'New Process', description: 'Start a new process', approvalJoin: false },
    // currentModalStep: { id: 3, name: 'AddUsersModal', img: 'addUsersLogo.svg', title: 'Add Users', description: 'Invite users to participate' }
    toggleAdditionalStep: false

  },


  // =============================================================
  // SETTER METHODS
  // =============================================================


  mutations: {


    [mutationMethods.RESET_TOGGLE_ADDITIONAL_STEP](state) {
      state.toggleAdditionalStep = false;
    },
    
    [mutationMethods.SET_TOGGLE_ADDITIONAL_STEP](state, toggle) {
      state.toggleAdditionalStep = toggle;

      if (toggle) {
        // Pridaj podmienený krok
        state.modalSteps = [
          { id: 1, name: 'NewProcessModal', img: 'newProcessApprovalLogo.svg', title: 'New Process', description: 'Start a new process', approvalJoin: false },
          { id: 2, name: 'AddDocumentModal', img: 'addFilesLogo.svg', title: 'Add Document', description: 'Add documents to your process' },
          { id: 3, name: 'AddUsersModal', img: 'addUsersLogo.svg', title: 'Add Users', description: 'Invite users to participate' },
          { id: 4, name: 'AddExternalUsersModal', img: 'addFilesLogo.svg', title: 'Add Externs', description: 'Add external users to your process' },
          { id: 5, name: 'SummaryModal', img: 'addUsersLogo.svg', title: 'Summary', description: 'Summary of process' }
        ];
      } else if (toggle == false) {
        // Odstráň podmienený krok
        state.modalSteps = [
          { id: 1, name: 'NewProcessModal', img: 'newProcessApprovalLogo.svg', title: 'New Process', description: 'Start a new process', approvalJoin: false },
          { id: 2, name: 'AddDocumentModal', img: 'addFilesLogo.svg', title: 'Add Document', description: 'Add documents to your process' },
          { id: 3, name: 'AddUsersModal', img: 'addUsersLogo.svg', title: 'Add Users', description: 'Invite users to participate' },
          { id: 4, name: 'SummaryModal', img: 'addUsersLogo.svg', title: 'Summary', description: 'Summary of process' }
        ];

        //when I switch off external toggle, to reset external users
        state.externalUsers = [];
      }
      else{
        state.modalSteps = [
          { id: 1, name: 'NewProcessModal', img: 'newProcessApprovalLogo.svg', title: 'New Process', description: 'Start a new process', approvalJoin: false },
          { id: 2, name: 'AddDocumentModal', img: 'addFilesLogo.svg', title: 'Add Document', description: 'Add documents to your process' },
          { id: 3, name: 'AddUsersModal', img: 'addUsersLogo.svg', title: 'Add Users', description: 'Invite users to participate' },
          { id: 4, name: 'SummaryModal', img: 'addUsersLogo.svg', title: 'Summary', description: 'Summary of process' }
        ];
      }
      console.log("modalSteps", state.modalSteps);
    },


    [mutationMethods.NEXT_MODAL_STEP](state) {
      const currentIndex = state.modalSteps.findIndex(step => step.id === state.currentModalStep.id);
      const nextIndex = currentIndex + 1;
      if (nextIndex < state.modalSteps.length) {
        state.currentModalStep = state.modalSteps[nextIndex];
      }
    },

    [mutationMethods.SPECIFIC_MODAL_STEP](state, stepId) {
      const newStep = state.modalSteps.find(step => step.id === stepId);
      if (newStep) {
        state.currentModalStep = newStep;
      }
    },

    [mutationMethods.PREV_MODAL_STEP](state) {
      const currentIndex = state.modalSteps.findIndex(step => step.id === state.currentModalStep.id);
      const prevIndex = currentIndex - 1;
      if (prevIndex >= 0) {
        state.currentModalStep = state.modalSteps[prevIndex];
      }
    },
    [mutationMethods.ADD_ACTIVE_STEP](state, stepId) {
      state.activeSteps.push(stepId);
    },

    [mutationMethods.SET_MODAL_STEP](state, stepId) {
      const newStep = state.modalSteps.find(step => step.id === stepId);
      if (newStep) {
        state.currentModalStep = newStep;
      }
    },

    
    [mutationMethods.PUSH_MSG] (state, data){
      state.msg.push({
        type: data.type, 
        text: data.text
      })
      setTimeout(() => {
        state.msg.shift()
      }, 7000)
    },

    [mutationMethods.POP_MSG] (state) {
      state.msg.shift()
    },


   
  
     
    [mutationMethods.MOVE_USER_DOWN](state, userId) {
      const index = state.users.findIndex(user => user.userId === userId);
      const maxOrder = state.users.length;
      console.log("maxOrder: ", maxOrder)
      console.log("index: ", index)
      if (index < state.users.length  && state.users[index].order < maxOrder) {
        state.users[index].order++;
       
      }
    },
    

    [mutationMethods.UPDATE_USER_ORDER](state, { userId, order }) {
      const userIndex = state.users.findIndex(user => user.userId === userId);
      if (userIndex !== -1) {
        const limitedOrder = Math.max(1, Math.min(order, state.users.length)); 
        state.users[userIndex].order = limitedOrder;
      }
    },

    [mutationMethods.MOVE_USER_UP](state, userId) {
      
      const index = state.users.findIndex(user => user.userId === userId);
      if ( state.users[index].order > 1) {
        state.users[index].order--;
        
      }
    },



    [mutationMethods.ADD_FILE](state, file) {
      state.uploadedFile = file;
    },
    [mutationMethods.ADD_IMAGE_SIGNATURE_FILE](state, file) {
      state.uploadedFileImageSignature = file;
    },

    [mutationMethods.EDIT_ADD_USER](state, newUser) {
      const userExists = state.documentDetail.users.some(user => user.user_id === newUser.user_id);
      if (!userExists) {
        // Fix here: Refer to state.documentDetail.users instead of undefined users
        const highestSignatureOrder = state.documentDetail.users.reduce((max, user) => Math.max(max, user.signature_order || 0), 0);
        newUser.signature_order = highestSignatureOrder + 1;
        newUser.editable = "true";
        state.documentDetail.users.push(newUser);
      }
      localStorage.setItem('documentDetail', JSON.stringify(state.documentDetail));
    },

    [mutationMethods.EDIT_REMOVE_USER](state, userId) {
      state.documentDetail.users = state.documentDetail.users.filter(user => user.user_id !== userId);
      localStorage.setItem('documentDetail', JSON.stringify(state.documentDetail));
    },


    [mutationMethods.EDIT_MOVE_USER_UP](state, userId) {
      const currentUserIndex = state.documentDetail.users.findIndex(user => user.user_id === userId);
      const currentUser = state.documentDetail.users[currentUserIndex];
      
      if (currentUser.signature_order > 1) {
        currentUser.signature_order = currentUser.signature_order - 1;
      }

      state.documentDetail.users = sortBySignatureOrder([...state.documentDetail.users]);
      localStorage.setItem('documentDetail', JSON.stringify(state.documentDetail));
    },
  
    [mutationMethods.EDIT_MOVE_USER_DOWN](state, userId) {
  
      const currentUserIndex = state.documentDetail.users.findIndex(user => user.user_id === userId);
      const currentUser = state.documentDetail.users[currentUserIndex];
      
      if ( currentUser.signature_order < state.documentDetail.users.length) {
        currentUser.signature_order = currentUser.signature_order + 1;
        
      } 

      state.documentDetail.users = sortBySignatureOrder([...state.documentDetail.users]);
      localStorage.setItem('documentDetail', JSON.stringify(state.documentDetail));
    },
  
  
    [mutationMethods.EDIT_UPDATE_USER_ORDER](state, { userId, order }) {
      console.log("id",userId ,"order",order)
      const userIndex = state.documentDetail.users.findIndex(user => user.user_id === userId);

      if (state.documentDetail.editable_order > order){
        state.documentDetail.users[userIndex].signature_order =state.documentDetail.editable_order+1; 
      }
      else if (userIndex !== -1) {
        state.documentDetail.users[userIndex].signature_order = order;
      }

      state.documentDetail.users = sortBySignatureOrder([...state.documentDetail.users]);
      localStorage.setItem('documentDetail', JSON.stringify(state.documentDetail));
    },
  


    [mutationMethods.REMOVE_FILE](state) {
      state.uploadedFile = null;
      state.documentId = null;
    },

    [mutationMethods.REMOVE_FILE_IMAGE_SIGNATURE](state) {
      state.uploadedFileImageSignature = null;
    },

    [mutationMethods.DELETE_USER](state, userId) {
  
      const userIndex = state.users.findIndex(user => user.userId === userId);
      if (userIndex !== -1) {
        const deletedUserOrder = state.users[userIndex].order;
        state.users.splice(userIndex, 1);
        const sharedOrderExists = state.users.some(user => user.order === deletedUserOrder);
        if (!sharedOrderExists) {
          state.users.forEach(user => {
            if (user.order > deletedUserOrder) {
              user.order -= 1;
            }
          });
        }
      }
    },
    [mutationMethods.DELETE_EXTERNAL_USER](state, userId) {
  
      const userIndex = state.externalUsers.findIndex(user => user.userId === userId);
      if (userIndex !== -1) {
        const deletedUserOrder = state.externalUsers[userIndex].order;
        state.externalUsers.splice(userIndex, 1);
        const sharedOrderExists = state.externalUsers.some(user => user.order === deletedUserOrder);
        if (!sharedOrderExists) {
          state.externalUsers.forEach(user => {
            if (user.order > deletedUserOrder) {
              user.order -= 1;
            }
          });
        }
      }
    },
    [mutationMethods.ADD_USERS](state, users) {
      users.forEach(user => {
        dbg("USEER", user)
        user.editable = "true";
        state.users.push(user);
      });
    },
    [mutationMethods.ADD_EXTERNAL_USERS](state, users) {
      users.forEach(user => {
        dbg("USEER", user)
        user.editable = "true";
        state.externalUsers.push(user);
      });
    },

    [mutationMethods.ADD_USER](state, user) {
      if (typeof state.users.count === 'undefined') {
        state.users.count = 0;
      }
    
      state.users.count++;
      const maxOrder = state.users.reduce((max, currentUser) => Math.max(max, currentUser.order), 0);
      user.id = state.users.count;
      if (maxOrder == 0) {
        user.order = 1;
      }
      else {
        user.order = maxOrder;
      }
     
      user.editable = "true";
      state.users.push(user);
    
    },

    [mutationMethods.ADD_EXTERNAL_USER](state, externalUser) {
      if (typeof state.externalUsers.count === 'undefined') {
        state.externalUsers.count = 0;
      }
    
      state.externalUsers.count++;
      const maxOrder = state.externalUsers.reduce((max, currentUser) => Math.max(max, currentUser.order), 0);
      externalUser.id = state.externalUsers.count;
      if (maxOrder == 0) {
        externalUser.order = 1;
      }
      else {
        externalUser.order = maxOrder;
      }
     
      externalUser.editable = "true";
      state.externalUsers.push(externalUser);
    
    },

    [mutationMethods.STORE_USERS](state, users) {
      state.loggedUsers = users
    },
    [mutationMethods.SET_CURRENT_USER](state, user) {
      state.currentUser = user
    },

    [mutationMethods.STORE_DOCUMENT_DETAIL](state, documentDetail) {
      state.documentDetail = documentDetail
      localStorage.setItem('documentDetail', JSON.stringify(documentDetail));
    },

    [mutationMethods.UPDATE_COMMENTS](state, comments) {
      dbg("documentDetail before", state.documentDetail)
      state.documentDetail.comments = comments
      dbg("documentDetail after", state.documentDetail)

      localStorage.setItem('documentDetail', JSON.stringify(state.documentDetail));
    },

    [mutationMethods.STORE_DOCUMENT_DETAIL_NOTE](state, note) {
      state.documentDetail.note = note
      localStorage.setItem('documentDetail', JSON.stringify(state.documentDetail));
    },


    [mutationMethods.STORE_MY_DOC](state, myDocs) {
      state.myDocs = myDocs
      state.myDocsTemp = myDocs
    },
    [mutationMethods.STORE_SHARED_DOC](state, sharedDocs) {
      state.sharedDocs = sharedDocs
      state.sharedDocsTemp = sharedDocs
    },



    [mutationMethods.ADD_DOCUMENT_ID](state, documentId) {
      state.documentId = documentId;
    },
    [mutationMethods.ADD_CERTIFICATE](state, certificate) {
      state.certificate = certificate;
    },
    [mutationMethods.RESET_USERS](state) {
      state.users = [];
      state.externalUsers = [];
    },


    [mutationMethods.RESET_MODAL_STEPS]: (state) => {
      state.currentModalStep = { id: 1, name: 'NewProcessModal', img: 'newProcessApprovalLogo.svg', title: 'New Process', description: 'Start a new process', approvalJoin: false }
      state.activeSteps = [1];
    },


    [mutationMethods.STORE_ACTIVE_TAB](state, activeTab) {
      state.activeTab = activeTab;
      localStorage.setItem('activeTab', JSON.stringify(activeTab));
      dbg('stored activeTab', activeTab)
    }
  },


  // =============================================================
  // GETTER METHODS
  // =============================================================


  getters: {
    [getterMethods.SORTED_USERS]: (state) => {
      return [...state.users].sort((a, b) => a.order - b.order || a.userId - b.userId);
    },
    [getterMethods.SORTED_EXTERNAL_USERS]: (state) => {
      return [...state.externalUsers].sort((a, b) => a.order - b.order || a.userId - b.userId);
    },

    [getterMethods.GET_UPLOADED_FILE]: state => state.uploadedFile,
    [getterMethods.GET_UPLOADED_FILE_IMAGE_SIGNATURE]: state => state.uploadedFileImageSignature,
    [getterMethods.GET_USERS]: state => state.users,
    [getterMethods.GET_STORE_USERS]: state => state.loggedUsers,
    [getterMethods.GET_CURRENT_USER]: state => state.currentUser,
    [getterMethods.GET_DOCUMENT_ID]: state => state.documentId,


    [getterMethods.GET_DOCUMENT_DETAIL]: (state) => {
      // Kopírování detailu dokumentu, aby nedošlo k přímé mutaci stavu
      const documentDetail = JSON.parse(JSON.stringify(state.documentDetail));
      
      if (documentDetail && documentDetail.users) {
        console.log("kys", documentDetail)
        documentDetail.users.forEach(user => {
            
              
              if (user.user_email) {
                  const parts = user.user_email.split('@');
                  user.user_email = parts[0];
              }
          });
      }
      return documentDetail;
    },

    [getterMethods.GET_INTERNAL_USER_IDS]: (state) => {
      
      if (state.users.length === 0) {
        return [];
      }
      
      let usersWithOrder = state.users.map(user => ({
        userId: user.userId,
        order: user.order
      }));
  
  
      usersWithOrder.sort((a, b) => a.order - b.order);

      let currentNormalizedOrder = 1;
      let lastOriginalOrder = usersWithOrder[0].order;
      usersWithOrder[0].normalizedOrder = currentNormalizedOrder;
  
      for (let i = 1; i < usersWithOrder.length; i++) {
        if (usersWithOrder[i].order !== lastOriginalOrder) {
          currentNormalizedOrder++; 
        }
        usersWithOrder[i].normalizedOrder = currentNormalizedOrder;
        lastOriginalOrder = usersWithOrder[i].order; 
      }
  
      return usersWithOrder.map(u => ({
        userId: u.userId,
        order: u.normalizedOrder 
      }));
    },

    [getterMethods.GET_EXTERNAL_USER_IDS]: (state) => {
      
      if (state.externalUsers.length === 0) {
        return [];
      }
      
      let usersWithOrder = state.externalUsers.map(user => ({
        userId: user.userId,
        order: user.order
      }));
  
  
      usersWithOrder.sort((a, b) => a.order - b.order);

      let currentNormalizedOrder = 1;
      let lastOriginalOrder = usersWithOrder[0].order;
      usersWithOrder[0].normalizedOrder = currentNormalizedOrder;
  
      for (let i = 1; i < usersWithOrder.length; i++) {
        if (usersWithOrder[i].order !== lastOriginalOrder) {
          currentNormalizedOrder++; 
        }
        usersWithOrder[i].normalizedOrder = currentNormalizedOrder;
        lastOriginalOrder = usersWithOrder[i].order; 
      }
  
      return usersWithOrder.map(u => ({
        userId: u.userId,
        order: u.normalizedOrder 
      }));
    },

    [getterMethods.GET_JOINED_FLOW]: state => (id) => {
      const joined = findJoined(state.myDocsTemp, id);
      return joined;
    },

    [getterMethods.FILTER_DOCS]: state => (filter, view) => {
      // Aplikovanie filtra a zoradenia na myDocs a sharedDocs
      // state.myDocs = filterDocs(state.myDocsTemp, filter.processType,  filter.myDocs.status, filter.searchText, filter.myDocs);
      // state.sharedDocs = filterDocs(state.sharedDocsTemp, filter.processType, filter.sharedDocs.status, filter.searchText, filter.sharedDocs);
      const sharedDocs = state.sharedDocsTemp;

      const myDocsResult = filterDocs(view, state.myDocsTemp, filter.processType, filter.myDocs.status, filter.searchText, filter.myDocs, filter.badgeFilters, filter.tableFilter, sharedDocs);
      state.myDocs = {
        docs: myDocsResult.docs,
        activeCount: myDocsResult.activeCount,
        finishedCount: myDocsResult.finishedCount
      };
      
      const sharedDocsResult = filterDocs(view, state.sharedDocsTemp, filter.processType, filter.sharedDocs.status, filter.searchText, filter.sharedDocs, filter.badgeFilters, filter.tableFilter, sharedDocs);
      state.sharedDocs = {
        docs: sharedDocsResult.docs,
        activeCount: sharedDocsResult.activeCount,
        finishedCount: sharedDocsResult.finishedCount
      };
    },

    [getterMethods.FILTER_COMBINED_DOCS]: state => (docs, filter) => {
      console.log(state.myDocsTemp);
      return filterCombinedDocs(docs, filter);
    },

    [getterMethods.GET_CERTIFICATE]: state => state.certificate,

    [getterMethods.GET_ACTIVE_TAB]: (state) => {
      return state.activeTab;
    },

    [getterMethods.GET_CURRENT_MODAL_STEP]: (state) => {
    
      if (state.currentModalStep.id === 1) {
        if (state.activeTab.abbr === 'AP') {
          return { id: 1, name: 'NewProcessModal', img: 'newProcessApprovalLogo.svg', title: 'New Approval Process', description: 'Start a new process', approvalJoin: false };
        } else {
          return { id: 1, name: 'NewProcessModal', img: 'newProcessSignatureLogo.svg', title: 'New Sign Process', description: 'Start a new process', approvalJoin: true };
        }
      }
      return state.currentModalStep;
    },
  },
  actions: {

    resetUsers({ commit }) {
      commit('RESET_USERS');
    },
    resetModalSteps({ commit }) {
      commit('RESET_MODAL_STEPS');
    },

    initStore({ commit }) {
      const storedDetail = localStorage.getItem('documentDetail');
      if (storedDetail) {
        // If storedDetail is found in localStorage, commit it to the state
        commit(mutationMethods.STORE_DOCUMENT_DETAIL, JSON.parse(storedDetail));
      }
    const savedTab = localStorage.getItem('activeTab');
    if (savedTab) {
      // Ak je v localStorage uložený activeTab, commitneme jeho hodnotu do stavu
      commit(mutationMethods.STORE_ACTIVE_TAB, JSON.parse(savedTab));
    }
    },
  },
});
