<template>
  <div v-if="show" class="add-note">
    <textarea v-model="note" :placeholder="$t('signModal.note')"></textarea>
    <div>
      
      <div v-if="props.mode == 'sign'">
        <button  class="confirm" @click="document_sign">
      {{ $t("signModal.sign") }}
      </button>
      <button  class="confirm" @click="document_sign_restriction">
        {{ $t("signModal.signWithCondition") }}
      </button>
      </div>

      <div v-if="props.mode == 'approve'">
        <button  class="confirm" @click="document_sign">
          {{ $t("signModal.approve") }}
      </button>
      </div>
      
      <div v-if="props.mode == 'decline'">
        <button class="confirm" @click="decline">
          {{ $t("signModal.decline") }}
      </button>
      </div>
    </div>
    
    <span class="close-button" @click="close">&#10006;</span>
  </div>
    <!-- document signing -->
    <teleport to="body">   
      <div class="modalExport" v-show="showSigner"> 
          <div class="modalSignerViewExport">
              <div class="highlightHeaderContent">
                  <h3 class="highlightTitle">{{filename}}</h3>
                  <div id="closeHighlight" @click="showSignerSwitch"> <FontAwesomeIcon icon="times" /> </div>
              </div>
              <div class="signerIframe">
                  <iframe class="signer" v-if="signerLink" :src="signerLink" ></iframe>
              </div>
          </div>
      </div>
  </teleport>

  <transition>
    <InfoPopUp v-if="(store.state.msg.length > 0)" />
  </transition>

    <LoadingModal :visible="loading" :message="$t('loading.message')" />

</template>

<script setup>
import dbg from "@/debug";
import { ref, watch } from "vue";
import { defineProps, defineEmits } from "vue";
import { useRouter } from "vue-router";
import { declineDocument, qSign, QsignDocument, getFile, signDocument, approveDocument, qSignMultiple } from "../services";
import { useStore } from "vuex";
import { mutationMethods, getterMethods } from "../store/Istore.js";
import { base_url, front_url } from "@/api";
import InfoPopUp from './InfoPopUp.vue';
import { useI18n } from 'vue-i18n';

import LoadingModal from './LoadingModal.vue'; 

const loading = ref(false);

const router = useRouter();
const { t } = useI18n();
const store = useStore();
const props = defineProps({
  show: Boolean,
  
  //0 - sign/sign with restriction 
  //1 - decline
  mode: String,

  docId: String,
  filename: String,
});

const emit = defineEmits(["close"]);

const note = ref("");
let showSigner = ref(false);
let signerLink = ref("");

const popUp = (type, text) => {
  store.commit(mutationMethods.PUSH_MSG, { type: type, text: text });
};

const showSignerSwitch = async () => {
  await QsignDocument(props.docId, note.value);
  showSigner.value = !showSigner.value;
  const message = { action: 'someAction', data: 'someData' };
  window.parent.postMessage(message, front_url);
};

const document_sign_restriction = async () => {
  if (note.value.trim() === "") {
    popUp(2, t("popUp.note")); 
    return;
  }

  let certificate = store.getters[getterMethods.GET_DOCUMENT_DETAIL].document_certificate;
  loading.value = true;
  if(certificate)
  {
    dbg("podpisujem minex sign");
    await sign(true);
  }
  else 
  {
    dbg("podpisujem qsign");
    await qsign(true);
  }
};

const document_sign = async () => {
  let certificate = store.getters[getterMethods.GET_DOCUMENT_DETAIL].document_certificate;
  loading.value = true;
  if(certificate == true)
  {
    dbg("podpisujem minex sign");
    await sign(false);
  }
  else if (certificate == false)
  {
    dbg("podpisujem qsign");
    await qsign(false);
  }
  else
  {
   dbg("schvalujem proces")
    await approve();
  }

};

const qsign = async (restriction) => {
  try {
    // Call the getFile function to get the file as FormData
    const formData = await getFile(props.docId, 1, props.filename);
    formData.append('lang', 'en');
    formData.append('redirect_url', `${base_url}/signed`);
    formData.append('restriction', restriction);

    //ulozim note.value do state.document_detail
    
    store.commit(mutationMethods.STORE_DOCUMENT_DETAIL_NOTE, note.value)
    
    close();
    
    try {
      //1: when I start to sign the qsign process 
      const response_in_process = await QsignDocument(props.docId, note.value, restriction);
      
      // if someone in signing the doc
      if (response_in_process.status !== 200){
        throw new Error(`Error signing file. Status: ${response.status}`);
      }

      
      //otherwise im gonna sign it
      var response = null;

      if (formData.getAll('file').length == 1){
        response = await qSign(formData);
      }
      else{
        response = await qSignMultiple(formData);
      }
      console.log(formData.getAll('file').length)
      console.log(response.data.redirect_url);
      console.log('som tu ')
      
      if (response.status !== 200) {

        // when ardaco sign failed, we set in_process to none
        await QsignDocument(props.docId, note.value);

        throw new Error(`Error signing file. Status: ${response.status}`);
      }
      loading.value = false
      //show iFrame with qSign
      signerLink.value = response.data.redirect_url;
      showSigner.value = !showSigner.value

    } catch (error) {
      console.error(error);
    }
  } catch (error) {
    console.error("Error getting file as FormData:", error);
  }
};

const sign = async (restriction) => {
  try {
    // Replace these with actual values or props
    var signed_at = new Date();
    signed_at.setHours(signed_at.getHours() + 1); // Přidání jedné hodiny pro UTC+1
    signed_at = signed_at.toISOString().slice(0, 19).replace('T', ' ');
    const response = await signDocument(props.docId, note.value, signed_at, restriction);
    console.log("Document signed:", response);
    dbg("note text", note.value);
    note.value = ""; // Reset poznámky

    router.push({ name: "TempComponent" });
    // Add any UI update logic here
  } catch (error) {
    console.error("Error signing document:", error);
  }
};

const approve = async () => {
  try {
    var approve_at = new Date();
    approve_at.setHours(approve_at.getHours() + 1); // Přidání jedné hodiny pro UTC+1
    approve_at = approve_at.toISOString().slice(0, 19).replace('T', ' ');
    const response = await approveDocument(props.docId, note.value, approve_at);
    console.log("Document approved:", response);
    dbg("note text", note.value);
    note.value = ""; // Reset poznámky

    router.push({ name: "TempComponent" });
    // Add any UI update logic here
  } catch (error) {
    console.error("Error signing document:", error);
  }
};

const decline = async () => {
  try {
    // Replace these with actual values or props
    var signed_at = new Date();
    signed_at.setHours(signed_at.getHours() + 1); // Přidání jedné hodiny pro UTC+1
    signed_at = signed_at.toISOString().slice(0, 19).replace('T', ' ');
    const response = await declineDocument(props.docId, note.value, signed_at, store.getters['getActiveTab'].abbr);
    console.log("Document declined:", response);
    dbg("note text", note.value);
    note.value = ""; // Reset poznámky

    router.push({ name: "TempComponent" });
    // Add any UI update logic here
  } catch (error) {
    console.error("Error signing document:", error);
  }
};

watch(
  () => props.show,
  (newValue) => {
    console.log("AddNote modal visibility changed:", newValue);
  }
);
// Metóda na zatvorenie komponentu
const close = () => {
  emit("close");
};
</script>

<style scoped>
.add-note {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;   
  z-index: 101;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Mierne tmavší overlay */
  padding: 20px;
  box-sizing: border-box;
}

textarea {
  width: 80%; /* Šírka textového poľa */
  height: 150px; /* Výška textového poľa */
  margin-bottom: 20px; /* Medzera pod textovým poľom */
  padding: 10px; /* Vnútorný okraj pre textové pole */
  border: 2px solid #ddd; /* Hranice textového poľa */
  border-radius: 8px; /* Zaoblené rohy */
  font-size: 16px; /* Veľkosť písma */
  resize: none; /* Zabrániť zmenám veľkosti */
}

.confirm {
  padding: 10px 20px;
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background-color: #2156a4; /* Zelená farba tlačidla */
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  font-size: 24px;
  color: #fff; /* Biela farba krížika */
}

.modalExport {
    position: fixed;
    top: 0%;
    z-index: 100;
    left: 0%;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalExport > div {
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 5px;
}

.modalSignerViewExport {
    width: 70%;
    height: 95%;
    font-family: 'Poppins', sans-serif;;
    overflow-y: auto;
}

.highlightHeaderContent {
    background-color: lightgrey;
    margin: 5px;
    display: flex;
    align-items: center;
    border-radius: 5px;
}

.highlightTitle {
    width: 97%;
    text-align: center;
}

#closeHighlight {
    text-align: center;
    width: 3%;
    height: 3%;
    background-color: darkgray;
    border-radius: 5px;
    margin-right: 1%;
}

.signerIframe {
    height: 90%;
    margin: 5px;
}

.signer {
    width: 100%;
    height: 100%;
    border: none;
}
</style>