<template>
  <div class="table-container">
    <table class="table-model">
      <thead>
        <tr>
          <th class="header-font" v-for="(column, index) in columns" :key="index">
            <div class="title">
              <span>{{ column.name }}</span>
              <img v-if="activeSortColumn === column.id && sortOrder[column.id] === 'asc'"
                src="../../public/table_order_asc.svg" @click="handleIconClick(column.id)" class="sort-icon" />
              <img v-if="activeSortColumn === column.id && sortOrder[column.id] === 'desc'"
                src="../../public/table_order_desc.svg" @click="handleIconClick(column.id)" class="sort-icon" />
              <img v-if="activeSortColumn !== column.id || !sortOrder[column.id]" src="../../public/table_order.svg"
                @click="handleIconClick(column.id)" class="sort-icon hover-active" />
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index" :class="{ active: isActiveRow(index) }"
          @click="handleRowClick(item.process_id, item.flow_col, index)">
          <td class="row-font" v-for="column in columns" :key="column.id">
            <template v-if="column.id === 'slider'">
              <ProgressBar :mode="1" :status="item.status" :possibleCount="item.signed_count"
                :allCount="item.all_count" />
            </template>
            <template v-else-if="column.id === 'assignee'">
              <AssigneeCircles :assignee="item.users" />
            </template>
            <template v-else-if="column.id === 'due'">
              {{ item.doc_due_date }}
            </template>
            <template v-else-if="column.id === 'created'">
              {{ item.created_date }}
            </template>
            <template v-else-if="column.id === 'autor'">
              {{ item.email }}
            </template>
            <template v-else-if="column.id === 'id'">
              {{ item.process_id }}
            </template>
            <template v-else-if="column.id === 'nazov'">
              <div class="title">
                <img v-if="activeTab.abbr == 'AP'" src="../../public/approvalLogo.svg" alt="">
                <img v-if="activeTab.abbr == 'DS'" src="../../public/minexLogo.svg" alt="">
                <img v-if="activeTab.abbr == 'QDS'" src="../../public/qsignLogo.svg" alt="">
                {{ item.title }}
              </div>
            </template>
            <template v-else-if="column.id === 'mojStav'">
              <template v-if="item.status === 'Signed'">


                {{ activeTab.abbr === 'AP' ? $t('rightSideBar.approved') : $t('rightSideBar.signed') }}
              </template>
              <template v-else-if="item.status === 'SignedRestriction'">
                {{ $t('rightSideBar.signedCondition') }}
              </template>
              <template v-else-if="item.status === 'Canceled'">
                {{ $t('rightSideBar.canceled') }}
              </template>
              <template v-else-if="item.status === 'Declined'">
                {{ $t('rightSideBar.declined') }}
              </template>
              <template v-else-if="item.status === 'NotSigned'">
                {{ item.previous === 'NotSigned'
            ? $t('rightSideBar.waiting')
            : (activeTab.abbr === 'AP'
              ? $t('rightSideBar.notApproved')
              : $t('rightSideBar.notSigned'))
                }}
              </template>
            </template>
            <template v-else>
              {{ item[column.id] }}
            </template>
            <template v-if="column.id === 'prepojenie'">
              <template v-if="item.joined_process_id == null">
                {{ $t('rightSideBar.no') }}
              </template>
              <template v-else>
                {{ $t('rightSideBar.yes') }}
              </template>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { ref, reactive, computed,  defineEmits, defineExpose, watchEffect, defineProps } from 'vue';
import ProgressBar from './ProgressBar.vue'; 
import AssigneeCircles from './AssigneeCircles.vue'; 
import { useStoreGlobal } from "@/store/storeHooks";
import { useI18n } from 'vue-i18n';

const store = useStoreGlobal();

const activeTab = computed(() => store.state.activeTab);

const emit = defineEmits(['detail'], ['tableOrder']);

const { t } = useI18n();

const props = defineProps({
  items: Array
});

defineExpose({ refreshYourself });

const columns = reactive([
  { id: 'nazov', name: t('processTable.name') },
  { id: 'id', name: 'ID' },
  { id: 'autor', name: t('processTable.author') },
  { id: 'assignee', name: t('processTable.assignee') },
  { id: 'due', name: t('processTable.due') },
  { id: 'created', name: t('processTable.created') },
  { id: 'slider', name: t('processTable.slider') },
  { id: 'mojStav', name: t('processTable.status') },
  { id: 'prepojenie', name: t('processTable.joined') },
]);

watchEffect(() => {
  columns[0].name = t('processTable.name');
  columns[2].name = t('processTable.author');
  columns[3].name = t('processTable.assignee');
  columns[4].name = t('processTable.due');
  columns[5].name = t('processTable.created');
  columns[6].name = t('processTable.slider');
  columns[7].name = t('processTable.status');
  columns[8].name = t('processTable.joined');
});

const activeRowIndex = ref(null);
const activeSortColumn = ref(null);
const sortOrder = ref({});

const handleRowClick = (id, flow_col, index) => {
  if (activeRowIndex.value !== index) {
    activeRowIndex.value = index;
  }
  emit('detail', id, flow_col);
  console.log(id);
};

function refreshYourself() {
  console.log('reloading');
  resetActive();
}

const resetActive = () => {
  activeRowIndex.value = null;
};

const isActiveRow = (index) => {
  return index === activeRowIndex.value;
};

const handleIconClick = (key) => {
  if (activeSortColumn.value !== key) {
    activeSortColumn.value = key;
    sortOrder.value[key] = 'asc';
  } else {
    if (sortOrder.value[key] === 'asc') {
      sortOrder.value[key] = 'desc';
    } else if (sortOrder.value[key] === 'desc') {
      activeSortColumn.value = null;
      sortOrder.value[key] = null;
    } else {
      sortOrder.value[key] = 'asc';
    }
  }
  console.log(`Sorting by ${key} in order: ${sortOrder.value[key]}`);
  emit('tableOrder', key, sortOrder.value[key]);
};
</script>



<style scoped>
.table-container {
  margin-top: 1rem;
  margin-inline: 1rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  overflow: auto;
  max-height: 70vh;
}

.infoBtn {
  width: 15px;
  color: #757575;
}

.table-model {
  width: 100%;
  border-collapse: collapse;
}

.table-model th,
.table-model td {
  width: 100px;
  min-width: 100px;
  white-space: nowrap;
}

.table-model th {
  position: sticky;
  top: 0;
  background: #ffffff;
  color: #3a3541ee;
  text-transform: uppercase;
  font-size: 13px;
  z-index: 100;
  font-weight: 600;
  text-align: left;
  padding: 12px 8px;
}

.table-model tr:hover td {
  background-color: #ffffff7d;
}

.table-model tr.active td {
  background-color: #f2faff;
  /* Adjust the active row background color to match hover */
}

.table-model td {
  border: none;
  border-top: 1px solid #e6e6e6;
  padding: 8px;
  text-align: left;
  color: #272727e0;
  font-weight: 400;
  font-size: 13px;
  background-color: #ffffff;
  padding-top: 12px;
  padding-bottom: 12px;
}

.table-model .no-data {
  font-weight: bold;
  color: #424242;
}

.checked-span {
  font-weight: 500;
  color: #8a74cf;
}

/* width and height */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #F5F5F5;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:horizontal {
  background: #888;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.title {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.active {
  background-color: #f0f0f0;
}

.sort-icon {
  cursor: pointer;
  width: 16px;
  height: 16px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hover-active:hover {
  background: rgb(235, 235, 235);
  border-radius: 6px;
}
</style>
