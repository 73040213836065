<template>
  <div v-if="mode == 0">
    
    <div class="title">{{ props.title }}</div>
    <div class="progress-bar-container">
      <div class="progress-bar"  :class="{ red: props.status == 'Canceled', green: (props.possibleCount == props.allCount && (props.status == 'Signed' || props.status == 'SignedRestriction')) }" :style="{ width: progressPercentage + '%' }"></div>
    </div>
    <div class="inline-flex">
        <div class="subtitle">{{ props.subtitle }}</div>
        <div class="progress-text">{{ props.possibleCount }} / {{ props.allCount }}</div>
    </div>
  </div>

  <div v-if="mode == 1">
    
    <div class="inline-flex">
    <div class="progress-bar-container">
      
      <div class="progress-bar" :class="{ red: props.status == 'Canceled' || props.status == 'Declined', green: (props.possibleCount == props.allCount && (props.status == 'Signed' || props.status == 'SignedRestriction')) }"  :style="{ width: progressPercentage + '%' }"></div>
    </div>
        <div class="progress-text inline">{{ props.possibleCount }}/{{ props.allCount }}</div>
    </div>
  </div>
  
</template>

<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps({
  possibleCount: {
    type: Number,
    required: true
  },
  allCount: {
    type: Number,
    required: true
  },
  title: String,
  subtitle: String,
  mode: Number,
  status: Boolean,
});

const progressPercentage = computed(() => {
  if (props.allCount === 0) {
    return 0;
  }
  return (props.possibleCount / props.allCount) * 100;
});
</script>

<style scoped>
.title {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  color: #252525;
}

.subtitle {
  margin: 0;
  font-size: 11px;
  color: #ABABAB;
}

.progress-bar-container {
  position: relative; /* Pro umístění textu */
  width: 100%;
  background-color: #D9D9D9;
  border-radius: 38px;
  height: 4px;
}

.progress-bar {
  background-color: #2156A4;
  height: 100%;
  border-radius: 38px;
}

.red {
  background-color: #C34040; 
}

.green {
  background-color: #40C377; 
}


.inline-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}



.progress-text {
  
  word-wrap: none;
  font-size: 11px;
  color: #ABABAB;
}

.inline {
    margin-left: 8px;
    width: 27px;
}
</style>
