<template>
    <div class="box">
        
        <!-- Title -->
        <div class="input-box">
            <label for="process-name">{{ t("addProcess.processName") }}</label>
            <input v-model="processTitle" type="text" id="process-name" v-bind:placeholder="t('addProcess.enterProcessName')" />
        </div>

        <!-- Date picker and approval id -->
        <div class="inline-box">
            <div class="input-box">
                <label for="process-date">{{ t("addProcess.processDate") }}</label>
                <input v-model="processDate" type="date" id="process-date" />
            </div>

            <div class="input-box" >
                
                <div class="inline-box" v-if="activeTab.abbr != 'AP'">
                    <label for="process-name">{{ t("addProcess.approvalJoin") }}</label>
                    <!-- switch button for approval id -->
                    <div class="toggle-switch" >
                        <input type="checkbox" id="approval-join" class="approval-join-checkbox" v-model="isApprovalIdChecked" @change="toggleApprovalId"/>
                        <label for="approval-join" class="toggle-label"></label>
                    </div>
                </div>
                <input v-if="activeTab.abbr != 'AP'" v-model="processApprovalId" type="text" id="process-name" v-bind:placeholder="t('addProcess.enterApprovalId')" :disabled="isApprovalIdChecked === false" />
            </div>
        </div>

        <!-- Multiline note -->
        <div class="input-box note-box">
            <label for="process-note">{{ t("addProcess.processNote") }}</label>
            <textarea  v-model="processNote" id="process-note" rows="4" v-bind:placeholder="t('addProcess.enterProcessNote')"></textarea>
        </div>
    </div>
</template>

<script setup>
import { ref, defineExpose, computed, onMounted} from "vue";
import { useStoreAddProcess, useStoreGlobal } from "@/store/storeHooks";
import dbg from "@/debug";
import { useI18n } from 'vue-i18n';

// === Ref variables =============================================================
//
// ===============================================================================
const store = useStoreAddProcess();
const storeGlobal = useStoreGlobal();

const newProcessData = computed(() => store.state.newProcess);

const processTitle = ref('');
const processDate = ref('');
const isApprovalIdChecked = ref(false);
const processApprovalId = ref('');
const processNote = ref('');
const { t } = useI18n();

const activeTab = storeGlobal.getters['getActiveTab'];

// === Functions =================================================================
//
// ===============================================================================

// load values from store if exist
onMounted(() => {
    if (newProcessData.value) {
        dbg("NewProcessModal mounted", newProcessData.value)
        processTitle.value = newProcessData.value.title;
        processDate.value = newProcessData.value.date;
        isApprovalIdChecked.value = newProcessData.value.isApprovalIdChecked;
        processApprovalId.value = newProcessData.value.approvalId;
        processNote.value = newProcessData.value.note;
    }
    
})

// when I want to enable/disable the approval id input field
function toggleApprovalId() {

    if (isApprovalIdChecked.value === false) {
        processApprovalId.value = '';
    }
}

// when I press next in AddProcess.vue
function newProcessPayload() {

    const payload = {
        title: processTitle.value,
        date: processDate.value,
        isApprovalIdChecked: isApprovalIdChecked.value,
        approvalId: processApprovalId.value,
        note: processNote.value 
    }
    return payload
}

defineExpose({
    newProcessPayload
})

</script>

<style scoped>
.box {
    width: 100%;    
    height: 100%;
    display: flex;
    flex-direction: column;
}

.input-box {
    display: flex;
    margin-block: 2rem;
    flex-direction: column;
    text-align: left;
    width: 100%;
    margin-bottom: 1rem;
}

.note-box {
    margin-bottom: 0;
    height: 100%;
}

.input-box label {
    font-size: 18px;
    color: #000000;
    margin-bottom: 0.5rem;
}

.input-box input {
    height: 45px;
    border-radius: 6px;
    border: 1px solid #DEE2E6;
    color: black;
    padding: 0 1rem;
    font-size: 16px;
    transition: 0.2s;
}

.input-box input::placeholder {
    color: #ADB5BD;
}

.input-box textarea {
    height: 100%;
    border-radius: 8px;
    border: 1px solid #DEE2E6;
    color: black;
    padding: 1rem;
    margin-bottom: 1rem;
    font-size: 16px;
    resize: none; /* Optional: prevents resizing */
    transition: 0.2s;
}

.input-box textarea::placeholder {
    color: #b8bec5;
}

textarea:focus {
    outline: none;
    border: 1px solid #c5c7c9;
    
}

input:focus {
    outline: none;
    border: 1px solid #c5c7c9;
}



.inline-box {
    display: flex;
    align-items: center;
    gap: 2rem;
}

/* Toggle switch styling */
.toggle-switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 18px; 
}

.approval-join-checkbox {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle-label {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px; 
}

.approval-join-checkbox:checked + .toggle-label {
    background-color: #0D6EFD; 
}

.toggle-label:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: -2px;
    bottom: -4px;
    background-color: #0D6EFD;
    transition: .1s;
    border-radius: 50%;
}

.approval-join-checkbox:checked + .toggle-label:before {
    transform: translateX(20px); 
}

.open-button {
  position: absolute;
  top: 10px;
  right: 11px;
  width: 25px;
  height: 25px;
  background: #fff;
  pointer-events: none;
}

.open-button button {
  border: none;
  background: transparent;
}

.approval-join-checkbox:checked + .toggle-label:before {
    transform: translateX(20px); 
}

/*
used to hide the default input calendar icon
input[type="date"]::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
} */


</style>