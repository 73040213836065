import { createRouter, createWebHistory } from 'vue-router';
import AddDocument from '../views/AddDocument.vue';
import SharedDocuments from '../views/SharedDocuments3.vue';
import ExternalSign from '../views/ExternalSign.vue';
import TempComponent from '../views/TempComponent.vue';
import ExternProcessDetails from '../views/ExternProcessDetails.vue';
import SelectProduct from '../components/SingleSign.vue';
import EnvProvider from 'jvjr-docker-env';
import { mutationMethods } from "../store/Istore.js";
import { useCookies } from "vue3-cookies";
import store from '../store/store.js';
import { auth_api } from '../api.js';

const { cookies } = useCookies();
import VerifyDocument from '../components/VerifyDocument.vue';
import ImageSignature from '../components/ImageSignature.vue';

const routes = [
  {
    path: '/',
    redirect: '/shared-documents',
    meta:{requiresAuth:false},
  },
  {
    path: '/verify-doc',
    name: 'VerifyDocument',
    component: VerifyDocument
  },
  {
    path: '/image-signature/:mode',
    name: 'ImageSignature',
    component: ImageSignature,
    props: true // Automatically passes route params as props
  },

  {
    path: '/add-document',
    name: 'AddDocument',
    component: AddDocument,
    meta:{requiresAuth:true},
  },

  {
    path: '/shared-documents',
    name: 'SharedDocuments',
    component: SharedDocuments,
    meta:{requiresAuth:true},
  },

  {
    path: '/single-sign',
    name: 'SingleSign',
    component: SelectProduct,
    meta:{requiresAuth:true},
  },
  {
    path: '/extern-process-details/:process_id',
    name: 'ExternProcessDetails',
    component: ExternProcessDetails,
    meta:{requiresAuth:false},
    props: true,
  },
  {
    path: '/signed',
    name: 'TempComponent',
    component: TempComponent,
    meta:{requiresAuth:true},
  },
  {
    path: '/external-sign/:process_id',
    name: 'ExternalSign',
    component: ExternalSign,
    meta:{requiresAuth:false},
    props: true,
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

async function auth(){
  console.log('Startin auth')
  try {
    const response = await auth_api.get('/auth', { withCredentials: true })
    if (response.status === 200){
      if (response.data.logged_in){
        console.log('true')
        return true
      } else {
        console.log('false')
        return false
      }
    } else {
        console.log('false')
        return false
    }
  } catch(err){
    console.log(err)
    return false
  }
}

function setLoggedUser(){
  let user = cookies.get('userName')

  if(user != null){
    cookies.set('userName', user, EnvProvider.value('COOKIE_DURATION'), null, EnvProvider.value('HOST_DOMAIN'))
    store.commit(mutationMethods.SET_CURRENT_USER, user);
  }
  else {
    store.commit(mutationMethods.SET_CURRENT_USER, '');
    console.log('Failed to set username from cookie')
  }
}

// Authentication logic on routes
router.beforeEach(async (to, from, next) => {
  if (to.meta && to.meta.requiresAuth === false) {
    next() // requires auth is explicitly set to false
  } else if (await auth()) {
    setLoggedUser()
    next() // i'm logged in. carry on
  } else {
    // unauthorized, redirect to login menu
    let home = new URL("/login-page", EnvProvider.value('API_AUTH'));
    window.location.href = home;
  }
})

export default router;
