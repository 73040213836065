<template>
    <div v-if="show" class="modal-backdrop">
      <div class="modal">
        <button class="close-btn" @click="closeModal">X</button>
        <span v-if="currentStep === 'signatureSet'">{{$t('autoAddModal.signType')}}</span>
        <span v-if="currentStep === 'lastPageSet'">{{$t('autoAddModal.origDoc')}}</span>

        <div v-if="currentStep === 'signatureSet'">
          
          <div :class="['option', { 'selected-option': selectedOptions.step1?.abbr === 'DS' }]" @click="chooseOption({ abbr: 'DS', fullName: 'digitalSignature' })">
            <img src="/minexLogo.svg" alt="">
          <div>{{$t('autoAddModal.minexCertificate')}}</div>
        </div>
        <div :class="['option', { 'selected-option': selectedOptions.step1?.abbr === 'QDS' }]" @click="chooseOption({ abbr: 'QDS', fullName: 'qualifiedDigitalSignature' })">
          <img src="/qsignLogo.svg" alt="">
          <div>{{$t('autoAddModal.qsignCertificate')}}</div>
        </div>

        <div class="buttons">
          <button  @click="closeModal()">{{$t('autoAddModal.cancel')}}</button>
          <button class="next" :disabled="!selectedOptions.step1" @click="nextPage()">{{$t('autoAddModal.next')}}</button>
        </div>
      </div>

        <div v-else-if="currentStep === 'lastPageSet'">
          <div :class="['option', { 'selected-option': selectedOptions.step2 === 'yes' }]" @click="chooseOption('yes')">
            {{$t('autoAddModal.useOriginal')}}
          </div>
          <div :class="['option', { 'selected-option': selectedOptions.step2 === 'no' }]" @click="chooseOption('no')">
            {{$t('autoAddModal.dnotUseOriginal')}}
          </div>
          <div class="buttons">
            <button @click="prevPage()">{{$t('autoAddModal.back')}}</button>
            <button class="next" :disabled="!selectedOptions.step2" @click="finish()">{{$t('autoAddModal.confirm')}}</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, defineProps, defineEmits } from 'vue';
  import { useStoreAddProcess, useStoreGlobal } from "@/store/storeHooks";
  import { automaticProcess } from "@/services";
import dbg from '@/debug';
 const props = defineProps({
    show: Boolean,
    processId: String
  });
  
  const emit = defineEmits(['close'], ['closeRightSidebar']);
  
// === Stores ====================================================================
//
// ===============================================================================

// eslint-disable-next-line
const store = useStoreGlobal();
// eslint-disable-next-line
const storeAddProcess = useStoreAddProcess();

  const currentStep = ref('signatureSet');
  const selectedOptions = ref({ step1: null, step2: null });
  
  // Helper function to decode base64 and create File object
  const base64ToFile = (base64String, fileName) => {
    const byteString = atob(base64String);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: 'application/pdf' });
    return new File([blob], fileName, { type: 'application/pdf' });
  };

async function requestData() {

    // here will be the request 

  var data = {
    signature_type: selectedOptions.value.step1,
    last_page: selectedOptions.value.step2,
    process_id: props.processId
  }
  var request = await automaticProcess(data)
  dbg("request", request);

  var payload1 = { 
    title: request.document_title,
    date: request.document_due_date,
    isApprovalIdChecked: true,
    approvalId: request.process_id,
    note: request.document_note 
  }
  dbg("payload1", payload1);

  storeAddProcess.commit('storeAddProcess', payload1);

  const files = request.documents.map(doc => base64ToFile(doc.file, doc.name));

const payload2 = {
  files: files,
  fileData: {
    name: files.map(file => file.name),
    size: files.map(file => file.size)
  }
};
  storeAddProcess.commit('storeAddDocument', payload2);

  const users = request.users.map(user => ({
          userId: user.user_id,
          name: user.user_email, // Assuming user_email is used as name here
          email: user.user_email,
          signed: 'NotSigned',
          note: user.note,
          order: user.signature_order
        }));
  store.commit('addUsers', users);
  // in request body will be selectedOptions and
        // process_id, which will be taken from props
    //close this modal

    //close rightsidebar
    
    //store data to storeAddProcess
        //from request like title, date, files, users
    //open AddProcessModal on last layer

    store.commit('setModalStep', 3);
    closeModal();
    emit('closeRightSidebar');
    storeAddProcess.commit('openModal', "addProcess");
    //using eventBus probably
}

  const chooseOption = async (option) => {
    if (currentStep.value === 'signatureSet') {
      selectedOptions.value.step1 = option;
      
      // currentStep.value = 'lastPageSet';
    } else if (currentStep.value === 'lastPageSet') {
      selectedOptions.value.step2 = option;
      console.log('Selected options:', selectedOptions.value);
      // await requestData();
    }
  };
  
  const nextPage = () => {
    if (currentStep.value === 'signatureSet') {
      currentStep.value = 'lastPageSet';
    }
  };
  const prevPage = () => {
    if (currentStep.value === 'lastPageSet') {
      currentStep.value = 'signatureSet';
    }
  };
  const finish = async () => {
    store.commit('storeActiveTab', selectedOptions.value.step1);
    await requestData();
  };

  const closeModal = () => {
    resetSteps();
    emit('close');
  };
  
  const resetSteps = () => {
    currentStep.value = 'signatureSet';
    selectedOptions.value = { step1: null, step2: null };
  };
  </script>
  
  <style scoped>
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    max-width: 400px; 
    min-width: 400px;
    width: 100%; 
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 16px;
  }
  .option {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    height: 45px;
    align-items: center;
  }
  .option img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .selected-option {
    border: 1px solid #538BDD; 
    color: #538BDD;
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
  }
  .buttons button{
    padding-block: 7px;
    width: 100px;
    border: 1px solid #E2E2E2;
    background-color: white;
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
    margin-top: 1rem;

  
  }
  .buttons .next {
    background-color: #2156A4;
    color: white;
  }
  .buttons button:disabled {
    background-color: #4177c8;
    cursor: not-allowed;
  }
  span {
    font-size: 12px;
    display: flex; 
    justify-content: flex-start;
    margin-bottom: 0.7rem;
    margin-top: 1.5rem;
  }
  </style>
  