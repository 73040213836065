import { inject } from 'vue';
import store1 from './store.js';
import store2 from './AddProcessStore.js';

const Store1Key = Symbol();
const Store2Key = Symbol();

export function provideStores(app) {
  app.provide(Store1Key, store1);
  app.provide(Store2Key, store2);
}

// storeHooks.js
export function useStoreGlobal() {
    const store = inject(Store1Key);
    if (!store) {
      console.error("Global store is not provided.");
      return;
    }
    return store;
  }
  
  export function useStoreAddProcess() {
    const store = inject(Store2Key);
    if (!store) {
      console.error("AddProcess store is not provided.");
      return;
    }
    return store;
  }
  
