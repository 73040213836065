<template>
    <div class="loading-modal" v-if="props.visible">
      <div class="spinner"></div>
      <p>{{ props.message }}</p>
    </div>
  </template>
  
  <script setup>
  import { defineProps } from 'vue';

  const props = defineProps({
    visible: {
      type: Boolean,
      required: true
    },
    message: {
      type: String,
      default: ''
    }
  });
  </script>
  
  <style scoped>
  .loading-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    font-size: 18px;
    z-index: 1000; /* Make sure it appears on top of other content */
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(255, 255, 255, 0.3);
    border-top: 5px solid white;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  </style>
  