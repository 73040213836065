<template>
  <div class="box">
    <div class="user-box">
      <div class="upload-box">
        <label v-if="activeDot == 'usersList'" for="process-date">{{ $t('addExternalUsersModal.header') }}</label>

        <div class="inline-box">

          <vue3-simple-typeahead 
            v-if="activeDot === 'usersList'" 
            id="searchBoxId" 
            :key="typeaheadKey"
            :placeholder='$t("adduserModal.search")' 
            :items="typeaheadItems" 
            :minInputLength="1" 
            autocomplete="on"
            @selectItem="selectItem" 
            @keydown.enter="selectItem" 
            v-model="simpleTypeaheadValue">
          </vue3-simple-typeahead>

        </div>
      </div>


    
    </div>

    <div class="line">
      <span>{{ $t("addProcess.users") }}</span>
    </div>

    <div class="list-box">
      <div class="file-box" v-for="(user) in sortedUsers" :key="user.id">
        <div class="order-controls">
          <img src="../../../public/arrow_up.svg" alt="" @click="moveUserUp(user.userId)" />
          <input type="text" :value="user.order.toString()" style="width: 30px; text-align: center"
            @keyup.enter="handleOrderInputChange($event, user.userId)"
            @blur="handleOrderInputChange($event, user.userId)" class="order-input" required />
          <img src="../../../public/arrow_down.svg" alt="" @click="moveUserDown(user.userId)" />
        </div>

        <div class="vertical-line"></div>
        <img src="../../../public/user_modal.svg" alt="">
        <div class="block-box">
          <h2>{{ user.name }}</h2>
          <h3>{{ user.email }}</h3>
        </div>
        <img src="../../../public/delete.png" alt="" class="delete-img" @click="deleteUser(user.userId)" />
      </div>

      <div class="text-empty" v-if="!sortedUsers.length">
        {{ $t("addProcess.noUsers") }}
      </div>
    </div>


  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useStoreGlobal } from "@/store/storeHooks";
import CustomDropdown from '../../components/CustomDropdown.vue';

const storeGlobal = useStoreGlobal();

const typeaheadKey = ref(0);
const typeaheadKeyGroup = ref(0);
const storeUsers = computed(() => storeGlobal.getters.getStoreUsers);
const typeaheadItems = computed(() => {
  return storeUsers.value
    .filter(user => user.category === 'External')
    .map(user => user.email);
});

const typeaheadItemsGroup = computed(() => {
  const allGroups = storeUsers.value.map((user) => user.group);
  const uniqueGroups = [...new Set(allGroups)];
  return uniqueGroups;
});
const usersList = computed(() => storeGlobal.state.users);
const selectedUser = ref(null);
const currentUser = ref(null);
const simpleTypeaheadValue = ref("");
const simpleTypeaheadValueGroup = ref("");

const sortedUsers = computed(() => storeGlobal.getters['sortedExternalUsers']);
const activeDot = ref('usersList');


const selectItem = (item) => {
  selectedUser.value = item;
  currentUser.value = item;

  const user = storeUsers.value.find((u) => u.email === selectedUser.value);
  if (user) {
    const isUserExists = usersList.value.some(u => u.email === user.email);
    if (isUserExists) {
      return;
    }
    storeGlobal.commit('addExternalUser', {
      name: user.email,
      email: user.email,
      signed: "NotSigned",
      userId: user.id,
      editable: "true",
    });
  }

  simpleTypeaheadValue.value = "";
  typeaheadKey.value++;
};

const selectItemGroup = (item) => {
  console.log("selected item: ", item);

  storeGlobal.commit('resetUsers');

  const usersInGroup = storeUsers.value.filter(user => user.group === item);

  usersInGroup.forEach(user => {
    storeGlobal.commit('addUser', {
      name: user.email,
      email: user.email,
      signed: "NotSigned",
      userId: user.id,
      editable: "true",
    });
  });

  typeaheadKeyGroup.value++;
};

const handleEnterKeyGroup = () => {
  const selectedItem = typeaheadItemsGroup.value.find(item => item.toLowerCase() === simpleTypeaheadValueGroup.value.toLowerCase());
  if (selectedItem) {
    console.log("Enter key pressed for groupList, selected item:", selectedItem);
    selectItemGroup(selectedItem);
  } else {
    console.log("Enter key pressed for groupList, no matching item found for:", simpleTypeaheadValueGroup.value);
  }
};

function deleteUser(userId) {
  storeGlobal.commit('deleteExternalUser', userId);
}

function handleOrderInputChange(event, userId) {
  const value = event.target.value.replace(/\D/g, '');
  event.target.value = value;

  if (value === '') return;

  const orderNumber = parseInt(value, 10);
  const limitedOrder = Math.max(1, Math.min(orderNumber, sortedUsers.value.length));
  event.target.value = limitedOrder;
  storeGlobal.commit('updateUserOrder', { userId, order: limitedOrder });
}

function moveUserUp(userId) {
  storeGlobal.commit('moveUserUp', userId);
}

function moveUserDown(userId) {
  storeGlobal.commit('moveUserDown', userId);
}

watch(simpleTypeaheadValue, (newValue) => {
  const user = storeUsers.value.find((u) => u.email === newValue);
  if (user) {
    selectedUser.value = user.id;
  } else {
    selectedUser.value = null;
  }
});

watch(simpleTypeaheadValueGroup, (newValue) => {
  console.log("simpleTypeaheadValueGroup updated:", newValue);
});
</script>

<style scoped>
.box {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.inline-box {
  display: flex;
  width: 99%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}


/* line with text */
.line {
  width: 100%;
  height: 1px;
  background: #DEE2E6;
  margin-block: 2rem;
}

.vertical-line {
  width: 1px;
  height: 80%;
  background: #DEE2E6;
  margin-block: 2rem;
}

.line span {
  background: white;
  padding: 0 1rem;
  color: #667085;
  font-size: 14px;
  position: relative;
  bottom: 0.8rem;
}

.upload-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  text-align: start;
  margin-left: 1px;
  margin-block: 3rem 1rem;
}

.user-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.upload-box /deep/ #searchBoxId,
.upload-box /deep/ #searchBoxGroupId {
  width: 100%;
  height: 45px;
  border-radius: 6px;
  outline: 1px solid #DEE2E6;
  border: none;
  color: black;
  padding-left: 20px;
  /* Add padding-left */
  box-sizing: border-box;
  /* Ensure padding is included in the width */
  font-size: 16px;
  transition: 0.2s;
}

/* .upload-box /deep/ #searchBoxId,.upload-box /deep/ #searchBoxGroupId {
    width: 100%;
    height: 45px;
    border-radius: 6px; 
    outline: 1px solid #DEE2E6;
    border: none;
    color: black;
    font-size: 16px;
    transition: 0.2s;
} */

/* /deep/ is used to change external component css while in style scoped */
.upload-box /deep/ #searchBoxId:hover,
.upload-box /deep/ #searchBoxGroupId:hover {
  outline: 1px solid #b8bec5 !important;
}

.upload-box /deep/ #searchBoxId:focus-visible,
.upload-box /deep/ #searchBoxGroupId:focus-visible {
  outline: 1px solid #b8bec5 !important;
}

label {
  font-size: 18px;
  color: #000000;
  margin-bottom: 0.5rem;
  margin-left: 28px;

}

/* files list section */
.list-box {
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* Add gap between rows */
  margin-bottom: 1rem;
}

.file-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1rem;
  align-items: center;
  text-align: start;
  padding: 0.5rem 1rem;
  border: 1px solid #DEE2E6;
  border-radius: 9px;
}

.file-box h2 {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.file-box h3 {
  font-size: 12px;
  font-weight: 400;
  text-align: start;
  color: #AFB7BF;
  margin: 0;
}

.file-box .delete-img {
  margin-left: auto;
}

.text-empty {
  margin: auto;
}

.order-controls {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  margin-inline: 0.6rem;
}

.order-controls input {
  width: 14px !important;
  text-align: center;
  border-radius: 4px;
  border: none;
  background: #EDF3FF;
  outline: none;
  margin-block: 6px;
  color: #0D6EFD;
}

.order-controls :focus {
  outline: 1px solid #BAD1FF;

}


.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-inline: 0.2rem;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.dot.active {
  background-color: #0D6EFD;
  transform: scale(1.4);
}

.inline-box img {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.inline-box img:hover {
  transform: scale(1.2);
}


.custom-dropdown {
  position: relative;
  width: 100%;
  height: 45px;
}

.custom-dropdown input {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  outline: 1px solid #DEE2E6;
  border: none;
  color: black;
  padding: 0 1rem;
  font-size: 16px;
}

.dropdown-list {
  position: absolute;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  background: white;
  border: 1px solid #DEE2E6;
  border-radius: 6px;
  margin-top: 4px;
  z-index: 1000;
}

.dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.external-toggle-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 92%;
  margin-right: 1rem;
}

.external-toggle-box h2 {
  font-size: 18px;
  color: #000000;
  font-weight: 400;
  margin-top: 0.3rem;
  text-wrap: nowrap;
}

.external-toggle-box p {
  margin: 0;
  text-align: start;
  font-size: 12px;
  color: #6A6A6A;
}


.external-inline-box {
  display: flex;
  width: 99%;
  flex-direction: row;
  align-items: center;
}

/* Toggle switch styling */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 18px;
}

.approval-join-checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 30px;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.approval-join-checkbox:checked+.toggle-label {
  background-color: #0D6EFD;
}

.toggle-label:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: -2px;
  bottom: -4px;
  background-color: #0D6EFD;
  transition: .1s;
  border-radius: 50%;
}

.approval-join-checkbox:checked+.toggle-label:before {
  transform: translateX(20px);
}

.open-button {
  position: absolute;
  top: 10px;
  right: 11px;
  width: 25px;
  height: 25px;
  background: #fff;
  pointer-events: none;
}

.open-button button {
  border: none;
  background: transparent;
}

.approval-join-checkbox:checked+.toggle-label:before {
  transform: translateX(20px);
}
</style>
