// services.js

import api, {signer_api } from "./api.js";
import { getterMethods } from "./store/Istore.js";
import dbg from './debug.js';
import JSZip from 'jszip';
// import CurrentUser from "./currentUser.js";
import store from "./store/store.js";

export const verifyDocument = async (file) => {
  let formData = new FormData();
  formData.append("file", file);
  try{
    let response = await api.post("/verify-document", formData);
    return response;
  }
  catch (error){
    console.error("Exception occurred when sending file:", error);
    throw error;
  }
}


// =============================================================
// POST /send-file na odosielanie dokumentu
// =============================================================

export const createSigningProcess = async (data) => {
  console.log("DATA", data);
  try {
    let response = await api.post("/create-process", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      }});
    return response.data;
  }
  catch (error) {
    console.error("Exception occurred when creating signing process", error);
    throw error;
  }
}


// =============================================================
// 
// =============================================================


export const createApprovalProcess = async (data) => {
  try {
    let response = await api.post("/create-approval-process", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      }});
    return response.data;
  }
  catch (error) {
    console.error("Exception occurred when creating approval process", error);
    throw error;
  }
}



// =============================================================
// 
// =============================================================


export const sendDocument = async (files, certificate) => {
  let formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append("files", files[i]);
  }
  formData.append("certificate", certificate)

  try {
    let response = await api.post("/send-file", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }});
    return response.data;
  }
  catch (error) {
    console.error("Exception occurred when sending file:", error);
    throw error;
  }
};


// =============================================================
// GET /users na načítanie vsetkych používateľov 
// =============================================================


export const loadUsers = async () => {
  try {
    let response = await api.get("/users");
    // dbg(JSON.stringify(response, null, 2));
    return response.data;
  }
  catch (error) {
    console.error("Exception occurred when loading users:", error);
    throw error;
  }
};


// =============================================================
// DELETE /remove-document na vymazanie dokumentu
// =============================================================


export const deleteDocument = async (store) => {
  try {

    let docID = [
      store.getters[getterMethods.GET_DOCUMENT_ID]
    ]

    let response = await api.delete("/remove-document", {
      data: { documentid: docID },
      headers: {
        'Content-Type': 'application/json',
      }
    });
    return response;
  }
  catch (error) {
    console.error("Exception occurred when deleting document:", error);
    throw error;
  }
};


// =============================================================
// GET /my-documents/{id} dokumenty autora id
// =============================================================


export const myDocuments = async () => {
  try {
    let response = await api.get(`/my-documents`);
    //dbg(JSON.stringify(response, null, 2));
    dbg("response data from /my-documents", response.data)
    return response.data;
  }
  catch (error) {
    console.error("Exception occurred when loading my documents:", error);
    throw error;
  }
};

// =============================================================
// GET /documents-to-sign/{id} dokumenty na podpis pre autora id
// =============================================================

export const sharedDocuments = async () => {
  try {
    let response = await api.get(`/documents-to-sign`);
    //dbg(JSON.stringify(response, null, 2));
    dbg("response data from /documents-to-sign", response.data)
    return response.data;
  }
  catch (error) {
    console.error("Exception occurred when loading my documents to sign:", error);
    throw error;
  }
};

// ================================================================================
// GET /load-send-to-mails/{id} maily, na ktore boli odoslane dokumenty mimo signer
// ================================================================================

export const loadSendTo = async (process_id) => {
  try {
    let response = await api.get(`/load-send-to-mails/${process_id}`);
    dbg("response data from /load-send-to-mails", response.data)
    return response.data;
  }
  catch (error) {
    console.error("Exception occurred when loading send to mails", error);
    throw error;
  }
};


// =============================================================
// GET /document-detail/{id} detail mojich pridanych dokumentov
// =============================================================


export const fetchDocumentDetails = async (docId) => {
  try {
    var process_type = store.getters["getActiveTab"]

    dbg("process type", process_type.abbr)
    dbg("selected document id", docId);

    let response = await api.post(`/document-detail/${docId}`, {
      process_type: process_type.abbr
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    console.error("Exception occurred when fetching document details:", error);
    throw error;
  }
};

// =============================================================
// POST /document-detail-approval-process/{id} detail schvalovacie procesu pre approval-link
// =============================================================

export const fetchApprovalDocument = async (docid) => {
  try {
    var body = {
      document_id: docid,
    }
  
    dbg("selected document id", docid);
    let response = await api.post(`/document-detail-approval-process`, body);
    return response.data;

  } catch (error) {
    console.error("Exception occurred when fetching shared document details:", error);
    throw error;
  }
}

// =============================================================
// POST /document-detail-to-sign/{id} detail mojich pridanych dokumentov
// =============================================================


export const fetchSharedDocuments = async (docid, approvalDefault=false) => {
  try {
    dbg("approvalDefault", approvalDefault)
    var process_type;
    if(approvalDefault){
      process_type = "AP"
    }
    else {
      process_type = store.getters["getActiveTab"].abbr
    }
    var body = {
      document_id: docid,
      // signer_id: CurrentUser.value,
      process_type: process_type
    }
  
    dbg("selected document id", docid);
    let response = await api.post(`/document-detail-to-sign`, body);
    return response.data;

  } catch (error) {
    console.error("Exception occurred when fetching shared document details:", error);
    throw error;
  }
};

// =============================================================
// POST /create-list 
// =============================================================


export const createSignatures = async (data) => {
  try {

  
    let response = await api.post("/create-list", data);
    dbg("response data", response.data)
    return response.data;

  } catch (error) {
    console.error("Exception occurred when creating list of signatures:", error);
    throw error;
  }
};

// =============================================================
// POST /sign_document na podpis dokumentu
// =============================================================

export const signDocument = async (documentId, message, signed_at, restriction) => {
  try {
    const body = {
      doc_id: documentId,
      note: message,
      signed_date: signed_at,
      restriction: restriction,
    };
    console.log('kys', body);
    dbg("signed_date", body.signed_date)

    let response = await api.post("/sign_document", body);
    dbg("response data", response.data);
    return response.data;

  } catch (error) {
    console.error("Exception occurred when signing document:", error);
    throw error;
  }
};

// =============================================================
// POST /approve_document na podpis dokumentu
// =============================================================

export const approveDocument = async (documentId, message, approved_at, restriction) => {
  try {
    const body = {
      doc_id: documentId,
      note: message,
      approved_date: approved_at,
      restriction: restriction,
    };
    console.log('kys', body);
    dbg("signed_date", body.signed_date)

    let response = await api.post("/approve_process", body);
    dbg("response data", response.data);
    return response.data;

  } catch (error) {
    console.error("Exception occurred when approving document:", error);
    throw error;
  }
};

// =============================================================
// POST /sign_document na podpis dokumentu
// =============================================================

export const declineDocument = async (documentId, message, signed_at, process_type) => {
  try {
    const body = {
      process_id: documentId,
      note: message,
      signed_date: signed_at,
      process_type: process_type
    };

    let response = await api.post("/decline_document", body);
    dbg("response data", response.data);
    return response.data;

  } catch (error) {
    console.error("Exception occurred when signing document:", error);
    throw error;
  }
};

// =============================================================
// POST /edit_document na edit dokumentu
// =============================================================

export const editDocument = async (data) => {
  try {
    
    let response = await api.post("/edit-document", data);
    dbg("response data", response.data);
    return response.data;

  } catch (error) {
    console.error("Exception occurred when editing document:", error);
    throw error;
  }
};

// =============================================================
// POST /send_mail_with_file na odoslanie mailu s dokumentmi
// =============================================================

export const sendMailWithFile = async (data) => {
  try {
    
    let response = await api.post("/send-mail-file", data);
    dbg("response data", response.data);
    return response.status;

  } catch (error) {
    console.error("Exception occurred when editing document:", error);
    throw error;
  }
};

// =============================================================
// GET /download original for preview
// =============================================================

export const downloadOriginal = async (data) => {
  try {
    const response = await api.post(`/get-file`, data, {
      responseType: 'arraybuffer'
    });

    const zipBlob = new Blob([response.data], { type: 'application/zip' });
    const zip = await JSZip.loadAsync(zipBlob);
    if (Object.keys(zip.files).length > 5){
        const url = window.URL.createObjectURL(zipBlob);

        const link = document.createElement('a');
        link.href = url;
        const contentDisposition = response.headers['content-disposition'];
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        console.log("MAtches",response.headers);
        let filename = 'all-documents.zip'; 
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
        }

        link.setAttribute('download', filename);

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        return;
      }


    for (const relativePath in zip.files) {
      const file = zip.files[relativePath];

      const fileContent = await file.async('uint8array');

      const pdfBlob = new Blob([fileContent], { type: 'application/pdf' });

      const pdfURL = URL.createObjectURL(pdfBlob);

      window.open(pdfURL, '_blank');
    }
  } catch (error) {
    console.error("Exception occurred when downloading document:", error);
    throw error;
  }
};


export const downloadOneOriginal = async (data) => {
  try {
    const response = await api.post(`/get-one-file`, data, {
      responseType: 'blob'
    });

    const fileBlob = new Blob([response.data], { type: 'application/pdf' }); // Assuming the file is a PDF. Adjust the type as needed.
    const url = window.URL.createObjectURL(fileBlob);

    const link = document.createElement('a');
    link.href = url;
    const contentDisposition = response.headers['content-disposition'];
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    let filename = 'document.pdf'; // Default filename

    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }

    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Exception occurred when downloading document:", error);
    throw error;
  }
};





// =============================================================
// GET /edit_document stiahnutie dokumentu
// =============================================================

export const downloadDocument = async (data) => {
  try {
    // Make a request to fetch the file data
    const response = await api.post(`/get-file`, data, {
      responseType: 'blob' // Set responseType to 'blob' to get binary data
    });

    const contentType = response.headers['content-type'];

    // If content type is PDF, open in new tab
    if (contentType === 'application/pdf') {
      const url = window.URL.createObjectURL(response.data);
      window.open(url, '_blank');
      return;
    }
    // Create a blob object from the file data
    const blob = new Blob([response.data], { type: response.headers['content-type'] });

    // Create a temporary URL for the blob object
    const url = window.URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement('a');
    link.href = url;
    const contentDisposition = response.headers['content-disposition'];
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
  
    let filename = 'all-documents.zip'; // Default filename
    if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
    }
    // Set the filename for the downloaded file
    link.setAttribute('download', filename);

    // Append the link to the body and click it programmatically to trigger the download
    document.body.appendChild(link);
    link.click();

    // Cleanup: Remove the link and revoke the URL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

    // const url = `${base_url}/get-file/${docid}/${mode}`;
    // window.open(url, '_blank');
  } catch (error) {
    console.error("Exception occurred when downloading document:", error);
    throw error;
  }
};



// =============================================================
// GET /edit_document stiahnutie dokumentu
// =============================================================

export const deleteFlow = async (process_type, process_id) => {
  try {

    var data = {
      process_id: process_id,
      process_type: process_type
    }
    
    dbg("selected process id", process_id);
    let response = await api.post(`/remove-sign-flow`, data);
   
    return response.data;

  } catch (error) {
    console.error("Exception occurred when fetching document details:", error);
    throw error;
  }
};
export const minexSign = async (data) => {
  try {
    dbg("selected file", data);
    let response = await api.post('/minex_sign_document', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
    },
    responseType: 'blob'
    });
   
    return response;

  } catch (error) {
    console.error("Exception occurred when fetching document details:", error);
    throw error;
  }
}
// =============================================================
//
// =============================================================
export const qSign = async (data) => {
  try {
    dbg("selected file", data);
    let response = await signer_api.post('/sign-document', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
    }
    });
   
    return response;

  } catch (error) {
    console.error("Exception occurred when fetching document details:", error);
    throw error;
  }
}
// =============================================================
//
// =============================================================
export const qSignMultiple = async (data) => {
  try {
    dbg("selected file", data);
    let response = await signer_api.post('/sign-multiple-documents', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
    }
    });
   
    return response;

  } catch (error) {
    console.error("Exception occurred when fetching document details:", error);
    throw error;
  }
}

// =============================================================
//
// =============================================================

export const qSignGetDoc = async (signid) => {
  try {
    dbg("selected file id", signid);
    let response = await signer_api.get(`/get-signed-document?signid=${signid}&no_cert=1`, {
      responseType: 'blob'  // Pridajte tento riadok
    });
   
    return response.data;

  } catch (error) {
    console.error("Exception occurred when fetching document details:", error);
    throw error;
  }
}

// =============================================================
//
// =============================================================

export const QsignDocument = async (documentId, message, signed_at, file, restriction) => {
  try {
    const formData = new FormData();

    const body = {
      doc_id: documentId,
      note: message,
      signed_date: signed_at,
      restriction: restriction
    };

    formData.append('json', JSON.stringify(body));
    if (file) {
      formData.append('file', file);
    }

    let response = await api.post("/qsign-document", formData);
    // let response = await testApi.post("/qsign-document", formData);

    dbg("response data", response);
    return response;

  } catch (error) {
    console.error("Exception occurred when signing document:", error);
    throw error;
  }
};

// =============================================================
// GET /get-file/{docID} načítanie konkrétneho dokumentu ako FormData
// =============================================================

export const getFile = async (docID, signed, filename, defaultType=null) => {
  try {

    var process_type;

    if(defaultType == true) {
      process_type = "AP"
    }
    else {
      process_type = store.getters["getActiveTab"].abbr
    }

    var data = {
       process_id: docID,
       signed: signed,
       process_type: process_type,


    }

    const response = await api.post(`/get-file`, data, { responseType: 'arraybuffer' });

    // Check if the response status is OK (200)
    if (response.status !== 200) {
      console.error(`Error fetching file. Status: ${response.status}`);
      throw new Error(`Error fetching file. Status: ${response.status}`);
    }

    // Check if the response contains data
    if (!response.data || response.data.byteLength === 0) {
      console.error("Empty response data");
      throw new Error("Empty response data");
    }

    var formData = new FormData();
    const zipBlob = new Blob([response.data], { type: 'application/zip' });
    const zip = await JSZip.loadAsync(zipBlob);

    if (Object.keys(zip.files).length > 1){

      for (const relativePath in zip.files) {
        const file = zip.files[relativePath];
        const fileContent = await file.async('uint8array');
        const pdfBlob = new Blob([fileContent], { type: 'application/pdf' });
        formData.append(relativePath, pdfBlob, relativePath);       
      }

    }
    else{

      const file = Object.values(zip.files)[0];
      const fileContent = await file.async('uint8array');
      const fileBlob = new Blob([fileContent], { type: 'application/pdf' });
      formData.append("file", fileBlob, filename[0]);

    }

    return formData;
  } catch (error) {
    console.error("Exception occurred when getting file as FormData:", error);
    throw error;
  }
};

// =============================================================
//
// =============================================================

export const sendMessage = async (data) => {
  try {
    dbg("DATA: ", data)

    let response = await api.post(`/save-comments`, data, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
   
    return response.data;
  } catch (error) {
    console.error("Exception occurred when sending message:", error);
    throw error;
  }
}

// =============================================================
//
// =============================================================

export const deleteMessage = async (commentId) => {
  try {

    let response = await api.get(`/delete-comment/${commentId}`);

    return response.data;
  } catch (error) {
    console.error("Exception occurred when deleting comment:", error);
    throw error;
  }
}

// =============================================================
//
// =============================================================

export const loadMessages = async (data) => {
  try {
    dbg("DATA: ", data)

    let response = await api.post(`/load-comments`, data, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
   
    return response.data;
  } catch (error) {
    console.error("Exception occurred when loading messages:", error);
    throw error;
  }
}

// =============================================================
//
// =============================================================

export const isApprovalFinished = async (approvalId) => {
  try {
    let response = await api.get(`/is-approval-finished/${approvalId}`);
    return response.data;
  } catch (error) {
    console.error("Exception occurred when checking approval status:", error);
    throw error;
  }
}

// =============================================================
//
// =============================================================

export const imageSignature = async (data) => {
  try {
    let response = await api.post(`/image-signature`, data , {responseType: 'blob'});
    return response;
  } catch (error) {
    console.error("Exception occurred when automatic process:", error);
    throw error;
  }
}

// =============================================================
//
// =============================================================

export const automaticProcess = async (data) => {
  try {
    let response = await api.post(`/automatic-process`, data);
    return response.data;
  } catch (error) {
    console.error("Exception occurred when automatic process:", error);
    throw error;
  }
}

// =============================================================
// GET /process-to-sign-external-sign/{process_id} načítanie konkrétneho dokumentu
// =============================================================

export const fetchExternalSignData = async (process_id) => {
    let response = await api.get(`/process-external-sign/${process_id}`);
    return response;
}

// =============================================================
// GET /process-external-sign-detail/{process_id} načítanie konkrétneho dokumentu
// =============================================================

export const fetchExternalProcessDetail = async (process_id) => {
  let response = await api.get(`/process-external-sign-detail/${process_id}`);
  return response;
}

// =============================================================
// POST /external-sign 
// =============================================================

export const externalSign = async (data) => {
  try {
    let response = await api.post(`/external-sign`, data, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
   
    return response;
  } catch (error) {
    console.error("Exception occurred when checking approval status:", error);
  }
}

// =============================================================
// POST /external-decline 
// =============================================================

export const externalDecline = async (data) => {
  try {
    let response = await api.post(`/external-decline`, data, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
   
    return response;
  } catch (error) {
    console.error("Exception occurred when checking approval status:", error);
  }
}