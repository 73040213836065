<template>
    <div 
      class="tooltip-container" 
      @mouseover="showTooltip = true" 
      @mouseleave="showTooltip = false"
    >
      <!-- Slot pre obsah, na ktorý sa tooltip viaže -->
      <slot></slot>
  
      <!-- Tooltip -->
      <div v-if="showTooltip" class="tooltip" :style="tooltipStyle">
        <div v-if="$slots.list">
          <slot name="list"/>
        </div>
        
        <div v-if="text">
          {{ text }}
        </div>
        
      </div>
    </div>
  </template>
  
  <script>
  import { ref, computed } from 'vue';
  


  export default {
    name: 'PopUpToolTip',
    props: {
      text: {
        type: String,
        required: true,
      },
      position: {
        type: String,
        default: 'bottom', // 'top', 'bottom', 'left', 'right'
      },
    },
    setup(props) {
      const showTooltip = ref(false);
      const tooltipStyle = computed(() => {
        switch (props.position) {
          case 'top':
            return 'transform: translateX(-50%) translateY(-100%);';
          case 'bottom':
            return 'transform: translateX(-40%) translateY(0);';
          case 'left':
            return 'transform: translateX(-100%) translateY(-50%);';
          case 'right':
            return 'transform: translateX(0%) translateY(-10%);';
          default:
            return 'transform: translateX(-50%) translateY(-100%);';
        }
      });
  
      return { showTooltip, tooltipStyle };
    },
  };
  </script>
  
  <style>
  .tooltip-container {
    position: relative;
    display: inline-block;
  }
  
.tooltip {
  position: absolute;
  background-color: #ffffff;
  color: #484848;
  text-align: center;
  font-size: 13px;
  border-radius: 6px;
  z-index: 1000;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  padding: 0.5rem;
  white-space: nowrap;
  max-width: 30rem;
  overflow: hidden
}



  </style>
  