<template v-if="$store.state.msg.length > 0">
    <div class="infoBox">
        <div v-for="(message, index) in $store.state.msg" :key="index" :class="'info-outer info-' + message.type">
            <div class="info-outer-container">
                <img v-if="message.type == 0"  src="../../public/ok.svg" alt="" class="ok-img">
                <img v-if="message.type == 2"  src="../../public/x.svg" alt="" class="ok-img">

                <div class="info-textbox">{{ message.text }}</div>
                <img src="../../public/x.svg" alt="" class="x-img" @click="$store.commit('popMsg')"> 
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InfoPopUp',
    setup() {
        // Tu môžete pridať logiku pre setup, ak je potrebné.
    },
    mounted() {
        // console.log(this.$store.state)
    },
    data() {
        return {
            // tu môžete pridať lokálne dáta, ak je potrebné
        }
    },
    methods: {
        // tu môžete pridať metódy, ak je potrebné
        test(message) {
            console.log(message);
        } 
    },
};
</script>
    
<style scoped>



.info-0 {
    background: green;
}

.info-1 {
    background: orange;
}

.info-2 {
    background: red;
}

.infoBox {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 2000;
}

.info-outer {
    padding: 0px 20px 20px 20px;
    right: 0;
    border-radius: 24px;
    width: 17rem;
    bottom: 0;
    margin-right: 1rem;
    margin-bottom: 0.6rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.info-outer-container {
    display: inline-flex;
}
.ok-img 
{
    width: 40px;
    margin-top: 22px;
}

.x-img 
{
    width: 10px;
    margin-bottom: 31px;
    
}

.info-textbox {
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 22px;
    font-weight: 600;
    min-width: 12rem;
    color: white;
    font-size: 14px;
    margin-left: 1rem;
}

.info-close {
    color: white;
    right: 200pz;
    display: block;
    height: 100%;
    cursor: pointer;
}




</style>
    